.loading-icon {
  &__inner {
    position: relative;
    top: 30%;
    height: 120px;
  }

  &__container {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility, opacity 0.5s ease-in-out;
    -ms-transition: visibility, opacity 0.5s ease-in-out;
    transition: visibility, opacity 0.5s ease-in-out;

    &.open {
      visibility: visible;
      opacity: 1;
      -webkit-transition: visibility, opacity 0.5s ease-in-out;
      -ms-transition: visibility, opacity 0.5s ease-in-out;
      transition: visibility, opacity 0.5s ease-in-out;
    }
  }
  
  &__loading {
    position: relative;
    color: #26d4a5 !important;
  }

  &__text {
    font-size: 15px;
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}