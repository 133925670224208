  .stepper-container {
    padding-top: 10px;
    padding-bottom: 15px;
    .stepper-bar {
      position: relative;
      height: 3px;
      width: 100%;
      border-radius: 10px;
      background: #a1b0fc;
  
      .active-percent {
        background: #4261ff;
        height: 100%;
        border-radius: inherit;
        box-shadow: 1px 0px 3px 0px rgba(66,97,255,1);
      }
    }
  }