.document__list {
  &-item {
    position: relative;
    display: block;
    border-top: 1px solid #eaecff;
    padding: 15px 0 16px 0;
    color: #2c2c39;
    line-height: 1.33;

    &-icon {
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
    }

    &-text {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }

    &-text-right {
      float: right;
      position: relative;
      vertical-align: middle;
      top: 2px;
      right: 21px;
      font-family: SansSemibold;
      color: #4261ff;
    }

    &-goto-icon {
      position: absolute;
      top: calc(50% + 2px);
      right: 0;
      transform: translateY(-50%);
    }
  }
}