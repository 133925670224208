.header-main {
	background-color: @header-bg-color;
	height: @header-height-mobile;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: @zindex-navbar-fixed;

	.nav-in-left & {
		transition: left @transition-speed ease-out;
		left: 0;
	}

	.nav-in-left.nav-open & {
		left: 100%;
	}

	.nav-in-right & {
		transition: right @transition-speed ease-out;
		right: 0;
	}

	.nav-in-right.nav-open & {
		right: 100%;
	}
}

.header-item {
	&:first-child {
		margin-right: auto;
	}

	&:last-child {
		font-family: SansSemibold;
		margin-left: auto;
	}
}

.header-item--header-title {
	position: absolute;
	top: 0;
	bottom: 0;
	left: @header-toggle-width + @grid-gutter-width;
	right: @header-toggle-width + @grid-gutter-width;
	display: flex;
	align-items: center;
	z-index: 1;
}