.header-onboarding {
  background: #4261FF;
  color: white;
  display: inline-block;
  padding: 20px;
  font-family: SansSemibold;
  font-size: 15px;

  .left {
    float: left;
  }
  .right {
    float: right;
    a {
      color: white;
      text-decoration: none;
    }
  }
  &-right-black {
    margin-right: 20px
  }
}