.tabs {
  &__header {
    box-shadow: 0 2px 3px -1px #eaecff;

    &.bo-border {
      box-shadow: 0 0 0 0 transparent;
    }

    &.fluid {
      margin: 0 -20px;
    }

    &.fluid & {
      &-inner {
        padding: 0 20px;
      }
    }

    &-item {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      text-decoration: none;
      font-size: 15px;
      color: #a1b0fc;
      box-sizing: border-box;
      
      &--inner {
        display: block;
        padding: 13px 0;
        cursor: pointer;
        border-bottom: 3px solid transparent;
      }

      &.active {
        color: #2c2c39;
        font-family: SansSemibold;
      }

      &.active & {
        &--inner {
          border-bottom: 3px solid #4261ff;
        }
      }
    }
  }

  &__content {
    &-item {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}