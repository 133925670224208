.borderless-top {
  border-top: none !important;
}

.borderless-bottom {
  border-bottom: none !important;
}
.border-error {
  &__bottom {
    border-bottom: 1px solid #dd0f43 !important;
  }
}