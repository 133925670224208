// ********************************************************************************
// checkbox and radio shared
.checkbox,
.radio {
	position: relative;

	+ .checkbox,
	+ .radio {
		margin-top: 8px;
	}

	&:last-child {
		padding-bottom: 12px;
		border-bottom: 1px solid @input-border;
	}

	&.inline {
		min-height: @input-toggable-size;
		display: inline-block;
		margin-top: 0;
		margin-right: @grid-gutter-width/2;
		margin-bottom: @grid-gutter-width/4;
	}

	label {
		min-height: @input-toggable-size;
		display: flex;
		align-items: flex-start;
		cursor: pointer;
		margin-bottom: 0;
		position: relative;
		color: @input-toggable-color;
		padding-left: 28px;

		table & {
			padding-top: 0;
			padding-bottom: 0;
		}

		a {
			text-decoration: underline;
		}

		// icon
		&:before {
			background-color: transparent;
			content: "";
			border: 1px solid @input-toggable-border-color;
			width: @input-toggable-size;
			height: @input-toggable-size;
			display: block;
			z-index: 2;
			margin-right: @grid-gutter-width/4;
			flex-grow: 0;
			flex-shrink: 0;
			position: absolute;
			left: 0;
			top: 1px;
		}

		&:after {
			content: "";
			display: block;
			opacity: 0;
			transition: opacity @transition-speed;
			flex-grow: 0;
			flex-shrink: 0;
		}
	}

	// hide control
	input {
		&:extend(.sr-only all);

		&:focus + label,
		&:focus + [type=hidden] + label {
			&:before {
				box-shadow: 0 0 0 1px @input-border-focus;
			}
		}

		&.input-validation-error + [type=hidden] + label {
			&:before {
				border-color: @error-color;
			}
		}

		&:disabled + label,
		&:disabled + [type=hidden] + label {
			opacity: .5;
		}
	}
}

// ********************************************************************************
// checkbox

.checkbox {
	@size: @input-toggable-size/2;
	@pos: @size/2;
	
	input + label,
	input + [type=hidden] + label {
		&:after {
			content: "";
			background-image: url("../../images/icons/check--checkbox.svg");
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			width: @size;
			height: @size;
			position: absolute;
			left: @pos;
			top: @size;
			transform: translate(0, -50%);
			z-index: 2;
		}
	}
	
	input:checked + label,
	input:checked + [type=hidden] + label {
		&:after {
			opacity: 1;
		}
	}
}

// ********************************************************************************
// radiobutton

.radio {
	@size: @input-toggable-size/2;
	@pos: @size/2;

	label {
		&:before {
			border-radius: 50%;
		}
	}

	input:checked + label,
	input:checked + [type=hidden] + label {

		&:after {
			opacity: 1;
			background-color: @input-toggable-border-color;
			border-radius: 50%;
			width: @size;
			height: @size;
			position: absolute;
			left: 4px;
			top: 9px;
			transform: translate(0, -50%);
			z-index: 2;
		}
	}
}