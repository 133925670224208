.tablist li, 
button,
a {
	&:active,
	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.header-title {
	font-size: @font-size-base;
	// font-weight: 600;
	font-family: SansSemibold;
	margin-bottom: 0;
	text-align: center;

	// truncate long text to "..." at the end
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.content-title {
	font-size: 32px;
	font-weight: 400;
}

.content-title--small {
	font-size: 24px;
	line-height: 32px;
}

.content-lead {
	font-family: OpenSans;
	font-size: 15px;
	white-space: pre-line;
}

.primary-title {
	font-weight: 400;
	font-size: 22px;
	line-height: 32px;
	font-weight: 600;
	margin-top: 10px;
	color: #4261FF;  
}

.section-title {
	font-size: 20px;
	font-weight: 600;
	line-height: 36px;
	margin-top: 10px;
	color: #4261FF;
	margin-bottom: 7px;
	//border-bottom: 1px solid #EAECFF;
}

.section-title--dark {
	color: @text-color;
}