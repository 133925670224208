.compliance-declaration-introduction {
  margin-top: 7px;
}
.compliance-declaration-agreement {
  display: inline-flex;
  margin: 8px 0 24px;

  & .input--check-box {
    background: transparent;
  }
}

.compliance-declaration__text-title {
  font-family: SansSemibold !important;
  font-size: 20px !important;
  color: #4261ff !important;
  margin-bottom: 0px;
}