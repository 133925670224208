.slider {
	padding-top: 5px;
	&-container {
		width: 100%;
		height: 16px;
	}
	&__risks {
		-webkit-appearance: none;
		width: 335px;
		height: 3px;
		background: linear-gradient(to right, #eaecff 75%, #9dabbf 25%);
		border-radius: 5px;
	}
	&__initial {
		-webkit-appearance: none;
		width: 335px;
		height: 3px;
		background: linear-gradient(to right, red 65%, grey 0%);
		// &::-webkit-slider-runnable-track {
		// 	height: 3px;
		// 	background-color: green;
		// }
	}

	&__risks, &__initial {
		&:active::-webkit-slider-thumb {
			background-color: blue;
		}
		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 21px;
			height: 21px;
			border-radius: 5px;
			box-shadow: 0 0 5px 0.5px rgba(66, 97, 255, 0.5);
			background: white;
			cursor: pointer;
			z-index: 4;
		}
		&::-moz-range-thumb {
			width: 21px;
			height: 21px;
			border-radius: 5px;
			box-shadow: 0 0 5px 0.5px rgba(66, 97, 255, 0.5);
			background: white;
			cursor: pointer;
			z-index: 2;
		  }		  
	}
	&-text-container {
		display: flex;
		justify-content: space-between;
	  }
	&-sub-text {
		font-family: 'OpenSans';
		font-size: 13px;
		letter-spacing: 0.5px;
		color: #a1b0fc;
	  }	  
	  
}

.slider__legend {
	color: @brand-primary-light;
	font-size: 13px;
	font-weight: 300;
	display: flex;
	padding-top: 10px;
	padding-bottom: 5px;
}

.slider__max {
	margin-left: auto;
}