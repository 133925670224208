.support-detail {
  &__title {
    font-family: SansSemibold;
    font-size: 20px;
    line-height: 1.5;
    color: #4261ff;
    margin-bottom: 10px;
  }

  &__desc {
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: .2px;
  }
}

.support-helpful {
  display: table;
  margin-bottom: 17px;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #eaecff;

  &__quest {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
  }

  &__ans {
    display: table-cell;
    vertical-align: middle;
    text-align: right;

    &--item {
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px;
      width: 50px;
      text-align: center;
      text-decoration: none;
      border: 1px solid #4261ff;
      border-radius: 5px;
      color: #4261ff;
      font-size: 12px;
    }
  }
}

.support-confirm {
  position: fixed;
  top: 17px;
  left: 15px;
  width: calc(100% - 30px);
  padding: 14px;
  background-color: #4261FF;
  opacity: 1;
  border-radius: 5px;
  z-index: 9999;
  color: #ffffff;
  box-shadow: 0px 0px 4px #4261FF;

  &__close {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 10px;
    height: 10px;
    background: url('../../../css/images/icons/close-glyph-alt-inverted.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  &__title {
    font-family: SansSemibold;
    margin-bottom: 6px;
  }

  &__desc {
    line-height: 1.67;
    margin-bottom: 6px;
  }
}