
@question-icon-color: #2c2c39;
@common-text-color: #4261ff;
@border-color: #eaecff;
@small-title-color: #2c2c39;
@error-color: #dd0f43;
@place-holder: #a1b0fc;
@light-blue: #eaecff;
@gray: #666666;
@white: #ffffff;

.personal-particular-container {
  padding-bottom: 15px;
  .account {
    &.expanded {
      .account-header {
        &__icon {
          transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
        }
      }
      .account-content {
        max-height: 1000px;
        overflow: visible;
        -webkit-transition: max-height 0.25s ease-in;
        -ms-transition: max-height 0.25s ease-in;
        transition: max-height 0.25s ease-in;
      }
    }
    &.last {
      padding-top: 20px;
    }

    &-header {
      position: relative;
      cursor: pointer;
      padding: 10px 0 10px 25px;
      border-bottom: 1px solid @border-color;
      &__icon {
        position: absolute;
        display: inline-block;
        top: 15px;
        left: 0;
        width: 16px;
        height: 19px;
        background-image: url("../../../css/images/icons/triangle-right.svg");
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-size: 16px 19px;
        color: @question-icon-color;

        -webkit-transition: -webkit-transform .15s ease-in-out;
        -ms-transition: -ms-transform .15s ease-in-out;
        transition: transform .15s ease-in-out;
      }
      &__text {
        display: inline-block;
        padding: 0;
        font-family: SansSemibold;
        font-size: 20px;
        color: @common-text-color;
      }
    }
    &-content {
      max-height: 0;
      overflow: hidden;
      -webkit-transition: max-height 0.15s ease-out;
      -ms-transition: max-height 0.15s ease-out;
      transition: max-height 0.15s ease-out;

      .form-group {
        padding-top: 20px;
        margin-bottom: 0;

        p {
          margin: 0 0 4px;
        }
        .control-label {
          font-size: 13px;
          color: @small-title-color;
          .mandatory-symbol {
            color: @error-color;
          }
        }
        .input-custom {
          height: 30px;
          font-size: 15px;
          border-bottom: none;
          padding: 0 8px;
          &:focus {
            border-bottom: 1px solid @common-text-color;
          }
        }
        .control-wrapper {
          &.error {
            border-bottom: 2px solid @error-color;
          }
          &.partial-control {
            position: relative;
          }
          input::placeholder {
            color: @place-holder;
            font-family: OpenSans;
            font-size: 15px;
            font-weight: normal;
          }
          input:disabled {
            background:  @white;
            opacity: 0.5;
          }
          .error-message {
            color: @error-color;
            font-size: 13px;
          }
          .my-calendar {
            position: absolute;
            top: -5px;
            right: 0;
            width: 16px;
            height: 16px;
            display: block;
            &-icon {
              width: 100%;
              height: 100%;
              display: block;
              background: url("../../../css/images/calender-alt.svg") no-repeat;
              background-size: 16px 16px;
            }
          }
        }
      }
    }
  }
}

// ant date-picker
.ant-calendar-picker {
  width: 100%;
  &-input {
    &.ant-input {
      border: none;
    }
  }

  .ant-calendar-picker-icon {
    display: inline-block;
    color: @common-text-color;
    font-size: 14px;
    line-height: 1;
    right: 1px;
    font-size: 16px;
  }
}
.custom-popup-calendar {
  left: 0!important;
  top: 33px!important;

  a {
    text-decoration: none;
  }

  .ant-calendar-header {
    border-bottom-color: @border-color;
    .ant-calendar-prev-year-btn {
      left: 0;
      &::after {
        content: "";
        background: url("../../../css/images/angle-default-left.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        left: 23px;
      }
      &::before {
        content: "";
        background: url("../../../css/images/angle-default-left.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        left: 18px;
      }
    }
    .ant-calendar-prev-month-btn {
      &::after {
        content: "";
        background: url("../../../css/images/angle-default-left.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        left: 18px;
      }
    }
    .ant-calendar-next-month-btn {
      &::after {
        content: "";
        background: url("../../../css/images/angle-default-right.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        right: 18px;
      }
    }

    .ant-calendar-next-year-btn {
      right: 0;
      &::after {
        content: "";
        background: url("../../../css/images/angle-default-right.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        right: 18px;
      }

      &::before {
        content: "";
        background: url("../../../css/images/angle-default-right.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        right: 23px;
      }
    }
  }
  .ant-calendar-input-wrap {
    display: none;
  }
  .ant-calendar {
    width: 100%;
  }
  .ant-calendar-tbody {
    font-family: OpenSans;
    .ant-calendar-date {
      font-size: 14px;
      width: 29px;
      height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    .ant-calendar-selected-day {
      .ant-calendar-date {
        color: @white;
        background: @common-text-color;
      }
    }
    .ant-calendar-today {
      &.ant-calendar-selected-date {
        .ant-calendar-date {
          background: @common-text-color;
          color: @white;
          font-weight: normal;
        }
      }
      .ant-calendar-date {
        border: none;
        background: @light-blue;
        color: @gray;
      }
    }

  }

  .ant-calendar-footer {
    border-top-color: @border-color;
    .ant-calendar-footer-btn {
      .ant-calendar-today-btn {
        font-family: SansSemibold;
        font-size: 14px;
      }
    }
  }
}

#wrapper-date-picker {
  position: relative;
}
.date-picker-custom {
  &.ant-calendar-picker {
    display: none;
  }
}