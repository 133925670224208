.logo {
	margin: 0 auto;
	padding-right: @grid-gutter-width/2;
	padding-left: @grid-gutter-width/2;
	&__notfound {
		background-image: url("../../images/icons/icecream-error-logo.svg");
		background-size: "200px 200px";
		display: block;
		margin-left:auto;
		background-repeat: no-repeat;
		margin-right:auto;
		height: 150px;
		width: 185px;
	}
	&__img {
		background-image: url("../../images/logo.svg");
		background-repeat: no-repeat;
		background-size: contain;
		display: block;
		height: 15px;
		width: 100px;  
	}
}