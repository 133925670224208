// ------------------------------------------------------------------
// a11y
// http://www.scottohara.me/blog/2018/05/24/keep-labels-clean.html

.form-group {
	position: relative;

	&.has-description {

		> .label,
		> label {
			&:after {
				content: "";
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}

		a,
		input,
		.select, 
		select, 
		textarea {
			position: relative;
			z-index: 2;
		}
	}
}

.form-group__info {
	color: @text-muted;
	margin-bottom: 5px;
	margin-top: -5px;
}


// ------------------------------------------------------------------
// form structure

.form-section {
	width: 100%;
}

.form-actions {
	margin-top: auto;
	//padding-top: @grid-gutter-width;
	padding-top: 0px;
}

.form-actions__item {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.btn:only-child {
		width: 100%;
	}

	+ .form-actions__item {
		margin-top: @grid-gutter-width;
	}
}

.form-actions__item--vertical {
	flex-direction: column;

	.btn {
		width: 100%;

		+ .btn {
			margin-top: @grid-gutter-width/2;
		}
	}
}


// ------------------------------------------------------------------
// form displayed as paragraph text

.form-main--paragraph {

	.form-main__body {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.form-control {
		//width: 80px;
		display: inline-block;
		font-size: inherit;
		color: #ffffff;
		padding: 2px 5px;
		height: auto;
		line-height: normal;
		margin-right: 3px;
		text-align: center;
		font-weight: 300;
	}

	.select {
		display: inline-flex;
		background-color: transparent;

		select {
			height: 45px;
			padding: 0 0 0 10px;
			margin-right: 0;
		}
	}
}


// ------------------------------------------------------------------
// for <p>

.form-section__content {
	font-size: 24px;
	font-weight: 300;
	line-height: 60px;
	color: #B3C0FF;
	margin-bottom: @grid-gutter-width;
}


// ------------------------------------------------------------------
// controls

.control-wrapper {
	border-bottom: 1px solid @input-border;
	margin-top: -5px;
}


.form-group--control-icon {
	position: relative;

	.control-label,
	.control-wrapper {
		padding-left: 28px;
	}
}

.control-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -9px;

	img {
		display: block;
	}
}

// ------------------------------------------------------------------
// control modifiers

.form-control--date {
	padding-left: 30px !important;
	background-repeat: no-repeat;
	background-image: url("../../images/icons/form-control--calendar.svg");
	background-position: 5px center;
    width: 165px;

	.layout--inverted & {
		background-image: url("../../images/icons/form-control--calendar--inverted.svg");
		text-align: left;
	}

	&.form-control--date--alt {
		background-position: right center;
		width: 100%;
		padding-left: 0 !important;
	}
}


// ------------------------------------------------------------------
// control sizes

[class*="form-control--"] {
    display: inline-block;
}

.form-control--xs {
    width: 100px;
}

.form-control--sm {
    width: 180px;
}

.form-control--md {
    width: 250px;
}

.form-control--lg {
    width: 350px;
}


// ------------------------------------------------------------------
// jquery.unobtrusive validation

.validation-summary-errors {
    &:extend(.alert);
	&:extend(.alert-danger);
	
    ol,
    ul {
        padding-left: 20px;
	}
	
    a {
        color: @alert-danger-text;
        text-decoration: underline;
    }
}

.label-validation-error {
	color: @brand-danger !important;
}

.input-validation-error {
    &.select select,
    &.form-control {
        border-color: @brand-danger;
	}
}

.toggle-validation-error {
    label {
		color: @brand-danger;
		
        &:before {
            border-color: @brand-danger;
        }
    }
}

// error message beneath control
.validation-error-message {
    color: @brand-danger;
	margin-top: 5px;
	
    .toggle-validation-error+& {
        margin-top: -5px;
    }
}


// ------------------------------------------------------------------
// mtan

.control-wrapper--mtan {
	display: flex;
	justify-content: space-around;
	margin-bottom: @grid-gutter-width/2;

	.form-control {
		text-align: center;
		font-size: 20px;

		+ .form-control {
			margin-left: 10px;
		}
	}
}

.mtan--counter {
	text-align: center;
}


.goal--saving:before {
	background-image: url("../../images/icons/goal--saving.svg");
}