nav ul {
	padding: 0;
	margin-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.close-icon-default {
	background-image: url("../../images/icons/close-icon-default.svg");
	width: 16px;
	height: 16px;
	background-size: cover;
	margin-top: 5px;
}

.nav-menu-close-icon {
	background-image: url("../../images/icons/close-glyph-alt.svg");
	width: 16px;
	height: 16px;
}
.nav-menu-inverted-close-icon {
	background-image: url("../../images/icons/close-glyph-alt-inverted.svg");
	width: 16px;
	height: 16px;
}
.nav-close-icon {
	background-image: url("../../images/icons/nav-close.svg");
	width: 16px;
	height: 17px;
}
.nav-back-icon {
	background-image: url("../../images/icons/nav-back.svg");
	background-size: 18px;
	background-repeat: no-repeat;
	width: 18px;
	height: 18px;
	background-size: contain;
}
.nav-back-white-icon {
	background-image: url("../../images/icons/arrow-circle-single-left.svg");
	background-repeat: no-repeat;
	width: 18px;
	height: 18px;
	background-size: contain;
}
.nav-menu-icon {
	background-image: url("../../images/icons/nav-menu.svg");
	width: 16px;
	height: 11px;
}
.nav-menu-icon-white {
	background-image: url("../../images/icons/menu-alt.svg");
	width: 14px;
	height: 14px;
	background-size: 100% 100%;
}

.nav-offcanvas {
	position: fixed;
	top: 0;
	z-index: 11;
	min-height: 100vh;
	height: 100vh;
	width: @nav-mobile-width;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.nav-offcanvas--left {
	left: -@nav-mobile-width;
	transition: left @transition-speed ease-out;

	.nav-in-left.nav-open & {
		left: 0;
	}
}

.nav-offcanvas--right {
	right: -@nav-mobile-width;
	transition: right @transition-speed ease-out;

	.nav-in-right.nav-open & {
		right: 0;
	}
}

.nav-offcanvas--top {
	top: -@nav-mobile-width;
	transition: top @transition-speed ease-out;
	z-index: @zindex-navbar-fixed - 1;

	.nav-in-top.nav-open & {
		background-color: rgba(255,255,255,1);
		top: 0;
	}
}
.nav-offcanvas-opened {
	z-index: @zindex-navbar-fixed - 1;
}

.nav-offcanvas__inner {
	display: flex;
	flex-direction: column;
	padding: 0 @grid-gutter-width/2 @grid-gutter-width/2;

	.nav-offcanvas--main & {
		background-color: @nav-offcanvas-main-bg;
		min-height: 100%;
		
		&,
		a {
			color: @nav-offcanvas-main-color;
		}
	}

	.nav-offcanvas--more & {
		background-color: @nav-offcanvas-more-bg;
		box-shadow: @nav-offcanvas-boxshadow;
		
		&,
		a {
			color: @nav-offcanvas-more-color
		}
	}
}

.nav-offcanvas__title {
	margin-right: auto;
	font-weight: 600;
	font-size: 13px;
	margin-left: @grid-gutter-width/2;
}

// ------------------------------------------------------------------------------------

.toggle-offcanvas--close {
	margin: 0;
	padding: 0;
	border: none;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
}
.toggle-offcanvas--title {
	margin-left: 15px;
	color: #2c2c39;
	font-family: SansSemibold;
	font-size: 14px;
}

// ------------------------------------------------------------------------------------
// left navigation elements (nav-main)

.nav__actions {
	display: flex;
	justify-content: space-between;
	margin-left: -@grid-gutter-width/2;
	margin-right: -@grid-gutter-width/2;
	align-items: center;

	.nav-offcanvas--main & use {
		fill: #ffffff;
	}

	.nav-offcanvas--more & use {
		fill: #243041;
	}
}

.nav__profile {
	margin-bottom: 20px;
	font-size: 32px;
	text-decoration: none;
}

.nav__primary,
.nav__secondary {
	a {
		display: block;
		font-size: 18px;
		height: 44px;
		display: flex;
		align-items: center;
		color: #ffffff;
		padding-left: 40px;
		position: relative;
		text-decoration: none;

		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -12px;
			width: 24px;
			height: 24px;
			background-repeat: no-repeat;
			background-position: center center;		
		}
	}

	li {
		opacity: .4;
		padding: 3px 0;

		&.active {
			opacity: 1;
		}
	}
}

.nav__secondary {
	margin-top: auto;
}

// icons

.nav__primary--dashboard a:before {
	background-image: url("../../images/icons/nav-primary--dashboard.svg");
}

.nav__primary--history a:before {
	background-image: url("../../images/icons/nav-primary--history.svg");
}

.nav__primary--messages a:before {
	background-image: url("../../images/icons/nav-primary--messages.svg");
}

.nav__primary--deposit a:before {
	background-image: url("../../images/icons/nav-primary--deposit.svg");
}

.nav__primary--withdraw a:before {
	background-image: url("../../images/icons/nav-primary--withdraw.svg");
}

.nav__primary--documents a:before {
	background-image: url("../../images/icons/nav-primary--documents-multiple.svg");
}

// .nav__primary--insights a:before {
// 	background-image: url("../../images/icons/nav-primary--insights.svg");
// }

// ---

.nav__secondary--settings a:before {
	background-image: url("../../images/icons/nav-secondary--settings.svg");
}

.nav__secondary--support a:before {
	background-image: url("../../images/icons/nav-secondary--support.svg");
}

.nav__secondary--signout a:before {
	background-image: url("../../images/icons/nav-secondary--signout.svg");
}

.nav__secondary--refer a:before {
	background-image: url("../../images/icons/nav-secondary--refer.svg");
}
.nav__secondary--feedback a:before {
	background-image: url("../../images/icons/nav-secondary--feedback.svg")
	}

// ------------------------------------------------------------------

.list-nav {
	li {
		+ li {
			border-top: 1px solid #EAECFF;
		}
	}

	span {
		display: block;
		height: 50px;
		display: flex;
		align-items: center;
		position: relative;
		padding-left: 28px;
		text-decoration: none;

		&:before,
		&:after {
			content: "";
			position: absolute;
			display: block;
			background-repeat: no-repeat;
			background-size: cover;
			top: 50%;
			transform: translate(0, -50%);
		}

		&:before {
			width: 18px;
			height: 18px;
			left: 0;
		}

		&:after {
			background-image: url("../../images/icons/arrow-list--nav-more.svg");
			width: 6px;
			height: 11px;
			right: 0;
		}
	}
}

.nav__more--edit-goal span:before {
	background-image: url("../../images/icons/pencil.svg");
}

.nav__more--delete-goal span:before {
	background-image: url("../../images/icons/nav-more--delete-goal.svg");
}

.nav__more--strategy span:before {
	background-image: url("../../images/icons/arrows-right-left-default.svg");
}


.nav__more--strategy span:before {
	background-image: url("../../images/icons/strategy-default.svg");
  }
  
.nav__left-app-name {
	margin-left: 15px;
	font-family: SansSemibold;
	font-size: 14px;
}

// .nav__more--modify-portfolio a:before {
// 	background-image: url("../../images/icons/nav-more--modify-portfolio.svg");
// }

// .nav__more--change-strategy a:before {
// 	background-image: url("../../images/icons/nav-more--change-strategy.svg");
// }

// .nav__more--invest a:before {
// 	background-image: url("../../images/icons/nav-more--invest.svg");
// }

// .nav__more--withdraw a:before {
// 	background-image: url("../../images/icons/nav-more--withdraw.svg");
// }