.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid #eaecff;
  &__left {
    display: inline-flex;
    align-items: center;
  }
}
.ant-switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 6px;
  width: 48px;
  height: 24px;
  border: none;
  border-radius: 25px;
  background-color: #9dabbf;
  &::after {
    content: "";
    display: block;
    position: relative;
    right: 5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 1px 4px 0 black;
  }
}
.ant-switch-checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 48px;
  height: 24px;
  border: none;
  border-radius: 25px;
  background-color: #4261ff;
  &::after {
    content: "";
    display: block;
    position: relative;
    left: 19px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 1px 4px 0 black;
  }
}