.account-change-password {
  & .input-custom {
    height: 28px;
    font-size: 15px;
    border-bottom: none;
    padding: 0 8px;
  }

  .control-wrapper {
    margin-top: 0;
  }

  & .forgot-password-link {
    padding-top: 15px;
    
    & .text-link-normal {
      font-family: SansSemibold;
    }
  }

  & .control-label {
    margin: 0;
  }
}