.messages-detail {
  &__header {
    position: relative;
    padding-bottom: 16px;
    padding-left: 30px;
    border-bottom: 1px solid #eaecff;
    line-height: 1.33;

    &-icon {
      position: absolute;
      top: 10px;
      left: 0;
      width: 18px;
      height: 18px;
    }

    &-time {
      font-size: 13px;
      color: #a1b0fc;
      margin-bottom: 2px;
    }
  }

  &__body {
    padding: 15px 0;
    letter-spacing: 0.2px;

    p {
      margin-bottom: 0;
    }
  }

  &__footer {
    & .btn {
      display: block;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin: 0;
      }

      &-no-focus__white {
        background-color: white !important;
        outline: none;
        border-color: #4261ff;
        color: #4261ff;
      }
    }
  }

  &__quote {
    margin-bottom: 20px;

    &-dots {
      margin: 20px 0;
    }

    &-content {
      padding-left: 20px;
      border-left: 1px solid #888585;
    }
  }
}