.funding-screen {
  padding: 120px 0;
  .container {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    white-space: pre-line;
    padding-top: 10px;
    span {
      color: #4261ff;
      font-weight: bold;
    }
  }
}