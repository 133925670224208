.loading {
    width: 100vw;
    background-color: #4261ff;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10000;
    .header-main {
        display: none;
    }
}

.loading-indicator {
    margin: 50px auto;
    width: 34px;
    height: 30px;
    text-align: center;
	font-size: 10px;
	display: flex;
	justify-content: space-between;
}

.loading-indicator > div {
    background-color: @loading-indicator-color;
    height: 100%;
    width: 2px;
    display: block;
	animation: loading-bars 1.2s infinite ease-in-out;
}

.loading-indicator .rect2 {
    animation-delay: -1.1s;
}

.loading-indicator .rect3 {
    animation-delay: -1.0s;
}

.loading-indicator .rect4 {
    animation-delay: -0.9s;
}

.loading-indicator .rect5 {
    animation-delay: -0.8s;
}

@keyframes loading-bars {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
    }
}