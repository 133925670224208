.text-bold {
	font-weight: 600 !important;
}

.text-normal {
	font-weight: normal !important;
}

.text-reset {
	font-weight: normal;
	font-style: normal;
	color: inherit;
	text-align: inherit;
}

.text-ellipsis {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 225px;
	white-space: nowrap;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

// truncate text to 1 line
.text-truncate {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


  