/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.border-top {
  border-top: 1px solid #eaecff;
}
.border-bottom {
  border-bottom: 1px solid #eaecff;
}
#borderless {
  border-bottom: 0;
  border-top: 0;
}
#borderless tr {
  border-bottom: 0 !important;
  border-top: 0;
  background-color: red;
}
#borderless-bottom {
  border-bottom: 0px;
}
#borderless-top {
  border-top: 0px;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('./fonts/glyphicons-halflings/glyphicons-halflings-regular.eot');
  src: url('./fonts/glyphicons-halflings/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('./fonts/glyphicons-halflings/glyphicons-halflings-regular.woff2') format('woff2'), url('./fonts/glyphicons-halflings/glyphicons-halflings-regular.woff') format('woff'), url('./fonts/glyphicons-halflings/glyphicons-halflings-regular.ttf') format('truetype'), url('./fonts/glyphicons-halflings/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicon-asterisk:before {
  content: "\002a";
}
.glyphicon-plus:before {
  content: "\002b";
}
.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac";
}
.glyphicon-minus:before {
  content: "\2212";
}
.glyphicon-cloud:before {
  content: "\2601";
}
.glyphicon-envelope:before {
  content: "\2709";
}
.glyphicon-pencil:before {
  content: "\270f";
}
.glyphicon-glass:before {
  content: "\e001";
}
.glyphicon-music:before {
  content: "\e002";
}
.glyphicon-search:before {
  content: "\e003";
}
.glyphicon-heart:before {
  content: "\e005";
}
.glyphicon-star:before {
  content: "\e006";
}
.glyphicon-star-empty:before {
  content: "\e007";
}
.glyphicon-user:before {
  content: "\e008";
}
.glyphicon-film:before {
  content: "\e009";
}
.glyphicon-th-large:before {
  content: "\e010";
}
.glyphicon-th:before {
  content: "\e011";
}
.glyphicon-th-list:before {
  content: "\e012";
}
.glyphicon-ok:before {
  content: "\e013";
}
.glyphicon-remove:before {
  content: "\e014";
}
.glyphicon-zoom-in:before {
  content: "\e015";
}
.glyphicon-zoom-out:before {
  content: "\e016";
}
.glyphicon-off:before {
  content: "\e017";
}
.glyphicon-signal:before {
  content: "\e018";
}
.glyphicon-cog:before {
  content: "\e019";
}
.glyphicon-trash:before {
  content: "\e020";
}
.glyphicon-home:before {
  content: "\e021";
}
.glyphicon-file:before {
  content: "\e022";
}
.glyphicon-time:before {
  content: "\e023";
}
.glyphicon-road:before {
  content: "\e024";
}
.glyphicon-download-alt:before {
  content: "\e025";
}
.glyphicon-download:before {
  content: "\e026";
}
.glyphicon-upload:before {
  content: "\e027";
}
.glyphicon-inbox:before {
  content: "\e028";
}
.glyphicon-play-circle:before {
  content: "\e029";
}
.glyphicon-repeat:before {
  content: "\e030";
}
.glyphicon-refresh:before {
  content: "\e031";
}
.glyphicon-list-alt:before {
  content: "\e032";
}
.glyphicon-lock:before {
  content: "\e033";
}
.glyphicon-flag:before {
  content: "\e034";
}
.glyphicon-headphones:before {
  content: "\e035";
}
.glyphicon-volume-off:before {
  content: "\e036";
}
.glyphicon-volume-down:before {
  content: "\e037";
}
.glyphicon-volume-up:before {
  content: "\e038";
}
.glyphicon-qrcode:before {
  content: "\e039";
}
.glyphicon-barcode:before {
  content: "\e040";
}
.glyphicon-tag:before {
  content: "\e041";
}
.glyphicon-tags:before {
  content: "\e042";
}
.glyphicon-book:before {
  content: "\e043";
}
.glyphicon-bookmark:before {
  content: "\e044";
}
.glyphicon-print:before {
  content: "\e045";
}
.glyphicon-camera:before {
  content: "\e046";
}
.glyphicon-font:before {
  content: "\e047";
}
.glyphicon-bold:before {
  content: "\e048";
}
.glyphicon-italic:before {
  content: "\e049";
}
.glyphicon-text-height:before {
  content: "\e050";
}
.glyphicon-text-width:before {
  content: "\e051";
}
.glyphicon-align-left:before {
  content: "\e052";
}
.glyphicon-align-center:before {
  content: "\e053";
}
.glyphicon-align-right:before {
  content: "\e054";
}
.glyphicon-align-justify:before {
  content: "\e055";
}
.glyphicon-list:before {
  content: "\e056";
}
.glyphicon-indent-left:before {
  content: "\e057";
}
.glyphicon-indent-right:before {
  content: "\e058";
}
.glyphicon-facetime-video:before {
  content: "\e059";
}
.glyphicon-picture:before {
  content: "\e060";
}
.glyphicon-map-marker:before {
  content: "\e062";
}
.glyphicon-adjust:before {
  content: "\e063";
}
.glyphicon-tint:before {
  content: "\e064";
}
.glyphicon-edit:before {
  content: "\e065";
}
.glyphicon-share:before {
  content: "\e066";
}
.glyphicon-check:before {
  content: "\e067";
}
.glyphicon-move:before {
  content: "\e068";
}
.glyphicon-step-backward:before {
  content: "\e069";
}
.glyphicon-fast-backward:before {
  content: "\e070";
}
.glyphicon-backward:before {
  content: "\e071";
}
.glyphicon-play:before {
  content: "\e072";
}
.glyphicon-pause:before {
  content: "\e073";
}
.glyphicon-stop:before {
  content: "\e074";
}
.glyphicon-forward:before {
  content: "\e075";
}
.glyphicon-fast-forward:before {
  content: "\e076";
}
.glyphicon-step-forward:before {
  content: "\e077";
}
.glyphicon-eject:before {
  content: "\e078";
}
.glyphicon-chevron-left:before {
  content: "\e079";
}
.glyphicon-chevron-right:before {
  content: "\e080";
}
.glyphicon-plus-sign:before {
  content: "\e081";
}
.glyphicon-minus-sign:before {
  content: "\e082";
}
.glyphicon-remove-sign:before {
  content: "\e083";
}
.glyphicon-ok-sign:before {
  content: "\e084";
}
.glyphicon-question-sign:before {
  content: "\e085";
}
.glyphicon-info-sign:before {
  content: "\e086";
}
.glyphicon-screenshot:before {
  content: "\e087";
}
.glyphicon-remove-circle:before {
  content: "\e088";
}
.glyphicon-ok-circle:before {
  content: "\e089";
}
.glyphicon-ban-circle:before {
  content: "\e090";
}
.glyphicon-arrow-left:before {
  content: "\e091";
}
.glyphicon-arrow-right:before {
  content: "\e092";
}
.glyphicon-arrow-up:before {
  content: "\e093";
}
.glyphicon-arrow-down:before {
  content: "\e094";
}
.glyphicon-share-alt:before {
  content: "\e095";
}
.glyphicon-resize-full:before {
  content: "\e096";
}
.glyphicon-resize-small:before {
  content: "\e097";
}
.glyphicon-exclamation-sign:before {
  content: "\e101";
}
.glyphicon-gift:before {
  content: "\e102";
}
.glyphicon-leaf:before {
  content: "\e103";
}
.glyphicon-fire:before {
  content: "\e104";
}
.glyphicon-eye-open:before {
  content: "\e105";
}
.glyphicon-eye-close:before {
  content: "\e106";
}
.glyphicon-warning-sign:before {
  content: "\e107";
}
.glyphicon-plane:before {
  content: "\e108";
}
.glyphicon-calendar:before {
  content: "\e109";
}
.glyphicon-random:before {
  content: "\e110";
}
.glyphicon-comment:before {
  content: "\e111";
}
.glyphicon-magnet:before {
  content: "\e112";
}
.glyphicon-chevron-up:before {
  content: "\e113";
}
.glyphicon-chevron-down:before {
  content: "\e114";
}
.glyphicon-retweet:before {
  content: "\e115";
}
.glyphicon-shopping-cart:before {
  content: "\e116";
}
.glyphicon-folder-close:before {
  content: "\e117";
}
.glyphicon-folder-open:before {
  content: "\e118";
}
.glyphicon-resize-vertical:before {
  content: "\e119";
}
.glyphicon-resize-horizontal:before {
  content: "\e120";
}
.glyphicon-hdd:before {
  content: "\e121";
}
.glyphicon-bullhorn:before {
  content: "\e122";
}
.glyphicon-bell:before {
  content: "\e123";
}
.glyphicon-certificate:before {
  content: "\e124";
}
.glyphicon-thumbs-up:before {
  content: "\e125";
}
.glyphicon-thumbs-down:before {
  content: "\e126";
}
.glyphicon-hand-right:before {
  content: "\e127";
}
.glyphicon-hand-left:before {
  content: "\e128";
}
.glyphicon-hand-up:before {
  content: "\e129";
}
.glyphicon-hand-down:before {
  content: "\e130";
}
.glyphicon-circle-arrow-right:before {
  content: "\e131";
}
.glyphicon-circle-arrow-left:before {
  content: "\e132";
}
.glyphicon-circle-arrow-up:before {
  content: "\e133";
}
.glyphicon-circle-arrow-down:before {
  content: "\e134";
}
.glyphicon-globe:before {
  content: "\e135";
}
.glyphicon-wrench:before {
  content: "\e136";
}
.glyphicon-tasks:before {
  content: "\e137";
}
.glyphicon-filter:before {
  content: "\e138";
}
.glyphicon-briefcase:before {
  content: "\e139";
}
.glyphicon-fullscreen:before {
  content: "\e140";
}
.glyphicon-dashboard:before {
  content: "\e141";
}
.glyphicon-paperclip:before {
  content: "\e142";
}
.glyphicon-heart-empty:before {
  content: "\e143";
}
.glyphicon-link:before {
  content: "\e144";
}
.glyphicon-phone:before {
  content: "\e145";
}
.glyphicon-pushpin:before {
  content: "\e146";
}
.glyphicon-usd:before {
  content: "\e148";
}
.glyphicon-gbp:before {
  content: "\e149";
}
.glyphicon-sort:before {
  content: "\e150";
}
.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}
.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}
.glyphicon-sort-by-order:before {
  content: "\e153";
}
.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}
.glyphicon-sort-by-attributes:before {
  content: "\e155";
}
.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}
.glyphicon-unchecked:before {
  content: "\e157";
}
.glyphicon-expand:before {
  content: "\e158";
}
.glyphicon-collapse-down:before {
  content: "\e159";
}
.glyphicon-collapse-up:before {
  content: "\e160";
}
.glyphicon-log-in:before {
  content: "\e161";
}
.glyphicon-flash:before {
  content: "\e162";
}
.glyphicon-log-out:before {
  content: "\e163";
}
.glyphicon-new-window:before {
  content: "\e164";
}
.glyphicon-record:before {
  content: "\e165";
}
.glyphicon-save:before {
  content: "\e166";
}
.glyphicon-open:before {
  content: "\e167";
}
.glyphicon-saved:before {
  content: "\e168";
}
.glyphicon-import:before {
  content: "\e169";
}
.glyphicon-export:before {
  content: "\e170";
}
.glyphicon-send:before {
  content: "\e171";
}
.glyphicon-floppy-disk:before {
  content: "\e172";
}
.glyphicon-floppy-saved:before {
  content: "\e173";
}
.glyphicon-floppy-remove:before {
  content: "\e174";
}
.glyphicon-floppy-save:before {
  content: "\e175";
}
.glyphicon-floppy-open:before {
  content: "\e176";
}
.glyphicon-credit-card:before {
  content: "\e177";
}
.glyphicon-transfer:before {
  content: "\e178";
}
.glyphicon-cutlery:before {
  content: "\e179";
}
.glyphicon-header:before {
  content: "\e180";
}
.glyphicon-compressed:before {
  content: "\e181";
}
.glyphicon-earphone:before {
  content: "\e182";
}
.glyphicon-phone-alt:before {
  content: "\e183";
}
.glyphicon-tower:before {
  content: "\e184";
}
.glyphicon-stats:before {
  content: "\e185";
}
.glyphicon-sd-video:before {
  content: "\e186";
}
.glyphicon-hd-video:before {
  content: "\e187";
}
.glyphicon-subtitles:before {
  content: "\e188";
}
.glyphicon-sound-stereo:before {
  content: "\e189";
}
.glyphicon-sound-dolby:before {
  content: "\e190";
}
.glyphicon-sound-5-1:before {
  content: "\e191";
}
.glyphicon-sound-6-1:before {
  content: "\e192";
}
.glyphicon-sound-7-1:before {
  content: "\e193";
}
.glyphicon-copyright-mark:before {
  content: "\e194";
}
.glyphicon-registration-mark:before {
  content: "\e195";
}
.glyphicon-cloud-download:before {
  content: "\e197";
}
.glyphicon-cloud-upload:before {
  content: "\e198";
}
.glyphicon-tree-conifer:before {
  content: "\e199";
}
.glyphicon-tree-deciduous:before {
  content: "\e200";
}
.glyphicon-cd:before {
  content: "\e201";
}
.glyphicon-save-file:before {
  content: "\e202";
}
.glyphicon-open-file:before {
  content: "\e203";
}
.glyphicon-level-up:before {
  content: "\e204";
}
.glyphicon-copy:before {
  content: "\e205";
}
.glyphicon-paste:before {
  content: "\e206";
}
.glyphicon-alert:before {
  content: "\e209";
}
.glyphicon-equalizer:before {
  content: "\e210";
}
.glyphicon-king:before {
  content: "\e211";
}
.glyphicon-queen:before {
  content: "\e212";
}
.glyphicon-pawn:before {
  content: "\e213";
}
.glyphicon-bishop:before {
  content: "\e214";
}
.glyphicon-knight:before {
  content: "\e215";
}
.glyphicon-baby-formula:before {
  content: "\e216";
}
.glyphicon-tent:before {
  content: "\26fa";
}
.glyphicon-blackboard:before {
  content: "\e218";
}
.glyphicon-bed:before {
  content: "\e219";
}
.glyphicon-apple:before {
  content: "\f8ff";
}
.glyphicon-erase:before {
  content: "\e221";
}
.glyphicon-hourglass:before {
  content: "\231b";
}
.glyphicon-lamp:before {
  content: "\e223";
}
.glyphicon-duplicate:before {
  content: "\e224";
}
.glyphicon-piggy-bank:before {
  content: "\e225";
}
.glyphicon-scissors:before {
  content: "\e226";
}
.glyphicon-bitcoin:before {
  content: "\e227";
}
.glyphicon-btc:before {
  content: "\e227";
}
.glyphicon-xbt:before {
  content: "\e227";
}
.glyphicon-yen:before {
  content: "\00a5";
}
.glyphicon-jpy:before {
  content: "\00a5";
}
.glyphicon-ruble:before {
  content: "\20bd";
}
.glyphicon-rub:before {
  content: "\20bd";
}
.glyphicon-scale:before {
  content: "\e230";
}
.glyphicon-ice-lolly:before {
  content: "\e231";
}
.glyphicon-ice-lolly-tasted:before {
  content: "\e232";
}
.glyphicon-education:before {
  content: "\e233";
}
.glyphicon-option-horizontal:before {
  content: "\e234";
}
.glyphicon-option-vertical:before {
  content: "\e235";
}
.glyphicon-menu-hamburger:before {
  content: "\e236";
}
.glyphicon-modal-window:before {
  content: "\e237";
}
.glyphicon-oil:before {
  content: "\e238";
}
.glyphicon-grain:before {
  content: "\e239";
}
.glyphicon-sunglasses:before {
  content: "\e240";
}
.glyphicon-text-size:before {
  content: "\e241";
}
.glyphicon-text-color:before {
  content: "\e242";
}
.glyphicon-text-background:before {
  content: "\e243";
}
.glyphicon-object-align-top:before {
  content: "\e244";
}
.glyphicon-object-align-bottom:before {
  content: "\e245";
}
.glyphicon-object-align-horizontal:before {
  content: "\e246";
}
.glyphicon-object-align-left:before {
  content: "\e247";
}
.glyphicon-object-align-vertical:before {
  content: "\e248";
}
.glyphicon-object-align-right:before {
  content: "\e249";
}
.glyphicon-triangle-right:before {
  content: "\e250";
}
.glyphicon-triangle-left:before {
  content: "\e251";
}
.glyphicon-triangle-bottom:before {
  content: "\e252";
}
.glyphicon-triangle-top:before {
  content: "\e253";
}
.glyphicon-console:before {
  content: "\e254";
}
.glyphicon-superscript:before {
  content: "\e255";
}
.glyphicon-subscript:before {
  content: "\e256";
}
.glyphicon-menu-left:before {
  content: "\e257";
}
.glyphicon-menu-right:before {
  content: "\e258";
}
.glyphicon-menu-down:before {
  content: "\e259";
}
.glyphicon-menu-up:before {
  content: "\e260";
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: OpenSans;
  font-size: 15px;
  line-height: 1.42857143;
  color: #243041;
  background-color: #fff;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #4261FF;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #0028f4;
  text-decoration: none;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 5px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
hr {
  margin-top: 21px;
  margin-bottom: 21px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
.sr-only,
.checkbox input,
.radio input,
.goal input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
[role="button"] {
  cursor: pointer;
}
p:last-child,
ol:last-child,
ul:last-child {
  margin-bottom: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.42857143;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777777;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 0;
  margin-bottom: 10.5px;
}
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 10.5px;
}
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}
h1,
.h1 {
  font-size: 26px;
}
h2,
.h2 {
  font-size: 32px;
}
h3,
.h3 {
  font-size: 26px;
}
h4,
.h4 {
  font-size: 19px;
}
h5,
.h5 {
  font-size: 15px;
}
h6,
.h6 {
  font-size: 13px;
}
p {
  margin: 0 0 10.5px;
}
.lead {
  margin-bottom: 21px;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .lead {
    font-size: 22.5px;
  }
}
small,
.small {
  font-size: 86%;
}
mark,
.mark {
  background-color: #fcf8e3;
  padding: 0.2em;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-muted {
  color: #777777;
}
.text-primary {
  color: #4261FF;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #0f36ff;
}
.text-success {
  color: #3c763d;
}
a.text-success:hover,
a.text-success:focus {
  color: #2b542c;
}
.text-info {
  color: #31708f;
}
a.text-info:hover,
a.text-info:focus {
  color: #245269;
}
.text-warning {
  color: #8a6d3b;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #66512c;
}
.text-danger {
  color: #a94442;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #843534;
}
.bg-primary {
  color: #fff;
  background-color: #4261FF;
}
a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #0f36ff;
}
.bg-success {
  background-color: #dff0d8;
}
a.bg-success:hover,
a.bg-success:focus {
  background-color: #c1e2b3;
}
.bg-info {
  background-color: #d9edf7;
}
a.bg-info:hover,
a.bg-info:focus {
  background-color: #afd9ee;
}
.bg-warning {
  background-color: #fcf8e3;
}
a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #f7ecb5;
}
.bg-danger {
  background-color: #f2dede;
}
a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e4b9b9;
}
.page-header {
  padding-bottom: 9.5px;
  margin: 42px 0 21px;
  border-bottom: 1px solid #eeeeee;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 10.5px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
dl {
  margin-top: 0;
  margin-bottom: 21px;
}
dt,
dd {
  line-height: 1.42857143;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777777;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 10.5px 21px;
  margin: 0 0 21px;
  font-size: 18.75px;
  border-left: 5px solid #eeeeee;
}
blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}
blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777777;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: '\2014 \00A0';
}
.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right;
}
.blockquote-reverse footer:before,
blockquote.pull-right footer:before,
.blockquote-reverse small:before,
blockquote.pull-right small:before,
.blockquote-reverse .small:before,
blockquote.pull-right .small:before {
  content: '';
}
.blockquote-reverse footer:after,
blockquote.pull-right footer:after,
.blockquote-reverse small:after,
blockquote.pull-right small:after,
.blockquote-reverse .small:after,
blockquote.pull-right .small:after {
  content: '\00A0 \2014';
}
address {
  margin-bottom: 21px;
  font-style: normal;
  line-height: 1.42857143;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 3px;
}
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 1px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  box-shadow: none;
}
pre {
  display: block;
  padding: 10px;
  margin: 0 0 10.5px;
  font-size: 14px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 3px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1006px;
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1006px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
table {
  background-color: transparent;
}
caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left;
}
th {
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 21px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #EAECFF;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #EAECFF;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #EAECFF;
}
.table .table {
  background-color: #fff;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table-bordered {
  border: 1px solid #EAECFF;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #EAECFF;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}
.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15.75px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #EAECFF;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 21px;
  font-size: 22.5px;
  line-height: inherit;
  color: inherit;
  border: 0;
}
.control-label {
  color: #243041;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  max-width: 100%;
}
legend.control-label {
  margin-bottom: 10px;
}
.control-label a {
  font-size: inherit;
  text-decoration: underline;
}
.control-label {
  font-family: OpenSans;
  font-size: 13px;
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  color: #2c2c39;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 7px;
  font-size: 15px;
  line-height: 1.42857143;
  color: #4261FF;
}
.form-control {
  display: block;
  width: 100%;
  height: 35px;
  padding: 0;
  font-size: 16px;
  font-family: SansSemiBold;
  background-color: inherit;
  background-image: none;
  border: none;
  box-shadow: none;
  line-height: 1.42857143;
  color: #4261FF;
}
.form-control:focus {
  outline: none;
}
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
textarea.form-control {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 35px;
  }
  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm,
  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"] {
    line-height: 31px;
  }
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg,
  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"] {
    line-height: 48px;
  }
}
.form-group {
  margin-bottom: 24px;
}
.form-group:last-child {
  margin-bottom: 0;
}
/*
BS radio/checkbox disabled
see app/_forms.less



// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    min-height: @line-height-computed; // Ensure the input doesn't jump when there is no text
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; // space out consecutive inline controls
}

// Apply same disabled cursor tweak as for inputs
// Some special care is needed because <label>s don't inherit their parent's `cursor`.
//
// Note: Neither radios nor checkboxes can be readonly.
input[type="radio"],
input[type="checkbox"] {
  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    cursor: @cursor-disabled;
  }
}
// These classes are used directly on <label>s
.radio-inline,
.checkbox-inline {
  &.disabled,
  fieldset[disabled] & {
    cursor: @cursor-disabled;
  }
}
// These classes are used on elements with <label> descendants
.radio,
.checkbox {
  &.disabled,
  fieldset[disabled] & {
    label {
      cursor: @cursor-disabled;
    }
  }
}
*/
.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 36px;
}
.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0;
}
.input-sm {
  height: 31px;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 1px;
}
select.input-sm {
  height: 31px;
  line-height: 31px;
}
textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}
.form-group-sm .form-control {
  height: 31px;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 1px;
}
.form-group-sm select.form-control {
  height: 31px;
  line-height: 31px;
}
.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}
.form-group-sm .form-control-static {
  height: 31px;
  min-height: 34px;
  padding: 6px 10px;
  font-size: 13px;
  line-height: 1.5;
}
.input-lg {
  height: 48px;
  padding: 10px 16px;
  font-size: 19px;
  line-height: 1.3333333;
  border-radius: 5px;
}
select.input-lg {
  height: 48px;
  line-height: 48px;
}
textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}
.form-group-lg .form-control {
  height: 48px;
  padding: 10px 16px;
  font-size: 19px;
  line-height: 1.3333333;
  border-radius: 5px;
}
.form-group-lg select.form-control {
  height: 48px;
  line-height: 48px;
}
.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}
.form-group-lg .form-control-static {
  height: 48px;
  min-height: 40px;
  padding: 11px 16px;
  font-size: 19px;
  line-height: 1.3333333;
}
.has-feedback {
  position: relative;
}
.has-feedback .form-control {
  padding-right: 43.75px;
}
.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  pointer-events: none;
}
.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 31px;
  height: 31px;
  line-height: 31px;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d;
}
.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8;
}
.has-success .form-control-feedback {
  color: #3c763d;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b;
}
.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}
.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3;
}
.has-warning .form-control-feedback {
  color: #8a6d3b;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442;
}
.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede;
}
.has-error .form-control-feedback {
  color: #a94442;
}
.has-feedback label ~ .form-control-feedback {
  top: 26px;
}
.has-feedback label.sr-only ~ .form-control-feedback,
.has-feedback label.checkbox input ~ .form-control-feedback,
.has-feedback label.radio input ~ .form-control-feedback {
  top: 0;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #516d93;
}
@media (min-width: 768px) {
  .form-inline {
    /*
    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .radio,
    .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;

      label {
        padding-left: 0;
      }
    }
    .radio input[type="radio"],
    .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
    }*/
  }
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}
.form-horizontal {
  /* .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: (@padding-base-vertical + 1); // Default padding plus a border
  }
  // Account for padding we're adding to ensure the alignment and of help text
  // and other content below items
  .radio,
  .checkbox {
    min-height: (@line-height-computed + (@padding-base-vertical + 1));
  }
*/
}
.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}
.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 19px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 13px;
  }
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 15px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: #4261FF;
  text-decoration: none;
}
.btn:active,
.btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}
.btn-default {
  color: #4261FF;
  background-color: #fff;
  border-color: #4261FF;
}
.btn-default:focus,
.btn-default.focus {
  color: #4261FF;
  background-color: #e6e6e6;
  border-color: #0020c2;
}
.btn-default:hover {
  color: #4261FF;
  background-color: #e6e6e6;
  border-color: #052eff;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #4261FF;
  background-color: #e6e6e6;
  border-color: #052eff;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #4261FF;
  background-color: #d4d4d4;
  border-color: #0020c2;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #4261FF;
}
.btn-default .badge {
  color: #fff;
  background-color: #4261FF;
}
.btn-primary {
  box-shadow: 0 0 5px 0 #4261FF;
  color: #fff;
  background-color: #4261FF;
  border-color: #4261FF;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0f36ff;
  border-color: #0020c2;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0f36ff;
  border-color: #052eff;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #0f36ff;
  border-color: #052eff;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #fff;
  background-color: #0026ea;
  border-color: #0020c2;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #4261FF;
  border-color: #4261FF;
}
.btn-primary .badge {
  color: #4261FF;
  background-color: #fff;
}
.btn-success {
  color: #fff;
  background-color: #25C177;
  border-color: #21ac6a;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #1d965d;
  border-color: #0c4128;
}
.btn-success:hover {
  color: #fff;
  background-color: #1d965d;
  border-color: #17784a;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #1d965d;
  border-color: #17784a;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  color: #fff;
  background-color: #17784a;
  border-color: #0c4128;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
  background-color: #25C177;
  border-color: #21ac6a;
}
.btn-success .badge {
  color: #25C177;
  background-color: #fff;
}
.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}
.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}
.btn-danger {
  color: #fff;
  background-color: #FF376B;
  border-color: #ff1e58;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #ff0445;
  border-color: #9d0029;
}
.btn-danger:hover {
  color: #fff;
  background-color: #ff0445;
  border-color: #df003a;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #ff0445;
  border-color: #df003a;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: #fff;
  background-color: #df003a;
  border-color: #9d0029;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #FF376B;
  border-color: #ff1e58;
}
.btn-danger .badge {
  color: #FF376B;
  background-color: #fff;
}
.btn-link {
  color: #4261FF;
  font-weight: normal;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #0028f4;
  text-decoration: none;
  background-color: transparent;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 19px;
  line-height: 1.3333333;
  border-radius: 5px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 1px;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 1px;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 15px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9.5px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #4261FF;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}
.dropdown-menu-right {
  left: auto;
  right: 0;
}
.dropdown-menu-left {
  left: 0;
  right: auto;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #777777;
  white-space: nowrap;
}
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  float: left;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  margin-left: -5px;
}
.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left;
}
.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px;
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.btn-group > .btn-group {
  float: left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}
.btn-group > .btn-lg + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn .caret {
  margin-left: 0;
}
.btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}
.dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}
.btn-group-vertical > .btn-group > .btn {
  float: none;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
  float: none;
  display: table-cell;
  width: 1%;
}
.btn-group-justified > .btn-group .btn {
  width: 100%;
}
.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}
[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.input-group .form-control:focus {
  z-index: 3;
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 48px;
  padding: 10px 16px;
  font-size: 19px;
  line-height: 1.3333333;
  border-radius: 5px;
}
select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
  height: 48px;
  line-height: 48px;
}
textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn,
select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn {
  height: auto;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 31px;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 1px;
}
select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
  height: 31px;
  line-height: 31px;
}
textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn,
select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn {
  height: auto;
}
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 15px;
  font-weight: normal;
  line-height: 1;
  color: #4261FF;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #EAECFF;
  border-radius: 3px;
}
.input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 1px;
}
.input-group-addon.input-lg {
  padding: 10px 16px;
  font-size: 19px;
  border-radius: 5px;
}
.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group-addon:last-child {
  border-left: 0;
}
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
  z-index: 2;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}
.alert,
.validation-summary-errors {
  padding: 15px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 3px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}
.alert-danger,
.validation-summary-errors {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
  padding-bottom: 75%;
}
.close {
  float: right;
  font-size: 22.5px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
button.close {
  width: 10px;
  height: 10px;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  background-image: url("../images/icons/close-glyph-alt.svg");
}
.modal-open {
  overflow: hidden;
}
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.modal-header {
  padding: 15px;
  border-bottom: 0 solid #e5e5e5;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
  color: #4261ff;
  font-weight: bold;
}
.modal-body {
  position: relative;
  padding: 0 15px 0 15px;
  letter-spacing: 0.3px;
}
.modal-footer {
  display: flex;
  padding: 20px 13px 15px 13px;
  text-align: center;
  justify-content: space-around;
  border-top: 0 solid #e5e5e5;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: OpenSans;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 13px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 3px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: OpenSans;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 15px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 15px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 4px 4px 0 0;
}
.popover-content {
  padding: 9px 14px;
}
.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover > .arrow {
  border-width: 11px;
}
.popover > .arrow:after {
  border-width: 10px;
  content: "";
}
.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
}
.popover.top > .arrow:after {
  content: " ";
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.popover.right > .arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff;
}
.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px;
}
.popover.bottom > .arrow:after {
  content: " ";
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left > .arrow:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px;
}
.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}
.clearfix:after,
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
.modal-header:after,
.modal-footer:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-hide text {
  display: none;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}
.bootstrap-select {
  width: 100% \0;
  /*IE9 and below*/
  height: 35px;
}
.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999;
}
.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
}
.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2;
}
.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}
.bootstrap-select.fit-width {
  width: auto !important;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control-"]):not(.input-group-btn) {
  width: 100%;
}
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}
.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}
.bootstrap-select.form-control:not([class*="col-"]):not([class*="form-control-"]) {
  width: 100%;
}
.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}
.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: block;
  margin-left: 0;
}
.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}
.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  margin-bottom: 0;
}
.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}
.form-group-lg .bootstrap-select.btn-group.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}
.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}
.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}
.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}
.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important;
}
.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}
.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}
.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  box-sizing: border-box;
}
.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}
.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
}
.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff;
}
.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed;
}
.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  user-select: none;
}
.bootstrap-select.btn-group .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}
.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none;
}
.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}
.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em;
}
.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  box-sizing: border-box;
}
.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static;
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}
.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}
.bs-actionsbox {
  width: 100%;
  box-sizing: border-box;
}
.bs-actionsbox .btn-group button {
  width: 50%;
}
.bs-donebutton {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.bs-donebutton .btn-group button {
  width: 100%;
}
.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}
.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
}
.datepicker {
  border-radius: 3px;
  direction: ltr;
}
.datepicker-inline {
  width: 220px;
}
.datepicker-rtl {
  direction: rtl;
}
.datepicker-rtl.dropdown-menu {
  left: auto;
}
.datepicker-rtl table tr td span {
  float: right;
}
.datepicker-dropdown {
  background-color: #4261FF;
  top: 0;
  left: 0;
  padding: 4px;
}
.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}
.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #4261FF;
  border-top: 0;
  position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}
.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid rgba(0, 0, 0, 0.15);
}
.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #4261FF;
}
.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker table tr td,
.datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: none;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #777777;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: #eeeeee;
  cursor: pointer;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #777777;
  cursor: default;
}
.datepicker table tr td.highlighted {
  color: #000;
  background-color: #d9edf7;
  border-color: #85c5e5;
  border-radius: 0;
}
.datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.focus {
  color: #000;
  background-color: #afd9ee;
  border-color: #298fc2;
}
.datepicker table tr td.highlighted:hover {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb;
}
.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.highlighted {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb;
}
.datepicker table tr td.highlighted:active:hover,
.datepicker table tr td.highlighted.active:hover,
.open > .dropdown-toggle.datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted:active:focus,
.datepicker table tr td.highlighted.active:focus,
.open > .dropdown-toggle.datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted:active.focus,
.datepicker table tr td.highlighted.active.focus,
.open > .dropdown-toggle.datepicker table tr td.highlighted.focus {
  color: #000;
  background-color: #91cbe8;
  border-color: #298fc2;
}
.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.highlighted {
  background-image: none;
}
.datepicker table tr td.highlighted.disabled:hover,
.datepicker table tr td.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted.disabled:focus,
.datepicker table tr td.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.disabled.focus,
.datepicker table tr td.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.highlighted.focus {
  background-color: #d9edf7;
  border-color: #85c5e5;
}
.datepicker table tr td.highlighted .badge {
  color: #d9edf7;
  background-color: #000;
}
.datepicker table tr td.highlighted.focused {
  background: #afd9ee;
}
.datepicker table tr td.highlighted.disabled,
.datepicker table tr td.highlighted.disabled:active {
  background: #d9edf7;
  color: #777777;
}
.datepicker table tr td.today {
  color: #000;
  background-color: #ffdb99;
  border-color: #ffb733;
}
.datepicker table tr td.today:focus,
.datepicker table tr td.today.focus {
  color: #000;
  background-color: #ffc966;
  border-color: #b37400;
}
.datepicker table tr td.today:hover {
  color: #000;
  background-color: #ffc966;
  border-color: #f59e00;
}
.datepicker table tr td.today:active,
.datepicker table tr td.today.active,
.open > .dropdown-toggle.datepicker table tr td.today {
  color: #000;
  background-color: #ffc966;
  border-color: #f59e00;
}
.datepicker table tr td.today:active:hover,
.datepicker table tr td.today.active:hover,
.open > .dropdown-toggle.datepicker table tr td.today:hover,
.datepicker table tr td.today:active:focus,
.datepicker table tr td.today.active:focus,
.open > .dropdown-toggle.datepicker table tr td.today:focus,
.datepicker table tr td.today:active.focus,
.datepicker table tr td.today.active.focus,
.open > .dropdown-toggle.datepicker table tr td.today.focus {
  color: #000;
  background-color: #ffbc42;
  border-color: #b37400;
}
.datepicker table tr td.today:active,
.datepicker table tr td.today.active,
.open > .dropdown-toggle.datepicker table tr td.today {
  background-image: none;
}
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.today:focus,
.datepicker table tr td.today.disabled.focus,
.datepicker table tr td.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.today.focus {
  background-color: #ffdb99;
  border-color: #ffb733;
}
.datepicker table tr td.today .badge {
  color: #ffdb99;
  background-color: #000;
}
.datepicker table tr td.today.focused {
  background: #ffc966;
}
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:active {
  background: #ffdb99;
  color: #777777;
}
.datepicker table tr td.range {
  color: #000;
  background-color: #eeeeee;
  border-color: #bbbbbb;
  border-radius: 0;
}
.datepicker table tr td.range:focus,
.datepicker table tr td.range.focus {
  color: #000;
  background-color: #d5d5d5;
  border-color: #7c7c7c;
}
.datepicker table tr td.range:hover {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d;
}
.datepicker table tr td.range:active,
.datepicker table tr td.range.active,
.open > .dropdown-toggle.datepicker table tr td.range {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d;
}
.datepicker table tr td.range:active:hover,
.datepicker table tr td.range.active:hover,
.open > .dropdown-toggle.datepicker table tr td.range:hover,
.datepicker table tr td.range:active:focus,
.datepicker table tr td.range.active:focus,
.open > .dropdown-toggle.datepicker table tr td.range:focus,
.datepicker table tr td.range:active.focus,
.datepicker table tr td.range.active.focus,
.open > .dropdown-toggle.datepicker table tr td.range.focus {
  color: #000;
  background-color: #c3c3c3;
  border-color: #7c7c7c;
}
.datepicker table tr td.range:active,
.datepicker table tr td.range.active,
.open > .dropdown-toggle.datepicker table tr td.range {
  background-image: none;
}
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:focus,
.datepicker table tr td.range[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range:focus,
.datepicker table tr td.range.disabled.focus,
.datepicker table tr td.range[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.focus {
  background-color: #eeeeee;
  border-color: #bbbbbb;
}
.datepicker table tr td.range .badge {
  color: #eeeeee;
  background-color: #000;
}
.datepicker table tr td.range.focused {
  background: #d5d5d5;
}
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:active {
  background: #eeeeee;
  color: #777777;
}
.datepicker table tr td.range.highlighted {
  color: #000;
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
.datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.focus {
  color: #000;
  background-color: #c1d7e3;
  border-color: #4b88a6;
}
.datepicker table tr td.range.highlighted:hover {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0;
}
.datepicker table tr td.range.highlighted:active,
.datepicker table tr td.range.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.range.highlighted {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0;
}
.datepicker table tr td.range.highlighted:active:hover,
.datepicker table tr td.range.highlighted.active:hover,
.open > .dropdown-toggle.datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted:active:focus,
.datepicker table tr td.range.highlighted.active:focus,
.open > .dropdown-toggle.datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted:active.focus,
.datepicker table tr td.range.highlighted.active.focus,
.open > .dropdown-toggle.datepicker table tr td.range.highlighted.focus {
  color: #000;
  background-color: #a8c8d8;
  border-color: #4b88a6;
}
.datepicker table tr td.range.highlighted:active,
.datepicker table tr td.range.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.range.highlighted {
  background-image: none;
}
.datepicker table tr td.range.highlighted.disabled:hover,
.datepicker table tr td.range.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted.disabled:focus,
.datepicker table tr td.range.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.disabled.focus,
.datepicker table tr td.range.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.highlighted.focus {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
.datepicker table tr td.range.highlighted .badge {
  color: #e4eef3;
  background-color: #000;
}
.datepicker table tr td.range.highlighted.focused {
  background: #c1d7e3;
}
.datepicker table tr td.range.highlighted.disabled,
.datepicker table tr td.range.highlighted.disabled:active {
  background: #e4eef3;
  color: #777777;
}
.datepicker table tr td.range.today {
  color: #000;
  background-color: #f7ca77;
  border-color: #f1a417;
}
.datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.focus {
  color: #000;
  background-color: #f4b747;
  border-color: #815608;
}
.datepicker table tr td.range.today:hover {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c;
}
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today.active,
.open > .dropdown-toggle.datepicker table tr td.range.today {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c;
}
.datepicker table tr td.range.today:active:hover,
.datepicker table tr td.range.today.active:hover,
.open > .dropdown-toggle.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:active:focus,
.datepicker table tr td.range.today.active:focus,
.open > .dropdown-toggle.datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today:active.focus,
.datepicker table tr td.range.today.active.focus,
.open > .dropdown-toggle.datepicker table tr td.range.today.focus {
  color: #000;
  background-color: #f2aa25;
  border-color: #815608;
}
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today.active,
.open > .dropdown-toggle.datepicker table tr td.range.today {
  background-image: none;
}
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:focus,
.datepicker table tr td.range.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.disabled.focus,
.datepicker table tr td.range.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.today.focus {
  background-color: #f7ca77;
  border-color: #f1a417;
}
.datepicker table tr td.range.today .badge {
  color: #f7ca77;
  background-color: #000;
}
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:active {
  background: #f7ca77;
  color: #777777;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #777777;
  border-color: #555555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.selected:focus,
.datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.focus,
.datepicker table tr td.selected.highlighted.focus {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #161616;
}
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.highlighted:hover {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737;
}
.datepicker table tr td.selected:active,
.datepicker table tr td.selected.highlighted:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.selected,
.open > .dropdown-toggle.datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737;
}
.datepicker table tr td.selected:active:hover,
.datepicker table tr td.selected.highlighted:active:hover,
.datepicker table tr td.selected.active:hover,
.datepicker table tr td.selected.highlighted.active:hover,
.open > .dropdown-toggle.datepicker table tr td.selected:hover,
.open > .dropdown-toggle.datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected:active:focus,
.datepicker table tr td.selected.highlighted:active:focus,
.datepicker table tr td.selected.active:focus,
.datepicker table tr td.selected.highlighted.active:focus,
.open > .dropdown-toggle.datepicker table tr td.selected:focus,
.open > .dropdown-toggle.datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected:active.focus,
.datepicker table tr td.selected.highlighted:active.focus,
.datepicker table tr td.selected.active.focus,
.datepicker table tr td.selected.highlighted.active.focus,
.open > .dropdown-toggle.datepicker table tr td.selected.focus,
.open > .dropdown-toggle.datepicker table tr td.selected.highlighted.focus {
  color: #fff;
  background-color: #4c4c4c;
  border-color: #161616;
}
.datepicker table tr td.selected:active,
.datepicker table tr td.selected.highlighted:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.selected,
.open > .dropdown-toggle.datepicker table tr td.selected.highlighted {
  background-image: none;
}
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.highlighted.disabled:hover,
.datepicker table tr td.selected[disabled]:hover,
.datepicker table tr td.selected.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.selected:hover,
fieldset[disabled] .datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected.disabled:focus,
.datepicker table tr td.selected.highlighted.disabled:focus,
.datepicker table tr td.selected[disabled]:focus,
.datepicker table tr td.selected.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.selected:focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.disabled.focus,
.datepicker table tr td.selected.highlighted.disabled.focus,
.datepicker table tr td.selected[disabled].focus,
.datepicker table tr td.selected.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.selected.focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted.focus {
  background-color: #777777;
  border-color: #555555;
}
.datepicker table tr td.selected .badge,
.datepicker table tr td.selected.highlighted .badge {
  color: #777777;
  background-color: #fff;
}
.datepicker table tr td.active,
.datepicker table tr td.active.highlighted {
  color: #fff;
  background-color: #4261FF;
  border-color: #4261FF;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.active:focus,
.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.focus,
.datepicker table tr td.active.highlighted.focus {
  color: #fff;
  background-color: #0f36ff;
  border-color: #0020c2;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active.highlighted:hover {
  color: #fff;
  background-color: #0f36ff;
  border-color: #052eff;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.active,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted {
  color: #fff;
  background-color: #0f36ff;
  border-color: #052eff;
}
.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.open > .dropdown-toggle.datepicker table tr td.active:hover,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.open > .dropdown-toggle.datepicker table tr td.active:focus,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus,
.open > .dropdown-toggle.datepicker table tr td.active.focus,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted.focus {
  color: #fff;
  background-color: #0026ea;
  border-color: #0020c2;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.active,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted {
  background-image: none;
}
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.highlighted.disabled:hover,
.datepicker table tr td.active[disabled]:hover,
.datepicker table tr td.active.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.active:hover,
fieldset[disabled] .datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.highlighted.disabled:focus,
.datepicker table tr td.active[disabled]:focus,
.datepicker table tr td.active.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.active:focus,
fieldset[disabled] .datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.disabled.focus,
.datepicker table tr td.active.highlighted.disabled.focus,
.datepicker table tr td.active[disabled].focus,
.datepicker table tr td.active.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.active.focus,
fieldset[disabled] .datepicker table tr td.active.highlighted.focus {
  background-color: #4261FF;
  border-color: #4261FF;
}
.datepicker table tr td.active .badge,
.datepicker table tr td.active.highlighted .badge {
  color: #4261FF;
  background-color: #fff;
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}
.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #eeeeee;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: #4261FF;
  border-color: #4261FF;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.focus,
.datepicker table tr td span.active:hover.focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active.disabled:hover.focus {
  color: #fff;
  background-color: #0f36ff;
  border-color: #0020c2;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover {
  color: #fff;
  background-color: #0f36ff;
  border-color: #052eff;
}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open > .dropdown-toggle.datepicker table tr td span.active,
.open > .dropdown-toggle.datepicker table tr td span.active:hover,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: #0f36ff;
  border-color: #052eff;
}
.datepicker table tr td span.active:active:hover,
.datepicker table tr td span.active:hover:active:hover,
.datepicker table tr td span.active.disabled:active:hover,
.datepicker table tr td span.active.disabled:hover:active:hover,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active.disabled:hover.active:hover,
.open > .dropdown-toggle.datepicker table tr td span.active:hover,
.open > .dropdown-toggle.datepicker table tr td span.active:hover:hover,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active:focus,
.datepicker table tr td span.active:hover:active:focus,
.datepicker table tr td span.active.disabled:active:focus,
.datepicker table tr td span.active.disabled:hover:active:focus,
.datepicker table tr td span.active.active:focus,
.datepicker table tr td span.active:hover.active:focus,
.datepicker table tr td span.active.disabled.active:focus,
.datepicker table tr td span.active.disabled:hover.active:focus,
.open > .dropdown-toggle.datepicker table tr td span.active:focus,
.open > .dropdown-toggle.datepicker table tr td span.active:hover:focus,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:focus,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active.focus,
.datepicker table tr td span.active:hover:active.focus,
.datepicker table tr td span.active.disabled:active.focus,
.datepicker table tr td span.active.disabled:hover:active.focus,
.datepicker table tr td span.active.active.focus,
.datepicker table tr td span.active:hover.active.focus,
.datepicker table tr td span.active.disabled.active.focus,
.datepicker table tr td span.active.disabled:hover.active.focus,
.open > .dropdown-toggle.datepicker table tr td span.active.focus,
.open > .dropdown-toggle.datepicker table tr td span.active:hover.focus,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled.focus,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover.focus {
  color: #fff;
  background-color: #0026ea;
  border-color: #0020c2;
}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open > .dropdown-toggle.datepicker table tr td span.active,
.open > .dropdown-toggle.datepicker table tr td span.active:hover,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-image: none;
}
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover.disabled:hover,
.datepicker table tr td span.active.disabled.disabled:hover,
.datepicker table tr td span.active.disabled:hover.disabled:hover,
.datepicker table tr td span.active[disabled]:hover,
.datepicker table tr td span.active:hover[disabled]:hover,
.datepicker table tr td span.active.disabled[disabled]:hover,
.datepicker table tr td span.active.disabled:hover[disabled]:hover,
fieldset[disabled] .datepicker table tr td span.active:hover,
fieldset[disabled] .datepicker table tr td span.active:hover:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active:hover.disabled:focus,
.datepicker table tr td span.active.disabled.disabled:focus,
.datepicker table tr td span.active.disabled:hover.disabled:focus,
.datepicker table tr td span.active[disabled]:focus,
.datepicker table tr td span.active:hover[disabled]:focus,
.datepicker table tr td span.active.disabled[disabled]:focus,
.datepicker table tr td span.active.disabled:hover[disabled]:focus,
fieldset[disabled] .datepicker table tr td span.active:focus,
fieldset[disabled] .datepicker table tr td span.active:hover:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active:hover.disabled.focus,
.datepicker table tr td span.active.disabled.disabled.focus,
.datepicker table tr td span.active.disabled:hover.disabled.focus,
.datepicker table tr td span.active[disabled].focus,
.datepicker table tr td span.active:hover[disabled].focus,
.datepicker table tr td span.active.disabled[disabled].focus,
.datepicker table tr td span.active.disabled:hover[disabled].focus,
fieldset[disabled] .datepicker table tr td span.active.focus,
fieldset[disabled] .datepicker table tr td span.active:hover.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover.focus {
  background-color: #4261FF;
  border-color: #4261FF;
}
.datepicker table tr td span.active .badge,
.datepicker table tr td span.active:hover .badge,
.datepicker table tr td span.active.disabled .badge,
.datepicker table tr td span.active.disabled:hover .badge {
  color: #4261FF;
  background-color: #fff;
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #777777;
}
.datepicker .datepicker-switch {
  width: 145px;
}
.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eeeeee;
}
.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden;
}
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.input-daterange {
  width: 100%;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}
.input-daterange .input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  line-height: 1.42857143;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px;
}
/* Slider */
.slick-disabled {
  opacity: 0;
  pointer-events: none;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-slider-progress-bar .slick-arrow {
  pointer-events: none;
}
@media only screen and (max-width: 768px) {
  .slick-list {
    width: calc(100vw - 40px);
  }
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  padding-bottom: 1px;
}
.slick-slider .slick-track li:focus,
.slick-slider .slick-list li:focus {
  outline: none !important;
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-slide section:focus {
  outline: 0 !important;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li.slick-active button:before {
  background-color: #4261FF;
}
.slick-dots button {
  border: 0;
  background: transparent;
  display: block;
  height: 10px;
  width: 10px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots button:hover,
.slick-dots button:focus {
  outline: none;
}
.slick-dots button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #d1d1d1;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 16px;
  width: 16px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 15px;
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:before,
.slick-next:before {
  content: "";
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  width: 7px;
  height: 16px;
  display: block;
}
.slick-prev {
  left: 20px;
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: 20px;
}
.slick-prev:before {
  background-image: url("../images/icons/chevron-left.svg");
}
[dir="rtl"] .slick-prev:before {
  background-image: url("../images/icons/chevron-right.svg");
}
.slick-next {
  right: 20px;
}
[dir="rtl"] .slick-next {
  left: 20px;
  right: auto;
}
.slick-next:before {
  background-image: url("../images/icons/chevron-right.svg");
}
[dir="rtl"] .slick-next:before {
  background-image: url("../images/icons/chevron-left.svg");
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li.slick-active button:before {
  background-color: #4261FF;
}
.slick-dots button:hover,
.slick-dots button:focus {
  outline: none;
}
.slick-dots button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #d1d1d1;
  display: block;
  border-radius: 50%;
}
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  position: absolute;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 3px;
}
.noUi-horizontal .noUi-handle {
  width: 21px;
  height: 21px;
  left: -17px;
  top: -9px;
}
.noUi-vertical {
  width: 3px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -9px;
  left: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #EAECFF;
  border-radius: 3px;
}
.noUi-connects {
  border-radius: 3px;
  box-shadow: 0 0 5px 0 rgba(66, 97, 255, 0.5);
}
.noUi-connect {
  background: #4261FF;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  cursor: default;
  background: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(66, 97, 255, 0.5);
  border-radius: 5px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  min-height: 100vh;
}
.viewport {
  overflow: hidden;
  width: 100%;
}
.viewport-inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding-top: 40px;
}
.nav-in-left .viewport-inner {
  left: 0;
  transition: left 0.15s ease-out;
}
.nav-in-left.nav-open .viewport-inner {
  left: 100%;
}
.nav-in-right .viewport-inner {
  right: 0;
  transition: right 0.15s ease-out;
}
.nav-in-right.nav-open .viewport-inner {
  right: 100%;
}
@media screen and (min-width: 768px) {
  .viewport-inner {
    padding-top: 90px;
  }
}
.content-main {
  padding: 20px;
  display: flex;
  flex: 1 auto;
  height: 100%;
}
.content-main__inner {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content__header {
  padding: 15px 0;
  text-align: center;
}
.content__header .title {
  font-weight: bold;
  font-size: 36px;
  margin: 0;
}
.content__header .text {
  font-weight: 600;
  margin: 0;
}
.content__header .text--recommendation {
  color: #25C177;
  position: relative;
  padding-left: 18px;
  display: inline-block;
}
.content__header .text--recommendation:before {
  content: "";
  background-image: url("../images/icons/check--success.svg");
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}
.content-main__body--centered {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  justify-content: center;
}
.content-main__footer {
  margin-top: auto;
  bottom: 8px;
  position: relative;
}
.header-main {
  background-color: #ffffff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1030;
}
.nav-in-left .header-main {
  transition: left 0.15s ease-out;
  left: 0;
}
.nav-in-left.nav-open .header-main {
  left: 100%;
}
.nav-in-right .header-main {
  transition: right 0.15s ease-out;
  right: 0;
}
.nav-in-right.nav-open .header-main {
  right: 100%;
}
.header-item:first-child {
  margin-right: auto;
}
.header-item:last-child {
  font-family: SansSemibold;
  margin-left: auto;
}
.header-item--header-title {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 70px;
  right: 70px;
  display: flex;
  align-items: center;
  z-index: 1;
}
.text-title__large {
  margin: 20px 0 !important;
  font-family: SansSemibold !important;
  font-size: 20px !important;
  color: #4261ff !important;
  line-height: 30px !important;
  white-space: pre-line !important;
}
.text-title__small {
  margin: 0 !important;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.5px;
}
.text-label {
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #2c2c39;
}
.text-label__descriptions {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.25px;
  color: #2c2c39;
  white-space: pre-line;
}
.text-label__values__small-1 {
  font-family: SansSemibold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #4261ff;
}
.text-label__values__small-2 {
  font-family: SansSemibold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #4261ff;
}
.text-label__link {
  font-size: 15px;
  text-decoration: underline;
  color: #26d4a5 !important;
  letter-spacing: 0.44px;
}
.text-error__small {
  font-size: 13px;
  color: #dd0f43;
  line-height: 18px;
  letter-spacing: 0.5px;
}
.text-one-line {
  white-space: nowrap !important;
}
.tablist li:active,
button:active,
a:active,
.tablist li:focus,
button:focus,
a:focus {
  outline: none;
  box-shadow: none;
}
.header-title {
  font-size: 15px;
  font-family: SansSemibold;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content-title {
  font-size: 32px;
  font-weight: 400;
}
.content-title--small {
  font-size: 24px;
  line-height: 32px;
}
.content-lead {
  font-family: OpenSans;
  font-size: 15px;
  white-space: pre-line;
}
.primary-title {
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 10px;
  color: #4261FF;
}
.section-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  margin-top: 10px;
  color: #4261FF;
  margin-bottom: 7px;
}
.section-title--dark {
  color: #243041;
}
.logo {
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}
.logo__notfound {
  background-image: url("../images/icons/icecream-error-logo.svg");
  background-size: "200px 200px";
  display: block;
  margin-left: auto;
  background-repeat: no-repeat;
  margin-right: auto;
  height: 150px;
  width: 185px;
}
.logo__img {
  background-image: url("../images/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 15px;
  width: 100px;
}
nav ul {
  padding: 0;
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.close-icon-default {
  background-image: url("../images/icons/close-icon-default.svg");
  width: 16px;
  height: 16px;
  background-size: cover;
  margin-top: 5px;
}
.nav-menu-close-icon {
  background-image: url("../images/icons/close-glyph-alt.svg");
  width: 16px;
  height: 16px;
}
.nav-menu-inverted-close-icon {
  background-image: url("../images/icons/close-glyph-alt-inverted.svg");
  width: 16px;
  height: 16px;
}
.nav-close-icon {
  background-image: url("../images/icons/nav-close.svg");
  width: 16px;
  height: 17px;
}
.nav-back-icon {
  background-image: url("../images/icons/nav-back.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-size: contain;
}
.nav-back-white-icon {
  background-image: url("../images/icons/arrow-circle-single-left.svg");
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-size: contain;
}
.nav-menu-icon {
  background-image: url("../images/icons/nav-menu.svg");
  width: 16px;
  height: 11px;
}
.nav-menu-icon-white {
  background-image: url("../images/icons/menu-alt.svg");
  width: 14px;
  height: 14px;
  background-size: 100% 100%;
}
.nav-offcanvas {
  position: fixed;
  top: 0;
  z-index: 11;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.nav-offcanvas--left {
  left: -100%;
  transition: left 0.15s ease-out;
}
.nav-in-left.nav-open .nav-offcanvas--left {
  left: 0;
}
.nav-offcanvas--right {
  right: -100%;
  transition: right 0.15s ease-out;
}
.nav-in-right.nav-open .nav-offcanvas--right {
  right: 0;
}
.nav-offcanvas--top {
  top: -100%;
  transition: top 0.15s ease-out;
  z-index: 1029;
}
.nav-in-top.nav-open .nav-offcanvas--top {
  background-color: #ffffff;
  top: 0;
}
.nav-offcanvas-opened {
  z-index: 1029;
}
.nav-offcanvas__inner {
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px;
}
.nav-offcanvas--main .nav-offcanvas__inner {
  background-color: #4261FF;
  min-height: 100%;
}
.nav-offcanvas--main .nav-offcanvas__inner,
.nav-offcanvas--main .nav-offcanvas__inner a {
  color: #ffffff;
}
.nav-offcanvas--more .nav-offcanvas__inner {
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(66, 97, 255, 0.1);
}
.nav-offcanvas--more .nav-offcanvas__inner,
.nav-offcanvas--more .nav-offcanvas__inner a {
  color: #243041;
}
.nav-offcanvas__title {
  margin-right: auto;
  font-weight: 600;
  font-size: 13px;
  margin-left: 15px;
}
.toggle-offcanvas--close {
  margin: 0;
  padding: 0;
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.toggle-offcanvas--title {
  margin-left: 15px;
  color: #2c2c39;
  font-family: SansSemibold;
  font-size: 14px;
}
.nav__actions {
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  align-items: center;
}
.nav-offcanvas--main .nav__actions use {
  fill: #ffffff;
}
.nav-offcanvas--more .nav__actions use {
  fill: #243041;
}
.nav__profile {
  margin-bottom: 20px;
  font-size: 32px;
  text-decoration: none;
}
.nav__primary a,
.nav__secondary a {
  display: block;
  font-size: 18px;
  height: 44px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-left: 40px;
  position: relative;
  text-decoration: none;
}
.nav__primary a:before,
.nav__secondary a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
}
.nav__primary li,
.nav__secondary li {
  opacity: 0.4;
  padding: 3px 0;
}
.nav__primary li.active,
.nav__secondary li.active {
  opacity: 1;
}
.nav__secondary {
  margin-top: auto;
}
.nav__primary--dashboard a:before {
  background-image: url("../images/icons/nav-primary--dashboard.svg");
}
.nav__primary--history a:before {
  background-image: url("../images/icons/nav-primary--history.svg");
}
.nav__primary--messages a:before {
  background-image: url("../images/icons/nav-primary--messages.svg");
}
.nav__primary--deposit a:before {
  background-image: url("../images/icons/nav-primary--deposit.svg");
}
.nav__primary--withdraw a:before {
  background-image: url("../images/icons/nav-primary--withdraw.svg");
}
.nav__primary--documents a:before {
  background-image: url("../images/icons/nav-primary--documents-multiple.svg");
}
.nav__secondary--settings a:before {
  background-image: url("../images/icons/nav-secondary--settings.svg");
}
.nav__secondary--support a:before {
  background-image: url("../images/icons/nav-secondary--support.svg");
}
.nav__secondary--signout a:before {
  background-image: url("../images/icons/nav-secondary--signout.svg");
}
.nav__secondary--refer a:before {
  background-image: url("../images/icons/nav-secondary--refer.svg");
}
.nav__secondary--feedback a:before {
  background-image: url("../images/icons/nav-secondary--feedback.svg");
}
.list-nav li + li {
  border-top: 1px solid #EAECFF;
}
.list-nav span {
  display: block;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 28px;
  text-decoration: none;
}
.list-nav span:before,
.list-nav span:after {
  content: "";
  position: absolute;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  top: 50%;
  transform: translate(0, -50%);
}
.list-nav span:before {
  width: 18px;
  height: 18px;
  left: 0;
}
.list-nav span:after {
  background-image: url("../images/icons/arrow-list--nav-more.svg");
  width: 6px;
  height: 11px;
  right: 0;
}
.nav__more--edit-goal span:before {
  background-image: url("../images/icons/pencil.svg");
}
.nav__more--delete-goal span:before {
  background-image: url("../images/icons/nav-more--delete-goal.svg");
}
.nav__more--strategy span:before {
  background-image: url("../images/icons/arrows-right-left-default.svg");
}
.nav__more--strategy span:before {
  background-image: url("../images/icons/strategy-default.svg");
}
.nav__left-app-name {
  margin-left: 15px;
  font-family: SansSemibold;
  font-size: 14px;
}
.onboarding-input-field__full__large {
  height: 46px;
  width: 100%;
  font-family: SansSemibold;
  font-size: 24px;
  color: #4261ff;
  border: none;
  border-bottom: 1px solid #eaecff;
}
.onboarding-input-field__full__large:focus {
  outline: none;
  border-bottom: 1px solid #a1b0fc;
}
.cockpit-input-field__full__small {
  width: 100%;
  font-family: SansSemibold;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #9dabbf;
  border-bottom: 1px solid #eaecff;
}
.cockpit-input-field__full__small:focus {
  outline: none;
}
.cockpit-input-field__full__small__inverted {
  font-family: OpenSans;
  font-size: 15px;
  letter-spacing: 0.25px;
  box-shadow: none;
}
.cockpit-input-field__full__small__inverted::placeholder {
  color: white;
}
.cockpit-input-field__full__small__no-border {
  width: 100%;
  border: none;
}
.cockpit-input-field__full__small__no-border:focus {
  outline: none;
  border-bottom: 1px solid #a1b0fc;
}
.input-wrapper {
  display: flex;
  padding-bottom: 8px;
  margin-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid #7a91ff;
}
.form-group {
  position: relative;
}
.form-group.has-description > .label:after,
.form-group.has-description > label:after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.form-group.has-description a,
.form-group.has-description input,
.form-group.has-description .select,
.form-group.has-description select,
.form-group.has-description textarea {
  position: relative;
  z-index: 2;
}
.form-group__info {
  color: #777777;
  margin-bottom: 5px;
  margin-top: -5px;
}
.form-section {
  width: 100%;
}
.form-actions {
  margin-top: auto;
  padding-top: 0px;
}
.form-actions__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-actions__item .btn:only-child {
  width: 100%;
}
.form-actions__item + .form-actions__item {
  margin-top: 30px;
}
.form-actions__item--vertical {
  flex-direction: column;
}
.form-actions__item--vertical .btn {
  width: 100%;
}
.form-actions__item--vertical .btn + .btn {
  margin-top: 15px;
}
.form-main--paragraph .form-main__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-main--paragraph .form-control {
  display: inline-block;
  font-size: inherit;
  color: #ffffff;
  padding: 2px 5px;
  height: auto;
  line-height: normal;
  margin-right: 3px;
  text-align: center;
  font-weight: 300;
}
.form-main--paragraph .select {
  display: inline-flex;
  background-color: transparent;
}
.form-main--paragraph .select select {
  height: 45px;
  padding: 0 0 0 10px;
  margin-right: 0;
}
.form-section__content {
  font-size: 24px;
  font-weight: 300;
  line-height: 60px;
  color: #B3C0FF;
  margin-bottom: 30px;
}
.control-wrapper {
  border-bottom: 1px solid #EAECFF;
  margin-top: -5px;
}
.form-group--control-icon {
  position: relative;
}
.form-group--control-icon .control-label,
.form-group--control-icon .control-wrapper {
  padding-left: 28px;
}
.control-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -9px;
}
.control-icon img {
  display: block;
}
.form-control--date {
  padding-left: 30px !important;
  background-repeat: no-repeat;
  background-image: url("../images/icons/form-control--calendar.svg");
  background-position: 5px center;
  width: 165px;
}
.layout--inverted .form-control--date {
  background-image: url("../images/icons/form-control--calendar--inverted.svg");
  text-align: left;
}
.form-control--date.form-control--date--alt {
  background-position: right center;
  width: 100%;
  padding-left: 0 !important;
}
[class*="form-control--"] {
  display: inline-block;
}
.form-control--xs {
  width: 100px;
}
.form-control--sm {
  width: 180px;
}
.form-control--md {
  width: 250px;
}
.form-control--lg {
  width: 350px;
}
.validation-summary-errors ol,
.validation-summary-errors ul {
  padding-left: 20px;
}
.validation-summary-errors a {
  color: #a94442;
  text-decoration: underline;
}
.label-validation-error {
  color: #FF376B !important;
}
.input-validation-error.select select,
.input-validation-error.form-control {
  border-color: #FF376B;
}
.toggle-validation-error label {
  color: #FF376B;
}
.toggle-validation-error label:before {
  border-color: #FF376B;
}
.validation-error-message {
  color: #FF376B;
  margin-top: 5px;
}
.toggle-validation-error + .validation-error-message {
  margin-top: -5px;
}
.control-wrapper--mtan {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
.control-wrapper--mtan .form-control {
  text-align: center;
  font-size: 20px;
}
.control-wrapper--mtan .form-control + .form-control {
  margin-left: 10px;
}
.mtan--counter {
  text-align: center;
}
.goal--saving:before {
  background-image: url("../images/icons/goal--saving.svg");
}
.checkbox,
.radio {
  position: relative;
}
.checkbox + .checkbox,
.radio + .checkbox,
.checkbox + .radio,
.radio + .radio {
  margin-top: 8px;
}
.checkbox:last-child,
.radio:last-child {
  padding-bottom: 12px;
  border-bottom: 1px solid #EAECFF;
}
.checkbox.inline,
.radio.inline {
  min-height: 17px;
  display: inline-block;
  margin-top: 0;
  margin-right: 15px;
  margin-bottom: 7.5px;
}
.checkbox label,
.radio label {
  min-height: 17px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  color: #4261FF;
  padding-left: 28px;
}
table .checkbox label,
table .radio label {
  padding-top: 0;
  padding-bottom: 0;
}
.checkbox label a,
.radio label a {
  text-decoration: underline;
}
.checkbox label:before,
.radio label:before {
  background-color: transparent;
  content: "";
  border: 1px solid #4261FF;
  width: 17px;
  height: 17px;
  display: block;
  z-index: 2;
  margin-right: 7.5px;
  flex-grow: 0;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: 1px;
}
.checkbox label:after,
.radio label:after {
  content: "";
  display: block;
  opacity: 0;
  transition: opacity 0.15s;
  flex-grow: 0;
  flex-shrink: 0;
}
.checkbox input:focus + label:before,
.radio input:focus + label:before,
.checkbox input:focus + [type=hidden] + label:before,
.radio input:focus + [type=hidden] + label:before {
  box-shadow: 0 0 0 1px #66afe9;
}
.checkbox input.input-validation-error + [type=hidden] + label:before,
.radio input.input-validation-error + [type=hidden] + label:before {
  border-color: red;
}
.checkbox input:disabled + label,
.radio input:disabled + label,
.checkbox input:disabled + [type=hidden] + label,
.radio input:disabled + [type=hidden] + label {
  opacity: 0.5;
}
.checkbox input + label:after,
.checkbox input + [type=hidden] + label:after {
  content: "";
  background-image: url("../images/icons/check--checkbox.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 8.5px;
  height: 8.5px;
  position: absolute;
  left: 4.25px;
  top: 8.5px;
  transform: translate(0, -50%);
  z-index: 2;
}
.checkbox input:checked + label:after,
.checkbox input:checked + [type=hidden] + label:after {
  opacity: 1;
}
.radio label:before {
  border-radius: 50%;
}
.radio input:checked + label:after,
.radio input:checked + [type=hidden] + label:after {
  opacity: 1;
  background-color: #4261FF;
  border-radius: 50%;
  width: 8.5px;
  height: 8.5px;
  position: absolute;
  left: 4px;
  top: 9px;
  transform: translate(0, -50%);
  z-index: 2;
}
.select {
  background-color: #FFFFFF;
  cursor: pointer;
}
.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
  margin-right: 0;
  height: 35px;
  padding: 0;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select:after {
  content: "";
  background-image: url("../images/icons/select-arrow.svg");
  background-repeat: no-repeat;
  display: block;
  width: 16px;
  height: 9px;
  flex-shrink: 0;
  position: absolute;
  right: 9px;
  top: 18px;
  z-index: 2;
}
.select:hover:after {
  color: #4261FF;
}
.input--check-box {
  -webkit-appearance: none;
  margin: 2px 10px 0 0;
  width: 18px;
  height: 18px;
  border: 1px solid #4261FF;
  background-color: transparent;
}
.input--check-box:after {
  display: none;
  content: "";
  position: relative;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 9px;
  border: solid #4261FF;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.input--check-box:checked:after {
  display: block;
}
.input--check-box:active,
.input--check-box:focus,
.input--check-box:hover {
  outline: none;
}
.error-message {
  font-family: OpenSans;
  font-size: 13px;
  color: red;
}
.error-message-bottom {
  border-bottom: 1px solid red;
  border-radius: 0;
}
.amount-changed {
  font-size: 13px;
}
#warningModal {
  align-items: center;
}
#warningModalFooter {
  justify-content: space-around;
}
.btn {
  box-shadow: 0 0 5px 0 rgba(66, 97, 255, 0.5);
  font-size: 16px;
  padding: 10px 32px 10px;
}
.btn-with-draw {
  padding-top: 1px;
  width: 96px;
  height: 20px;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background: #4261ff;
  border-radius: 5px;
  box-shadow: 0 0 5px 0.5px rgba(66, 97, 255, 0.5);
}
.btn-no-focus__primary:active,
.btn-no-focus__primary:focus,
.btn-no-focus__primary:hover {
  background-color: #4261ff !important;
  border-color: #4261ff !important;
  outline: none !important;
}
.btn-no-focus__primary:focus {
  outline: none;
}
.btn-no-focus__primary__inverted:active,
.btn-no-focus__primary__inverted:focus,
.btn-no-focus__primary__inverted:hover {
  color: white !important;
  background-color: #26D4A5 !important;
  border-color: #26D4A5 !important;
  outline: none !important;
}
.btn-no-focus__primary__inverted:focus {
  outline: none;
}
.btn-no-focus__inverted:active,
.btn-no-focus__inverted:hover {
  color: white !important;
}
.btn-no-focus__inverted:active {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid white !important;
  background-color: #4261ff !important;
}
.btn-no-focus__white:active,
.btn-no-focus__white:focus,
.btn-no-focus__white:hover {
  background-color: white !important;
  outline: none;
  border-color: #4261ff;
}
.btn-no-focus__disabled:active,
.btn-no-focus__disabled:focus,
.btn-no-focus__disabled:hover {
  color: white;
}
.btn-no-focus__disabled:disabled {
  opacity: 1;
  background-color: #a1b0fc;
  color: white;
}
.btn-default {
  box-shadow: none;
}
.button-group {
  display: flex;
  justify-content: space-between;
}
.button-group .btn:only-child {
  display: block;
  width: 100%;
}
.toggle-header {
  margin: 0;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  height: 40px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.toggle-header:focus {
  outline: none;
  box-shadow: none;
}
.toggle-header--notification {
  position: relative;
}
.notification-indicator {
  position: absolute;
  top: 4px;
  left: 42%;
  color: #fff;
  font-size: 9px;
  text-align: center;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background-color: #4261ff;
  border-radius: 50%;
}
.toggle-icon {
  display: flex;
  align-items: center;
}
.link--more {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eaecff;
  height: 50px;
  font-weight: 600;
}
.table-listing + .link--more {
  margin-top: -20px;
}
.link-arrow {
  position: relative;
  padding-left: 15px;
}
.link-arrow:before {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../images/icons/link-arrow.svg");
  content: "";
  width: 10px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.link-form {
  font-size: 15px;
  display: block;
  margin-top: 10px;
}
.btn-cancel {
  width: 50%;
  margin-right: 10px;
  position: relative;
  top: 69%;
}
.btn-review {
  width: 50%;
  position: relative;
  top: 69%;
}
.borderless-top {
  border-top: none !important;
}
.borderless-bottom {
  border-bottom: none !important;
}
.border-error__bottom {
  border-bottom: 1px solid #dd0f43 !important;
}
.gauge-label-left-date {
  position: absolute ;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -86px;
  margin-top: -32px;
  color: #2c2c39;
  font-family: OpenSans;
  font-size: 12px;
}
.gauge-label-left {
  position: absolute ;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -86px;
  margin-top: -18px;
  color: #2c2c39;
  font-family: SansSemiBold;
  font-size: 12px;
}
.gauge-label-right {
  position: absolute ;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 88px;
  margin-top: -18px;
  color: #2c2c39;
  font-family: SansSemiBold;
  font-size: 12px;
}
.gauge-label-right-date {
  position: absolute ;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 88px;
  margin-top: -33px;
  color: #2c2c39;
  font-family: OpenSans;
  font-size: 12px;
}
.chart {
  margin-bottom: 15px;
  margin-top: 15px;
}
.chart--column,
.chart--pie {
  margin-top: 15px;
}
.chart__element__container {
  width: 180px;
  margin: 0 auto 10px;
}
.chart--pie .chart__element__outer {
  position: relative;
  padding: 0;
  padding-bottom: 100%;
  display: block;
  height: 0;
  overflow: hidden;
  width: 180px;
}
.chart--pie .chart__element__inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.chart--pie .chart__element {
  position: relative;
  width: 100%;
  height: 100%;
}
.chart--performance .chart__element {
  height: 220px;
}
.chart--gauge .chart__element {
  height: 180px;
}
.chart--goal-probability .chart__element {
  height: 360px;
}
.highcharts-range-selector-group,
.highcharts-range-selector-buttons {
  display: none;
}
.chart__range {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0;
  overflow: hidden;
}
.chart__range div {
  display: block;
  font-size: 15px;
  cursor: pointer;
  padding: 3px 11px;
  border-radius: 5px;
  color: #B3C0FF;
  text-decoration: none;
}
.chart__range div.active {
  box-shadow: 0 0 5px 0 #4261FF;
  background-color: #4261FF;
  height: 24px;
  width: 45px;
  color: #fff;
  font-family: SansSemibold;
}
.chart__range div:active,
.chart__range div:focus {
  text-decoration: none;
}
.chart--column .chart__element {
  display: flex;
}
.column-chart__item {
  flex: 1 auto;
}
.column-chart__bar-wrapper {
  height: 100%;
  border-bottom: 1px solid #EAECFF;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.column-chart__bar {
  width: 15px;
  height: 0;
  transition: height 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  display: block;
}
.column-chart__text {
  text-align: center;
  padding-top: 10px;
}
.column-chart__item__name {
  display: block;
}
.column-chart__item__value {
  font-weight: 600;
  color: #4261FF;
}
.chart__currency {
  padding: 20% 0;
}
.hide-text text {
  display: none;
}
.list-view {
  width: 100%;
}
.list-view-row {
  display: block;
}
.list-view-row.title {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  font-family: OpenSans;
}
.list-view-row.amount {
  color: #a1b0fc;
  font-size: 13px;
  font-weight: 400;
  font-family: OpenSans;
}
.list-view-title {
  font-size: 13px;
}
.list-view:not(:last-child) {
  margin-bottom: 10px;
}
ul.list-view,
ol.list-view {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}
.list-view tr,
.list-view li {
  border-top: 1px solid #EAECFF;
  border-bottom: 1px solid #EAECFF;
}
.list-view th,
.list-view td {
  padding: 15px 0;
}
.list-view th {
  font-weight: 400;
  width: 50%;
}
.list-view td {
  color: #4261FF;
  font-family: SansSemibold;
}
.list-view li {
  border-bottom: 1px solid #EAECFF;
}
.list-view li a {
  color: #243041;
  position: relative;
  display: block;
  padding: 15px 16px 15px 0;
  text-decoration: none;
}
.list-view li a:after {
  content: "";
  background-image: url("../images/icons/arrow-list.svg");
  background-position: right center;
  background-repeat: no-repeat;
  width: 6px;
  height: 10px;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.list-view li a .icn {
  margin-top: 1px;
}
.text-byline {
  display: block;
  color: #A1B0FC;
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
}
td.text-byline {
  display: table-cell;
  border-top: none;
  padding-top: 0;
  font-family: OpenSans;
  font-size: 13px;
  color: #A1B0FC;
}
.list-view__item--has-value {
  display: flex;
}
.list-view__item__value {
  margin-left: auto;
  font-weight: 600;
  color: #4261FF;
}
.list-action {
  display: inline-flex;
  align-items: center;
}
.list-action__text {
  display: inline-block;
  white-space: nowrap;
}
.list-action__link {
  background-color: initial;
  border: none;
  background-repeat: no-repeat;
  height: 40px;
  padding: 0;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 0 0 10px;
}
.list-action__link--more {
  background-image: url("../images/icons/arrow-list.svg");
  background-position: right center;
}
.list-action__link--expand {
  background-image: url("../images/icons/triangle-right.svg");
  background-repeat: no-repeat;
  background-position: right center;
}
.list-action__link--expand.open {
  position: relative;
  top: 5px;
  background-image: url("../images/icons/triangle-down.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
.table-listing {
  width: 100%;
}
.table-listing:last-child {
  margin-bottom: -1px;
}
.table-listing td,
.table-listing th {
  vertical-align: middle;
  padding-top: 15px;
  padding-bottom: 15px;
}
.table-listing .th,
.table-listing th {
  text-align: left;
  font-weight: 400;
  color: #243041;
}
.table-listing td {
  color: #4261FF;
  font-weight: 600;
}
.table-listing .text-small {
  display: block;
  font-weight: normal;
  color: #B3C0FF;
  font-size: 11px;
}
.table-listing tr {
  padding: 15px !important;
  border-top: 1px solid #EAECFF;
}
.table-listing--status-bar th {
  width: 70px;
  padding-right: 10px;
}
th.bold {
  font-family: SansSemibold;
}
.table-nested {
  margin-bottom: 0;
  font-size: 13px;
}
.table-nested tr:first-child th,
.table-nested tr:first-child td {
  border-top: none;
}
.table-nested td,
.table-nested th {
  padding: 15px 0;
}
tr.row-expandable > td {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 25px;
}
.row-title {
  padding: 5px 0;
  border-top: 1px solid #EAECFF;
  border-bottom: 1px solid #EAECFF;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: #4261ff;
}
td.cell-icn {
  width: 28px;
  padding-right: 10px;
  vertical-align: middle;
}
td.cell-percentage {
  width: 55px;
  font-family: SansSemiBold;
  font-size: 15px;
}
td.cell-amount {
  width: 110px;
  text-align: right;
}
td.cell-tag {
  width: 70px;
  text-align: right;
}
td.cell-default {
  padding-left: 10px;
  padding-right: 10px;
  width: 150px;
}
td.cell-default:first-child {
  padding-left: 0;
}
td.cell-dot {
  padding-right: 5px;
  padding-left: 5px;
  width: 22px;
}
td.cell-more {
  padding-top: 0;
  padding-bottom: 0;
  width: 21px;
}
td.cell-expand {
  padding-top: 0;
  padding-bottom: 0;
  width: 20px;
}
td.cell-truncate {
  position: relative;
}
td.cell-truncate:before {
  content: '&nbsp;';
  visibility: hidden;
}
td.cell-truncate > span {
  position: absolute;
  left: 0;
  right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}
td.cell-status-bar {
  padding: 0;
}
.icn {
  display: inline-block;
  padding-left: 28px;
  position: relative;
  background-repeat: no-repeat;
}
.icn:before {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.icn-main {
  display: inline-block;
  padding-left: 28px;
  position: relative;
}
.icn-main:before {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  content: "";
  width: 25px;
  height: 32px;
  position: absolute;
  left: 0;
  top: -14px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icn--loading {
  padding-left: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto 30px;
}
.icn--loading:before {
  background-image: url("../images/icons/main-icon.svg");
  height: 114px;
  display: block;
  width: 100%;
  top: calc(220px + 57px);
}
.icn--search--default {
  background-image: url("../images/icons/search.svg");
  width: 16px;
  height: 16px;
  background-size: contain;
  margin-top: 5px;
}
.icn--search {
  background-image: url("../images/icons/nav-search.svg");
  width: 16px;
  height: 16px;
}
.icn--notification {
  background-image: url("../images/icons/notification.svg");
  width: 17px;
  height: 17px;
}
.icn--menu-toggle {
  background-image: url("../images/icons/menu-toggle.svg");
  width: 5px;
  height: 17px;
}
.icn--otp-lock {
  background-image: url("../images/icons/otp-lock.svg");
}
.icn--verify-phone-portrait {
  background-image: url("../images/icons/verify-phone-portrait.svg");
}
.icn--withdraw:before {
  background-image: url("/");
}
.icn--funding-received:before {
  background-image: url("../images/icons/funding-receive.svg");
}
.icn--sell:before {
  background-image: url("../images/icons/sell.svg");
}
.icn--buy:before {
  background-image: url("../images/icons/buy.svg");
}
.icn--rebalancing:before {
  background-image: url("../images/icons/rebalancing.svg");
}
.icn--calendar-goal {
  position: absolute;
  left: 94%;
  top: 25px;
}
.icn--calendar-goal:before {
  background-image: url("../images/icons/listing--calendar.svg");
}
.icn--username:before {
  width: 18px;
  height: 18px;
  background-image: url("../images/icons/user-alt-1.svg");
}
.icn--password:before {
  width: 18px;
  height: 18px;
  background-image: url("../images/icons/password-lock.svg");
}
.icn--send {
  position: relative;
  top: 1px;
  width: 50px;
  height: 20px;
  margin: auto;
  font-size: 12px;
  color: white;
  background-color: #4261ff;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 1px #4261ff;
}
.icn--error-message-close:before {
  width: 10px;
  height: 10px;
  background-image: url("../images/icons/close-glyph-alt-inverted.svg");
}
.icn--onboarding-pie-chart:before {
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/onboarding--pie-chart.svg");
}
.icn--onboarding-bulb:before {
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/onboarding-bulb.svg");
}
.icn--onboarding-equalizer:before {
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/onboarding-equalizer.svg");
}
.icn--onboarding-lock:before {
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/onboarding-lock.svg");
}
.icn--fees:before {
  background-image: url("../images/icons/listing--fees.svg");
}
.icn--deposit:before,
.icn--investment:before {
  background-image: url("../images/icons/listing--investment.svg");
}
.icn--performance:before {
  background-image: url("../images/icons/magic-wand.svg");
}
.icn--buy-amount:before {
  background-image: url("../images/icons/arrow-buy-amount.svg");
}
.icn--sell-amount:before {
  background-image: url("../images/icons/arrow-sell-amount.svg");
}
.icn--security:before {
  background-image: url("../images/icons/listing--security.svg");
}
.icn--calendar:before {
  background-image: url("../images/icons/listing--calendar.svg");
}
.icn--withdraw:before {
  background-image: url("../images/icons/nav-more--withdraw.svg");
}
.icn--market:before {
  background-image: url("../images/icons/listing--market.svg");
}
.icn--recurring:before {
  background-image: url("../images/icons/listing--recurring.svg");
}
.icn--strategy:before {
  background-image: url("../images/icons/listing--strategy.svg");
}
.icn--probability:before {
  background-image: url("../images/icons/listing--probability.svg");
}
.icn--projection:before {
  background-image: url("../images/icons/listing--projection.svg");
}
.icn--target:before {
  background-image: url("../images/icons/flag.svg");
}
.icn--generic-document:before {
  background-image: url("../images/icons/documents--generic-document.svg");
}
.icn--monthly-statement:before {
  background-image: url("../images/icons/documents--monthly-statement.svg");
}
.icn--return:before {
  background-image: url("../images/icons/listing--return.svg");
}
.icn--allocation:before {
  background-image: url("../images/icons/listing--allocation.svg");
}
.icn--action-required:before {
  background-image: url("../images/icons/listing--messaging--action-required.svg");
}
.icn--support:before {
  background-image: url("../images/icons/listing--messaging--support.svg");
}
.icn--portfolio-info:before {
  background-image: url("../images/icons/listing--messaging--support.svg");
}
.icn--document:before {
  background-image: url("../images/icons/listing--messaging--new-document.svg");
}
.icn--history:before {
  background-image: url("../images/icons/listing--messaging--history.svg");
}
.icn--recommendation:before {
  background-image: url("../images/icons/listing--messaging--recommendation.svg");
}
.icn--triangle-down,
.icn--triangle-up {
  padding-left: 0;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0;
}
.icn--triangle-down:before,
.icn--triangle-up:before {
  width: 10px;
  height: 10px;
  display: block;
}
.icn--triangle-down:before {
  background-image: url("../images/icons/triangle-down.svg");
}
.icn--triangle-up:before {
  background-image: url("../images//icons/triangle-up.svg");
}
.icn--not-found,
.icn--hour-glass,
.icn--frown,
.icn--frown-highlighted,
.icn--locked,
.icn--time {
  padding-left: 0;
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.icn--not-found:before,
.icn--hour-glass:before,
.icn--frown:before,
.icn--frown-highlighted:before,
.icn--locked:before,
.icn--time:before {
  width: 80px;
  height: 80px;
  display: block;
}
.icn--confirmation,
.icn--reset-password {
  padding-left: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 10px;
}
.icn--confirmation:before,
.icn--reset-password:before {
  width: 64px;
  height: 64px;
  display: block;
}
.icn--reset-password:before {
  background-image: url("../images/icons/lock-unlocked.svg");
}
.icn--confirmation:before {
  background-image: url("../images/icons/envolope-default.svg");
}
.icn--mail:before {
  width: 18px;
  height: 18px;
  background-image: url("../images/icons/envolope-white.svg");
  background-size: contain;
}
.icn--note-pen {
  padding-left: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 30px;
}
.icn--note-pen:before {
  content: " ";
  width: 64px;
  height: 64px;
  display: block;
  background-image: url("../images/icons/note-pen.svg");
  background-size: 100% 100%;
}
.icn--right-tick,
.icn--right-tick-highlighted {
  padding-left: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 30px;
}
.icn--right-tick:before,
.icn--right-tick-highlighted:before {
  width: 64px;
  height: 64px;
  display: block;
}
.icn--right-tick {
  margin-bottom: 16px;
}
.icn--right-tick:before {
  background-image: url("../images/icons/rightTick.svg");
}
.icn--right-tick-highlighted:before {
  background-image: url("../images/icons/rightTickHighlighted.svg");
}
.icn--not-found:before {
  background-image: url("../images/icons/not-found.svg");
}
.icn--frown:before {
  background-image: url("../images/icons/frown-icon.svg");
  background-size: 64px 64px;
}
.icn--frown-highlighted:before {
  background-image: url("../images/icons/frown-icon-highlighted.svg");
  background-size: 64px 64px;
}
.icn--hour-glass:before {
  background-image: url("../images/icons/hourglass.svg");
}
.icn--locked:before {
  background-image: url("../images/icons/lock-locked.svg");
}
.icn--time:before {
  background-image: url("../images/icons/time-default.svg");
}
.icn--time-small:before {
  background-image: url("../images/icons/time-default.svg");
}
.icn--trophy:before,
.icn--badge:before,
.icn--shield:before,
.icn--file:before {
  width: 18px;
  height: 18px;
}
.icn--trophy:before {
  background-image: url("../images/icons/trophy.svg");
}
.icn--badge:before {
  background-image: url("../images/icons/badge.svg");
}
.icn--shield:before {
  background-image: url("../images/icons/shield-default.svg");
}
.icn--file:before {
  background-image: url("../images/icons/file-single-alt.svg");
}
.icn--plus {
  padding: 6px;
  margin-left: 6px;
  width: 8px;
  height: 9px;
}
.icn--plus:before {
  background-image: url("../images/icons-png/plus.png");
}
.icn--menu {
  width: 8px;
  height: 9px;
}
.icn--menu:before {
  background-image: url("../images/icons-png/menu-vertical.png");
}
.icn--camera {
  width: 24px;
  height: 20px;
}
.icn--camera:before {
  width: 24px;
  height: 26px;
  background-size: 24px;
  background-image: url("../images/icons-png/camera.png");
}
.icn--microphone {
  width: 10px;
  height: 20px;
}
.icn--microphone:before {
  width: 18px;
  height: 22px;
  background-size: 22px;
  background-image: url("../images/icons-png/microphone.png");
}
.icn--close {
  float: right;
  padding-left: 0;
  width: 10px;
  height: 10px;
}
.icn--close:before {
  background-image: url("../images/icons/close-glyph-alt.svg");
  width: 10px;
  height: 10px;
}
.icn--close-blue {
  float: right;
  padding-left: 0;
  width: 10px;
  height: 10px;
}
.icn--close-blue:before {
  background-image: url("../images/icons/close-glyph-alt-blue.svg");
  width: 10px;
  height: 10px;
}
.icn--shortfall:before {
  background-image: url("../images/icons/shortfall.svg");
}
.icn--excess:before {
  background-image: url("../images/icons/excess.svg");
}
.icn--referral:before {
  background-image: url("../images/icons/referral.svg");
  width: 64px;
  height: 64px;
  display: block;
}
.icn--referral {
  padding-left: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 25px;
}
.icn--dashboard-title {
  margin: 2px 0 0 2px;
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
.icn-instruction-adjust {
  position: relative;
  bottom: 4px;
}
.invest-summary {
  text-align: center;
  padding: 0 0 20px 0;
  line-height: 1;
  position: relative;
}
.invest-summary .amount-value {
  font-size: 36px;
  margin-bottom: 10px;
  position: relative;
}
.invest-summary .currency {
  font-size: 13px;
  position: absolute;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  font-family: SansSemiBold;
  -webkit-transform: translate(-117%);
  transform: translate(-117%);
  top: 4px;
}
.invest-summary .amount-change {
  font-size: 15px;
}
.invest-summary__icon {
  margin-bottom: 10px;
  display: block;
}
.invest-summary__icon .icn {
  width: 24px;
  height: 24px;
}
.amount-positive {
  color: #25C177;
}
.amount-negative {
  color: #FF376B;
}
.amount-change {
  color: #25C177;
  font-family: SansSemibold;
  margin-bottom: 0;
  font-weight: 600;
  padding-left: 14px;
  position: relative;
  display: inline-block;
}
.amount-change:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 8px;
  height: 8px;
  background-image: url("../images/icons/amount-increase.svg");
  background-repeat: no-repeat;
}
.amount-change--decrease {
  color: #FF376B;
}
.amount-change--decrease:before {
  background-image: url("../images/icons/amount-decrease.svg");
}
.line-chart {
  margin-left: -20px;
  margin-right: -20px;
}
.dot {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
table .dot {
  position: relative;
  top: 1px;
}
.status-bar {
  display: block;
  height: 10px;
  border-radius: 5px;
}
.tag {
  background-color: #4261FF;
  font-weight: 400;
  height: 25px;
  color: #ffffff;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 13px;
}
.tag--arrow:after {
  content: "";
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../images/icons/tag-arrow-right.svg");
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 3px;
}
.tag--arrow.tag--arrow--up:after {
  background-image: url("../images/icons/tag-arrow-up.svg");
}
.tag--arrow.tag--arrow--down:after {
  background-image: url("../images/icons/tag-arrow-down.svg");
}
.tag--arrow.tag--arrow--right:after {
  background-image: url("../images/icons/tag-arrow-right.svg");
}
.tag--positive {
  background-color: #25C177;
}
.tag--neutral {
  background-color: #4261FF;
}
.tag--negative {
  background-color: #FF376B;
}
.warning {
  display: inline-block;
  width: 12px;
  height: 11px;
  background-image: url('../images/icons/warning.svg');
  background-repeat: no-repeat;
  background-size: 12px 11px;
}
.tooltip-toggle {
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-left: 5px;
  width: 12px;
  height: 12px;
  background: url("../images/icons/tool-tip-question-mark.svg") no-repeat;
  background-size: contain;
  z-index: 2;
}
.referal {
  margin-top: 20px;
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -20px;
  padding: 20px;
  text-align: center;
}
.referal .btn {
  margin-top: 15px;
}
.disclaimer {
  font-family: OpenSans;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #a1b0fc;
}
.loading {
  width: 100vw;
  background-color: #4261ff;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10000;
}
.loading .header-main {
  display: none;
}
.loading-indicator {
  margin: 50px auto;
  width: 34px;
  height: 30px;
  text-align: center;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
}
.loading-indicator > div {
  background-color: #B3C0FF;
  height: 100%;
  width: 2px;
  display: block;
  animation: loading-bars 1.2s infinite ease-in-out;
}
.loading-indicator .rect2 {
  animation-delay: -1.1s;
}
.loading-indicator .rect3 {
  animation-delay: -1s;
}
.loading-indicator .rect4 {
  animation-delay: -0.9s;
}
.loading-indicator .rect5 {
  animation-delay: -0.8s;
}
@keyframes loading-bars {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
.scroll-sticky {
  box-shadow: 0 0 5px 0 rgba(66, 97, 255, 0);
  transition: box-shadow 0.15s;
}
.is-sticky {
  box-shadow: 0 0 5px 0 rgba(66, 97, 255, 0.1);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1030;
}
.goal-selector {
  min-height: 200px;
  margin-top: 20px;
  margin-right: -20px;
  margin-left: -20px;
}
.goal {
  padding: 0 20px;
}
.goal label {
  display: block;
  cursor: pointer;
}
.goal--title {
  width: 335px;
  height: 30%;
  float: none;
  margin: 0 auto;
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #4261ff;
}
.goal__icon {
  display: block;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
}
.goal__icon img {
  width: 100%;
}
.goal__name {
  display: flex;
  justify-content: center;
  font-family: OpenSans, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4261ff;
}
.goal__description {
  display: block;
  font-size: 15px;
}
.goal-selector--cockpit.goal-selector {
  min-height: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.goal-selector--cockpit .goal {
  width: 50%;
  text-align: center;
  margin-bottom: 32px;
}
.goal-selector--cockpit .goal__icon {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}
.goal--investment:before {
  background-image: url("../images/icons/goal--investment.svg");
}
.goal--holiday:before {
  background-image: url("../images/icons/goal--holiday.svg");
}
.goal--home:before {
  background-image: url("../images/icons/goal--home.svg");
}
.goal--education:before {
  background-image: url("../images/icons/goal--education.svg");
}
.goal--renovation:before {
  background-image: url("../images/icons/goal--renovation.svg");
}
.goal--children:before {
  background-image: url("../images/icons/goal--children.svg");
}
.goal--retirement:before {
  background-image: url("../images/icons/goal--retirement.svg");
}
.goal--others:before {
  background-image: url("../images/icons/goal--others.svg");
}
.goal--event:before {
  background-image: url("../images/icons/goal--event.svg");
}
.goal--present:before {
  background-image: url("../images/icons/goal--present.svg");
}
.icn--calculator:before {
  background-image: url('../../css/images/icons/calculator.svg');
}
.icn--action-required:before {
  background-image: url("../images/icons/listing--messaging--action-required.svg");
}
.icn--support:before {
  background-image: url("../images/icons/listing--messaging--support.svg");
}
.icn--portfolio-info:before {
  background-image: url("../images/icons/listing--messaging--support.svg");
}
.icn--document:before {
  background-image: url("../images/icons/listing--messaging--new-document.svg");
}
.icn--history:before {
  background-image: url("../images/icons/listing--messaging--history.svg");
}
.icn--recommendation:before {
  background-image: url("../images/icons/listing--messaging--recommendation.svg");
}
.icn--edit--goal--topup {
  white-space: nowrap;
}
.icn--edit--goal--topup:before {
  background-image: url("../images/icons/edit--goal--topup.svg");
}
.icn--edit--goal--withdraw {
  white-space: nowrap;
}
.icn--edit--goal--withdraw:before {
  background-image: url("../images/icons/edit--goal--withdraw.svg");
}
.icn--dashboard-title {
  margin: 2px 0 0 2px;
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
.icn--completion:before {
  background-image: url('../images/icons/arrow-glyph-up-right-alt.svg');
}
.icn--status:before {
  background-image: url('../images/icons/screen-desktop.svg');
}
.icn--expected-amount,
.icn--strategy {
  white-space: nowrap;
}
.icn--expected-amount:before {
  background-image: url('../images/icons/diamond.svg');
}
.icn--target-amount:before {
  background-image: url('../images/icons/listing--target.svg');
}
.icn--details:before {
  background-image: url('../images/icons/details.svg');
}
.goal--holiday:before {
  background-image: url("../images/icons/goal--holiday.svg");
}
.icn {
  white-space: nowrap;
}
.tablist {
  margin: 0 -20px 0px;
  padding: 0 20px;
  position: relative;
  box-shadow: 0 3px 3px -2px rgba(66, 97, 255, 0.2);
}
.tablist span {
  color: #b3c0ff;
  padding: 15px 0 10px;
  display: block;
  border-bottom: 3px solid #fff;
  text-decoration: none;
  text-align: center;
}
.tablist::-webkit-scrollbar {
  display: none;
}
.tablist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tablist li.selected a {
  position: relative;
  color: #000000;
  font-weight: 600;
  text-align: center;
}
.tablist li.selected a:after {
  content: "";
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #4261FF;
  display: block;
  box-shadow: 0 0 5px 0 rgba(66, 97, 255, 0.5);
}
.tablist li + li {
  margin-left: 20px;
}
.tablist a {
  color: #B3C0FF;
  font-weight: 400;
  padding: 15px 0 10px;
  display: block;
  border-bottom: 3px solid #ffffff;
  text-decoration: none;
  text-align: center;
}
.tablist .slick-prev,
.tablist .slick-next {
  z-index: 10;
  top: 17px;
}
.tablist .slick-prev {
  left: -17px;
}
.tablist .slick-next {
  right: -17px;
}
.tablist__btn {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 2;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tablist__btn:before {
  content: "";
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 7px;
  height: 12px;
  display: block;
}
.tablist__btn--prev {
  left: 0;
}
.tablist__btn--prev:before {
  background-image: url("../images/icons/tab-nav-left.svg");
}
.tablist__btn--next {
  right: 0;
}
.tablist__btn--next:before {
  background-image: url("../images/icons/tab-nav-right.svg");
}
.progressbar {
  margin: 0 -20px 0px;
  padding: 0 20px;
  position: relative;
  box-shadow: 0 3px 3px -2px rgba(66, 97, 255, 0.2);
}
.progressbar::-webkit-scrollbar {
  display: none;
}
.progressbar__wrapper div {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.progressbar__wrapper li {
  flex-shrink: 0;
  flex: 1;
  padding-bottom: 15px;
  position: relative;
  width: 33.333333%;
}
.progressbar__wrapper li.complete:after {
  content: "";
  height: 1px;
  width: 100%;
  display: block;
  background-color: #4261FF;
  box-shadow: 0 0 5px 0 #4261FF;
  position: absolute;
  left: 0;
  bottom: 0px;
}
.progressbar__wrapper li.complete a {
  font-size: 13px;
  flex-direction: column;
}
.progressbar__wrapper li.complete span {
  flex-direction: column;
}
.progressbar__wrapper li.active:before {
  content: "";
  background-color: #4261FF;
  width: 14px;
  height: 14px;
  border: 3px solid #ffffff;
  position: absolute;
  left: 50%;
  bottom: 2px;
  transform: translate(-50%, 50%);
  z-index: 2;
  border-radius: 50%;
}
.progressbar__wrapper li.active:after {
  content: "";
  height: 1px;
  box-shadow: 0 0 5px 0 #4261FF;
  width: 50%;
  display: block;
  background-color: #4261FF;
  position: absolute;
  left: 0;
  bottom: 0;
}
.progressbar__wrapper li.active span,
.progressbar__wrapper li.active a {
  color: #243041;
  font-weight: 600;
}
.progressbar__wrapper a,
.progressbar__wrapper li > span {
  position: relative;
  color: #B3C0FF;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 36px;
}
.progressbar__wrapper .slick-prev,
.progressbar__wrapper .slick-next {
  z-index: 10;
  top: 9px;
}
.progressbar__wrapper .slick-prev {
  left: -17px;
}
.progressbar__wrapper .slick-next {
  right: -17px;
}
.progressbar__wrapper .value {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: #4261FF;
}
.progressbar__wrapper .icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: 4px;
}
.progressbar__wrapper .icon:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-image: url("../images/icons/progressbar--complete.svg");
  background-repeat: no-repeat;
}
.slider {
  padding-top: 5px;
}
.slider-container {
  width: 100%;
  height: 16px;
}
.slider__risks {
  -webkit-appearance: none;
  width: 335px;
  height: 3px;
  background: linear-gradient(to right, #eaecff 75%, #9dabbf 25%);
  border-radius: 5px;
}
.slider__initial {
  -webkit-appearance: none;
  width: 335px;
  height: 3px;
  background: linear-gradient(to right, red 65%, grey 0%);
}
.slider__risks:active::-webkit-slider-thumb,
.slider__initial:active::-webkit-slider-thumb {
  background-color: blue;
}
.slider__risks::-webkit-slider-thumb,
.slider__initial::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 21px;
  height: 21px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0.5px rgba(66, 97, 255, 0.5);
  background: white;
  cursor: pointer;
  z-index: 4;
}
.slider__risks::-moz-range-thumb,
.slider__initial::-moz-range-thumb {
  width: 21px;
  height: 21px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0.5px rgba(66, 97, 255, 0.5);
  background: white;
  cursor: pointer;
  z-index: 2;
}
.slider-text-container {
  display: flex;
  justify-content: space-between;
}
.slider-sub-text {
  font-family: 'OpenSans';
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #a1b0fc;
}
.slider__legend {
  color: #B3C0FF;
  font-size: 13px;
  font-weight: 300;
  display: flex;
  padding-top: 10px;
  padding-bottom: 5px;
}
.slider__max {
  margin-left: auto;
}
.carousel {
  padding-bottom: 30px;
  margin-bottom: 15px;
  width: 100%;
}
.layout--inverted,
.layout--inverted .header-main,
.layout--inverted .content-main {
  background-color: #4261FF;
}
.layout--inverted p,
.layout--inverted .content-lead,
.layout--inverted .section-title,
.layout--inverted .header-main,
.layout--inverted .content-main {
  color: #ffffff;
}
.layout--inverted .content-lead {
  line-height: normal;
  letter-spacing: 0.3px;
}
.layout--inverted a {
  color: #26D4A5;
}
.layout--inverted a:active,
.layout--inverted a:hover {
  color: #26D4A5;
}
.layout--inverted .content-main {
  align-items: stretch;
  display: flex;
}
.layout--inverted .content-main .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.layout--inverted form {
  width: 100%;
  flex: 1 auto;
}
.layout--inverted form p {
  color: #A1B0FC;
  width: 100%;
}
.layout--inverted .radio label,
.layout--inverted .checkbox label,
.layout--inverted .control-label,
.layout--inverted .form-control {
  color: #ffffff;
}
.layout--inverted .radio label:before,
.layout--inverted .checkbox label:before {
  border-color: #ffffff;
}
.layout--inverted .radio input:checked + label:after,
.layout--inverted .checkbox input:checked + label:after {
  background-color: #ffffff;
}
.layout--inverted .select {
  background-color: transparent;
}
.layout--inverted .select:after {
  background-image: url("../images/icons/select-arrow--inverted.svg");
}
.layout--inverted .input-validation-error {
  background-color: #FF376B;
  color: #ffffff;
}
.layout--inverted .form-actions__item p {
  color: #A1B0FC;
}
.layout--inverted .btn-default {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.layout--inverted .btn-primary {
  background-color: #26D4A5;
  border-color: #26D4A5;
  color: #ffffff;
}
.layout--inverted .toggle-icon g {
  fill: #ffffff;
}
.layout--inverted .btn-secondary {
  color: #4261FF;
  border-color: #ffffff;
  background-color: #ffffff;
}
.text-bold {
  font-weight: 600 !important;
}
.text-normal {
  font-weight: normal !important;
}
.text-reset {
  font-weight: normal;
  font-style: normal;
  color: inherit;
  text-align: inherit;
}
.text-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 225px;
  white-space: nowrap;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flex-row {
  display: flex;
}
@media screen and (min-width: 768px) {
  .flex-row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-center {
  margin: 0 auto;
}
.container-full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
p:last-child,
ul:last-child,
ol:last-child,
.panel:last-child {
  margin-bottom: 0;
}
.m-b-r {
  margin-bottom: 0 !important;
}
.m-b-xs {
  margin-bottom: 7.5px !important;
}
.m-b-sm {
  margin-bottom: 15px !important;
}
.m-b-md {
  margin-bottom: 30px !important;
}
.m-b-lg {
  margin-bottom: 60px !important;
}
.m-b-xl {
  margin-bottom: 90px !important;
}
.m-t-r {
  margin-top: 0 !important;
}
.m-t-xs {
  margin-top: 7.5px !important;
}
.m-t-sm {
  margin-top: 15px !important;
}
.m-t-md {
  margin-top: 18px!important;
}
.m-t-lg {
  margin-top: 60px !important;
}
.m-t-xl {
  margin-top: 7.5px !important;
}
.m-r-r {
  margin-right: 0 !important;
}
.m-r-xs {
  margin-right: 7.5px !important;
}
.m-r-sm {
  margin-right: 15px !important;
}
.m-r-md {
  margin-right: 30px !important;
}
.m-r-lg {
  margin-right: 60px !important;
}
.m-r-xl {
  margin-right: 90px !important;
}
.m-l-r {
  margin-left: 0 !important;
}
.m-l-xs {
  margin-left: 7.5px !important;
}
.m-l-sm {
  margin-left: 15px !important;
}
.m-l-md {
  margin-left: 30px !important;
}
.m-l-lg {
  margin-left: 60px !important;
}
.m-l-xl {
  margin-left: 90px !important;
}
.p-t-xs {
  padding-top: 7.5px !important;
}
.p-t-sm {
  padding-top: 15px !important;
}
.p-t-md {
  padding-top: 30px !important;
}
.p-t-lg {
  padding-top: 60px !important;
}
.p-t-xl {
  padding-top: 90px !important;
}
.p-t-r {
  padding-top: 5px!important;
  padding-bottom: 0 !important;
}
.p-b-xs {
  padding-bottom: 7.5px !important;
}
.p-b-sm {
  padding-bottom: 15px !important;
}
.p-b-md {
  padding-bottom: 30px !important;
}
.p-b-lg {
  padding-bottom: 60px !important;
}
.p-b-xl {
  padding-bottom: 90px !important;
}
.p-l-r {
  padding-left: 0 !important;
}
.p-l-xs {
  padding-left: 7.5px !important;
}
.p-l-sm {
  padding-left: 15px !important;
}
.p-l-md {
  padding-left: 30px !important;
}
.p-l-lg {
  padding-left: 60px !important;
}
.p-l-lg-xl {
  padding-left: 90px !important;
}
.p-r-r {
  padding-right: 0 !important;
}
.p-lr-xs {
  padding-right: 7.5px !important;
}
.p-r-sm {
  padding-right: 15px !important;
}
.p-r-md {
  padding-right: 30px !important;
}
.p-r-lg {
  padding-right: 60px !important;
}
.p-r-xl {
  padding-right: 90px !important;
}
.funding-screen {
  padding: 120px 0;
}
.funding-screen .container {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  white-space: pre-line;
  padding-top: 10px;
}
.funding-screen .container span {
  color: #4261ff;
  font-weight: bold;
}
.text-status {
  font-family: OpenSans;
  font-size: 13px;
  color: #a1b0fc;
  margin-top: -22px;
}
.pop-up-box {
  visibility: hidden;
  -webkit-animation: fadeInOut 3s linear forwards;
  animation: fadeInOut 3s linear forwards;
  opacity: 0;
  display: inline-block;
  position: relative;
  top: 15px;
  padding: 3px 3px;
  background-color: #4261ff;
  box-shadow: 0px 0px 4px 0px #4261ff;
}
.pop-up-box:after {
  content: '';
  top: 27px;
  right: 24px;
  position: relative;
  border-style: solid;
  border-width: 4px;
  border-color: #4261ff transparent transparent transparent;
}
@-webkit-keyframes fadeInOut {
  5%,
  95% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes fadeInOut {
  5%,
  95% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
  }
}
.pop-up-text {
  position: relative;
  bottom: 1px;
  left: 4px;
  color: white;
}
.inverted-input {
  color: #4261FF;
  text-align: center;
  width: 100%;
  border: none;
  border-bottom: 1px solid #eaecff;
  font-family: OpenSans;
  font-size: 24px;
  height: 55px;
  outline-width: 0;
}
.referral-text {
  letter-spacing: 0.4px;
}
.text-link-normal {
  font-family: OpenSans;
  letter-spacing: 0.4px;
  color: #26d4a5;
}
.generic-page-title {
  margin-bottom: 9px !important;
  white-space: pre-line;
  font-family: SansSemibold;
  font-size: 20px;
  line-height: 1.5;
}
.generic-page-footer__text {
  margin-top: 20px;
  color: #a1b0fc !important;
  letter-spacing: 0.44px;
}
.generic-page-footer__padding {
  margin-top: 40%;
}
.generic-page-icon__container {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 13%;
}
.generic-page-icon__loading {
  position: relative;
  color: #26d4a5 !important;
}
.generic-page-icon__text {
  font-size: 15px;
  position: absolute;
  top: 40%;
}
.generic-page-email-input__container {
  margin: 50px 0 30px;
}
.icn--generic-item {
  margin-top: 18px;
}
.icn--generic-item::before {
  top: 0;
  padding-top: 10px;
}
.icn--generic-item-more {
  margin: 13px 0 13px auto;
  width: 24px;
}
.generic-item {
  font-family: OpenSans;
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #eaecff;
  font-size: 15px;
  z-index: 10;
}
.generic-item-left {
  white-space: nowrap;
}
.generic-info-right {
  color: #4261ff;
  font-weight: 600;
  height: 100%;
}
.generic-subtext {
  font-size: 13px;
  color: #a1b0fc;
}
.generic-notification {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #2c2c39;
}
.generic-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.generic-right-text {
  font-family: SansSemibold;
  color: #4261ff;
}
.borderless {
  border: none;
}
.otp-title {
  font-family: SansSemibold;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: normal;
}
.otp-description {
  font-family: OpenSans;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.3px;
  white-space: pre-line;
}
.otp-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  padding-top: 5px;
}
.otp-code {
  border: 0;
  outline-width: 0;
  width: 40px;
  height: 55px;
  font-family: OpenSans;
  font-size: 24px;
  text-align: center;
  border-bottom: 1px solid #7a91ff;
  background-color: #4261ff;
}
.otp-code:disabled {
  opacity: 1;
  -webkit-text-fill-color: white;
}
.otp-code:focus {
  border-bottom: 2px solid white;
}
.otp-timer {
  font-family: OpenSans;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: white;
  padding-top: 10px;
}
.otp-button {
  margin: 30px auto 0;
  width: 335px;
  height: 45px;
  font-family: SansSemibold;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #26d4a5;
}
.otp-button:focus {
  outline: none;
}
.otp-footer {
  padding-top: 40px;
}
.otp-footer-description {
  height: 100px;
  margin: 0 auto;
  font-family: OpenSans;
  font-size: 15px;
  line-height: normal;
  text-align: left;
  letter-spacing: 0.4px;
  color: white;
}
.otp-icon {
  margin: 0 auto 25px;
  width: 64px;
  height: 64px;
}
.otp-bottom-space {
  margin: 80px auto 0;
}
.code-num-highlight {
  border-bottom: 2px solid white;
}
.dropdown-item {
  width: 100%;
  border: none;
  padding: 5px 0px;
  line-height: 1;
  height: 34px;
  background: url('../../css/images/icons//angle-bold-down.svg') no-repeat right;
  color: #4261FF;
  background-size: 16px;
  -webkit-appearance: none;
  background-position-y: -5px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}
.dropdown-item:focus {
  outline: 0px;
}
.dropdown-item option {
  background-color: none;
}
.phone-number-drop-down {
  display: flex;
  align-items: center;
  padding-right: 10%;
  width: 33%;
  -webkit-appearance: none;
  font-family: SansSemibold;
  font-size: 20px;
  color: #a1b0fc;
  background: url('../../css/images/icons/angle-bold-down.svg') no-repeat right;
  background-size: 16px;
  border: none;
  border-bottom: 1px solid #eaecff;
}
.phone-number-drop-down:focus {
  outline: none;
}
.phone-number-drop-down .dial-code {
  padding-left: 10px;
  color: #4261ff;
}
.phone-number-flag {
  margin-top: 2px;
  width: 23px;
  height: 15px;
  background-size: 100% 100% !important;
}
.phone-number-flag__container {
  width: 38px;
  height: 32px;
  display: flex;
  align-items: center;
}
#country-code {
  display: flex;
  align-items: center;
}
#country-code .modal-container {
  width: 351px;
  height: 412px;
  overflow: scroll;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(66, 97, 255, 0.2);
  background-color: white;
  margin: 0 auto;
}
#country-code .modal-container .modal-content-main {
  padding-left: 8px;
  padding-right: 8px;
}
#country-code .modal-container .modal-content-main .search-header {
  width: 100%;
  margin-top: 6px;
}
#country-code .modal-container .modal-content-main .search-header .search-group {
  display: flex;
  align-items: center;
  position: relative;
}
#country-code .modal-container .modal-content-main .search-header .search-group .input-search {
  padding-left: 48px;
  width: 100%;
  color: #2c2c39;
  font-size: 15px;
  font-family: OpenSans;
}
#country-code .modal-container .modal-content-main .search-header .search-group .input-search::placeholder {
  font-family: OpenSans;
  font-size: 15px;
  color: #a1b0fc;
}
#country-code .modal-container .modal-content-main .search-header .search-group .search-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
}
#country-code .modal-container .modal-content-main .search-header .search-group .search-content .icon-search {
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  color: #4261ff;
}
#country-code .modal-container .modal-content-main .country-body {
  display: block;
  max-height: 360px;
  overflow: scroll;
  width: 100%;
}
#country-code .modal-container .modal-content-main .country-body .country-row {
  border-bottom: 1px solid #eaecff;
}
#country-code .modal-container .modal-content-main .country-body .country-row:last-child {
  border-bottom: none;
}
#country-code .modal-container .modal-content-main .country-body .country-row-no-result-message {
  padding-left: 48px;
  height: 28px;
  padding-top: 8px;
  color: #2c2c39;
}
.border-error__bottom {
  border-bottom: 1px solid #dd0f43;
}
.dropdown-container {
  position: absolute;
  top: 100%;
  width: 351px;
  max-height: 187px;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(66, 97, 255, 0.2);
  left: -8px;
  overflow: auto;
  background: #fff;
}
.dropdown-container .dropdown-body {
  padding: 8px;
}
.dropdown-container .dropdown-body .country-row {
  border-bottom: 1px solid #eaecff;
}
.dropdown-container .dropdown-body .country-row:last-child {
  border-bottom: none;
}
.dropdown-container .country-body .country-row {
  border-bottom: 1px solid #eaecff;
}
.dropdown-container .country-body .country-row:last-child {
  border-bottom: none;
}
.line {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
}
.line .flag-content {
  width: 40px;
  display: flex;
  justify-content: center;
}
.line .flag-content .flag {
  display: block;
  height: 15.5px;
  width: 24px;
  font-family: OpenSans;
  font-size: 15px;
  background-size: 100% 100% !important;
}
.line .country-name {
  font-family: OpenSans;
  font-size: 15px;
  display: flex;
  height: 100%;
  width: 80%;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  line-height: 1.87;
}
.line .country-name.border-error__bottom {
  color: #2c2c39;
}
.line .none-active-name {
  color: #2c2c39;
}
.line .active-name {
  color: #4261ff;
}
.line .icon-content {
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.line .icon-content .icon {
  display: block;
  width: 16px;
  height: 16px;
}
.line .icon-content .check-sticker {
  background: url("../../css/images/icons/check-glyph-alt.svg") no-repeat;
  background-size: 16px 16px;
}
.ch-flag {
  background: url("../../css/images/flags/Switzerland.svg") no-repeat;
}
.vietnam-flag {
  background: url("../../css/images/flags/Vietnam.svg") no-repeat;
}
.singapore-flag {
  background: url("../../css/images/flags/Singapore.svg") no-repeat;
}
.id-flag {
  background: url("../../css/images/flags/ID.svg") no-repeat;
}
.at-flag {
  background: url("../../css/images/flags/AT.svg") no-repeat;
}
.au-flag {
  background: url("../../css/images/flags/AU.svg") no-repeat;
}
.my-flag {
  background: url("../../css/images/flags/MY.svg") no-repeat;
}
.th-flag {
  background: url("../../css/images/flags/TH.svg") no-repeat;
}
.de-flag {
  background: url("../../css/images/flags/DE.svg") no-repeat;
}
.jp-flag {
  background: url("../../css/images/flags/JP.svg") no-repeat;
}
.ph-flag {
  background: url("../../css/images/flags/PH.svg") no-repeat;
}
.expandable-view-row {
  border-top: 1px solid #eaecff;
}
.expandable-view-row div {
  width: 100%;
  margin-top: 2px;
  padding: 16px 0 16px 0;
  font-family: OpenSans;
  font-size: 15px;
  color: #2c2c39;
  letter-spacing: 0.29px;
}
.expandable-view-title {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.expandable-view-description {
  margin-bottom: 23px !important;
  font-family: OpenSans;
  font-size: 15px;
  color: #2c2c39;
  letter-spacing: 0.25px;
  white-space: pre-line;
}
.row-padding__none {
  padding: 0 !important;
}
.row-padding__compliance-declaration {
  padding: 0 !important;
  height: 53px;
}
.range-slider-input-hidden-text {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 40px;
  max-width: 100%;
  font-size: 24px;
  display: inline-block;
  word-break: break-word;
  visibility: hidden;
  white-space: pre-wrap;
  padding: 0 8px;
}
.range-slider__thumb {
  background-color: white !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 12px;
  box-shadow: 0 0 5px 0.5px rgba(66, 97, 255, 0.5);
}
.range-slider__activated {
  background-color: #4261ff !important;
  box-shadow: none;
}
.range-slider__track {
  color: #4261ff;
}
.range-slider__track.before {
  background-color: #4261ff;
  height: 6px;
}
.range-slider__track.after {
  background-color: #a1b0fc;
}
.input-slider--right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.input-slider--title__container {
  display: flex;
  padding: 15px 0;
  align-items: center;
  justify-content: space-between;
}
.input-slider--title__text {
  font-size: 13px;
  color: #2c2c39;
  letter-spacing: 0.5px;
}
.input-slider--title__currency,
.input-slider--title__date {
  font-family: OpenSans;
  color: #a1b0fc;
  margin: 5px 5px 0 10px;
}
.input-slider--title__currency {
  font-size: 15px;
}
.input-slider--title__date {
  font-size: 15px;
}
.input-slider--title__input {
  font-family: SansSemibold;
  font-size: 24px;
  height: 50px;
  max-width: 100%;
  color: #4261ff;
  text-align: center;
  border: none;
  border-bottom: 1px solid #a1b0fc;
  padding: 0 8px;
}
.input-slider--title__input:focus {
  outline: none;
}
.input-slider--range-value {
  display: flex;
  margin-top: 20px;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #a1b0fc;
  justify-content: space-between;
}
.success-modal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 18px;
}
.success-modal .modal-dialog {
  width: 100%;
  margin: 15px;
}
.success-modal .modal-dialog .modal-header {
  text-align: left;
  font-size: 15px;
  padding: 15px 15px 10px 15px;
}
.success-modal .modal-dialog .modal-header span {
  margin-top: 5px;
}
.success-modal .modal-dialog .modal-body {
  text-align: left;
  font-size: 15px;
  color: #4261ff;
  padding: 0px 15px 20px 15px;
}
.loading-icon__inner {
  position: relative;
  top: 30%;
  height: 120px;
}
.loading-icon__container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility, opacity 0.5s ease-in-out;
  -ms-transition: visibility, opacity 0.5s ease-in-out;
  transition: visibility, opacity 0.5s ease-in-out;
}
.loading-icon__container.open {
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility, opacity 0.5s ease-in-out;
  -ms-transition: visibility, opacity 0.5s ease-in-out;
  transition: visibility, opacity 0.5s ease-in-out;
}
.loading-icon__loading {
  position: relative;
  color: #26d4a5 !important;
}
.loading-icon__text {
  font-size: 15px;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.pop-up__container {
  width: 100%;
}
.pop-up__message {
  white-space: pre-line;
}
.pop-up__footer {
  padding-bottom: 20px;
}
.transaction-text-left {
  font-family: SansSemibold;
  font-size: 20px;
  line-height: 30px;
  color: #4261ff;
}
.icn-open {
  top: 7px !important;
  margin-right: 2px;
  height: 0;
  padding: 6px 10px;
}
.icn-closed {
  margin-right: 10px;
  padding: 6px 6px;
}
.transaction-history-search {
  width: 75vw;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #4261FF;
  border: none;
}
.transaction-history-search:focus {
  outline-width: 0;
}
.transaction-history-search::placeholder {
  color: #a1b0fc;
  font-weight: normal;
}
.no-transaction {
  height: 20px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  margin-top: 50px;
}
.support-title-text {
  font-size: 13px;
  color: #2c2c39;
  line-height: 18px;
  letter-spacing: 0.5px;
}
.support-title-input {
  border: none;
  padding: 4px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaecff;
  font-family: SansSemibold;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #4261ff;
  resize: none;
  overflow: hidden;
}
.support-title-input:focus {
  outline: none;
}
.support-message {
  margin-top: 2px;
  height: 100%;
  border: none;
  font-family: OpenSans;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #2c2c39;
  resize: none;
}
.support-message:focus {
  outline: none;
}
.support-message::placeholder {
  font-family: OpenSans;
  font-style: italic;
  font-size: 13px;
  color: #a1b0fc;
}
.btn-send:focus {
  outline: none;
}
.with-draw .account {
  border-top: 1px solid #eaecff;
  border-bottom: 1px solid #eaecff;
  margin-bottom: 30px;
  padding: 10px 0;
}
.with-draw .goal-name {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: #2c2c39;
}
.with-draw option {
  letter-spacing: 0.5px;
  color: #2c2c39;
}
.list-view .description {
  height: 115px;
  vertical-align: top;
}
.list-view .description p {
  color: #2c2c39;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
}
.funding-terms-header-title {
  margin: 0;
  font-family: SansSemibold;
  font-size: 20px;
  color: #4261ff;
  line-height: 30px;
}
.bank-account-input {
  padding: 24px 0 5px !important;
}
.bank-account-tab-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.bank-account-button {
  margin-top: 20px;
  width: 100%;
}
.bank-account-footer {
  flex-direction: column;
}
.onboarding legend {
  font-size: 20px;
}
.mask {
  margin: -20px;
  background-color: #4261ff;
  height: calc(100vh + 10px);
  margin-top: -70px;
}
.discover-more-page {
  margin: -20px;
  padding-top: 100px;
}
.discover-more-page .header {
  width: calc(100vw - 80px);
  font-family: SansSemibold;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  text-align: center;
  color: white;
  margin: 0 auto;
}
.discover-more-page .full-dashboard-bg {
  background-image: url('../../css/images/theme/Mobile-Fullscreen.png');
  background-position: 50%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 20px;
}
@media only screen and (min-device-height: 375px) and (max-device-width: 667px) {
  .contain {
    margin-top: 110px;
  }
}
@media only screen and (min-device-height: 700px) {
  .contain {
    margin-top: 10px;
  }
}
.contain .discover-more-text {
  font-family: OpenSans;
  font-size: 15px;
  color: #2c2c39;
  white-space: pre-line;
}
.contain .discover-more-text.dm-description {
  letter-spacing: 0.44px;
}
.contain .discover-more-text.dm-list-item {
  letter-spacing: 0.29px;
}
.contain .discover-list-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #eaecff;
}
.contain .discover-list-icon {
  height: 18px;
  margin: 18px 0;
}
.discover-more {
  padding: 38px 0;
  background: white;
  display: flex;
  font-family: 'OpenSans';
}
.discover-more .left {
  padding-top: 110px;
  width: 60%;
  margin-right: auto;
}
.discover-more .left .simple-affordable {
  color: #4261ff;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
}
.discover-more .left .a-digital-wealth {
  font-size: 15px;
  letter-spacing: 0.4px;
  margin-top: 10px;
  color: #2c2c39;
}
.discover-more .left .z-button {
  margin-top: 10px;
  background: white;
  display: flex;
  width: 160px;
  color: #4261ff;
}
.discover-more .left .z-button .text-contain {
  margin-left: -25px;
}
.discover-more .left .z-button .text-contain .icn--play {
  background-image: url('../../css/images/icons/control-play-alt.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position-y: 4px;
}
.discover-more .right .half-dashboard-bg {
  background-image: url('../../css/images/theme/Mobile-Halfscreen.png');
  width: 135px;
  height: 488px;
  background-size: contain;
  margin-right: -30px;
  background-repeat: no-repeat;
}
.test-class {
  color: green !important;
}
.layout--onboarding .header-main {
  background-color: #4261ff;
}
.available-goal {
  height: calc(100vh + 10px);
  color: white;
  position: relative;
}
.available-goal .more-detail {
  color: #26d4a5;
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding-right: 40px;
  font-size: 13px;
}
.available-goal .more-detail .icon-arrow-down {
  background-image: url('../../css//images/icons/icon-arrow-down-green.svg');
  background-position: center;
  margin: 0 auto;
  margin-top: 10px;
  background-size: cover;
}
.available-goal.mask {
  margin: 0 -20px;
  background-color: #4261ff;
  padding: 20px;
  padding-top: 72px;
  margin-bottom: 0px;
  margin-top: -70px;
}
.available-goal .list-action__link {
  width: 16px;
}
.available-goal .goal__name {
  color: white;
}
.available-goal a {
  color: #26d4a5;
  font-size: 15;
  text-align: center;
}
.available-goal .icon :before {
  background-position: 50%;
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.available-goal .icon--retirement {
  background-image: url('../../css/images/icons/goal--retirement-white.svg');
  background-size: contain;
}
.available-goal .icon--renovation {
  background-image: url('../../css/images/icons/goal--renovation-white.svg');
  background-size: contain;
}
.available-goal .icon--investment {
  background-image: url('../../css/images/icons/goal--investment-white.svg');
  background-size: contain;
}
.available-goal .icon--saving {
  background-image: url('../../css/images/icons/goal--saving-white.svg');
  background-size: contain;
}
.available-goal .icon--education {
  background-image: url('../../css/images/icons/goal--education-white.svg');
  background-size: contain;
}
.available-goal .icon--holiday {
  background-image: url('../../css/images/icons/goal--holiday-white.svg');
  background-size: contain;
}
.available-goal .icon--home {
  background-image: url('../../css/images/icons/goal--home-white.svg');
  background-size: contain;
}
.available-goal .icon--others {
  background-image: url('../../css/images/icons/goal--other-white.svg');
  background-size: contain;
}
.potential__title {
  font-family: SansSemibold;
  font-size: 20px;
  color: #4261ff;
  line-height: 30px;
}
.potential__description {
  margin: 10px 0;
  font-family: OpenSans;
  font-size: 15px;
  color: #2c2c39;
  letter-spacing: 0.44px;
}
.potential__sliders {
  padding-bottom: 30px;
  border-bottom: 1px solid #eaecff;
}
.investment-detail--description {
  margin-top: 20px;
  width: 102%;
  font-size: 20px;
  color: #2c2c39;
  line-height: 42px;
  white-space: pre-line;
}
.investment-detail-container {
  padding-top: 10px;
}
.investment-detail-container .text-title__large {
  margin: 0 !important;
}
.investment-detail--input {
  margin: 0 0 8px;
  padding: 0 8px;
  height: 44px;
  font-family: SansSemibold;
  border: none;
  border-bottom: 1px solid #eaecff;
  color: #4261ff;
}
.investment-detail--input::placeholder {
  color: #eaecff;
}
.investment-detail--input:focus {
  outline: none;
  border-bottom: 1px solid #a1b0fc;
}
.goal-input__name {
  width: 98%;
}
.goal-input__target-amount {
  width: 35%;
}
.goal-input__target-years {
  width: 12%;
  margin-bottom: 20px;
  text-align: center;
}
.goal-input__initial-amount {
  width: 26%;
}
.investment-detail--error-message {
  -webkit-animation: fadeIn 200ms linear forwards;
  animation: fadeIn 200ms linear forwards;
  opacity: 0;
  display: none;
  position: absolute;
  top: 107px;
  left: 5%;
  padding: 3px 3px;
  width: 91%;
  height: 104px;
  font-size: 15px;
  color: white;
  background-color: #4261ff;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px #4261ff;
}
.investment-detail--error-message .error-message-arrow {
  width: 0;
  top: 104px;
  left: 46%;
  position: inherit;
  border-style: solid;
  border-width: 13px;
  border-color: #4261ff transparent transparent transparent;
}
.investment-detail--error-message__title {
  padding: 0 20px;
  font-family: SansSemibold;
}
.investment-detail--error-message__content {
  padding: 0 20px;
  position: relative;
  bottom: 5px;
  line-height: 20px;
}
@-webkit-keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
.error-message-title {
  display: flex;
  justify-content: space-between;
}
.invalid-input {
  color: #dd0f43;
  border-bottom: 1px solid #dd0f43;
}
.invalid-input::placeholder {
  color: #dd0f4330;
}
.personal-detail__title {
  margin: 25px 0 !important;
  margin-top: 0 !important;
}
.personal-detail__input-field {
  margin-bottom: 24px;
}
.personal-detail__phone-input {
  margin-left: 20px;
}
.personal-detail__error-message {
  position: relative;
  bottom: 20px;
  margin: 0;
}
.personal-detail__error-message-phone {
  position: relative;
  top: 5px;
  margin: 0;
}
.personal-detail--phone-number-container {
  display: flex;
  position: relative;
}
.personal-detail-container {
  padding-top: 10px;
}
.obligatory {
  color: #dd0f43;
}
.total-balance-background {
  background: #00b961;
  width: 100px;
  height: 29px;
  border-radius: 5px;
  color: white;
  padding: 0 5px;
}
.onboarding .slick-slider {
  padding-bottom: 15px;
}
.onboarding .slick-dots li {
  vertical-align: top;
}
.onboarding .highcharts-label {
  position: absolute;
  top: 10px !important;
  left: 30px !important;
}
.onboarding .highcharts-legend-item > span {
  overflow: visible !important;
}
.onboarding .highcharts-legend-item-hidden {
  color: #333333;
  fill: none;
}
.onboarding .highcharts-legend-item-hidden .highcharts-legend-item-custom-mark {
  background-image: url("../../css/images/icons/square-default-line.svg");
}
.onboarding .highcharts-legend-item-custom {
  position: relative;
}
.onboarding .highcharts-legend-item-custom-hidden {
  color: transparent;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.onboarding .highcharts-legend-item-custom-inner {
  position: absolute;
  top: 7px;
  left: -56px;
}
.onboarding .highcharts-legend-item-custom-mark {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 7px;
  width: 24px;
  height: 24px;
  background-image: url("../../css/images/icons/check-square-line.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.onboarding .highcharts-legend-item-custom-label {
  display: inline-block;
  vertical-align: bottom;
}
.header-onboarding {
  background: #4261FF;
  color: white;
  display: inline-block;
  padding: 20px;
  font-family: SansSemibold;
  font-size: 15px;
}
.header-onboarding .left {
  float: left;
}
.header-onboarding .right {
  float: right;
}
.header-onboarding .right a {
  color: white;
  text-decoration: none;
}
.header-onboarding-right-black {
  margin-right: 20px;
}
.label-section {
  display: flex;
  justify-content: space-between;
}
.label-section label {
  font-family: OpenSans;
  font-size: 13px;
  line-height: 1.15;
  color: #a1b0fc;
}
.risk-profiling.description {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4261ff;
  white-space: pre-line;
  margin-bottom: 25px;
}
.stepper-container {
  padding-top: 10px;
  padding-bottom: 15px;
}
.stepper-container .stepper-bar {
  position: relative;
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background: #a1b0fc;
}
.stepper-container .stepper-bar .active-percent {
  background: #4261ff;
  height: 100%;
  border-radius: inherit;
  box-shadow: 1px 0px 3px 0px #4261ff;
}
.risk-score-container {
  font-family: OpenSans;
}
.risk-score-container .risk-score-chart {
  height: 180px;
  /* Fixed ticked CR4-406 */
  overflow: hidden;
  /* Fixed ticked CR4-406 */
}
.risk-score-container .risk-score-status {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  color: #fff;
  line-height: 1.5;
  font-family: "SansSemibold";
}
.risk-score-container .risk-score-cap {
  padding-top: 8px;
  text-align: center;
  font-size: 15px;
  white-space: pre-line;
  line-height: 1.3;
  color: #fff;
}
.content-main__footer .risk-score-footer-description {
  padding-top: 20px;
  font-size: 15px;
  color: #ffffff !important;
}
.content-main__footer .risk-score-footer-description .page-link {
  font-family: "SansSemibold";
  color: #26d4a5;
}
.goal-calculator-title {
  margin: 0 !important;
}
.goal-calculator-sliders-section {
  margin: 0 -20px;
  overflow: hidden;
  height: 133px;
  transition: height 0.7s;
  -webkit-transition: height 0.7s;
}
.goal-calculator-sliders-section__container {
  margin: 0 20px;
}
.goal-calculator-more {
  display: inline-flex;
  font-size: 13px;
  letter-spacing: 0.38px;
  color: #a1b0fc;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaecff;
}
.goal-calculator-more-icon {
  position: relative;
  top: 2px;
  margin-right: 9px;
}
.goal-calculator-projection-section__title {
  display: block;
}
.compliance-declaration-introduction {
  margin-top: 7px;
}
.compliance-declaration-agreement {
  display: inline-flex;
  margin: 8px 0 24px;
}
.compliance-declaration-agreement .input--check-box {
  background: transparent;
}
.compliance-declaration__text-title {
  font-family: SansSemibold !important;
  font-size: 20px !important;
  color: #4261ff !important;
  margin-bottom: 0px;
}
.risk-profile-container {
  font-family: OpenSans;
}
.risk-profile-container .risk-profile-title {
  position: relative;
  margin-bottom: -20px;
  text-align: center;
  z-index: 1030;
}
.risk-profile-container .risk-profile-title__name {
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.5px;
}
.risk-profile-container .risk-profile-title__status {
  font-family: SansSemibold;
  color: #dd0f43;
}
.risk-profile-container .risk-profile-chart {
  height: 180px;
  /* Fixed ticked CR4-406 */
  overflow: hidden;
  /* Fixed ticked CR4-406 */
}
.risk-profile-container .risk-profile-status {
  padding-top: 14px;
  text-align: center;
  font-family: "SansSemibold";
  font-size: 20px;
  line-height: 1.5;
  color: #4261ff;
}
.risk-profile-container .risk-profile-cap {
  padding: 14px 0 20px;
  text-align: center;
  font-size: 15px;
  white-space: pre-line;
  line-height: 1.3;
  letter-spacing: 0.25px;
}
.risk-profile-container .risk-profile-date {
  display: inline-flex;
  width: 100%;
  padding-top: 16px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eaecff;
}
.risk-profile-container .risk-profile-date__left {
  display: inline-flex;
  align-items: center;
}
.risk-profile-container .risk-profile-date__right {
  font-family: SansSemibold;
  color: #4261ff;
}
.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid #eaecff;
}
.notification-item__left {
  display: inline-flex;
  align-items: center;
}
.ant-switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 6px;
  width: 48px;
  height: 24px;
  border: none;
  border-radius: 25px;
  background-color: #9dabbf;
}
.ant-switch::after {
  content: "";
  display: block;
  position: relative;
  right: 5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 1px 4px 0 black;
}
.ant-switch-checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 48px;
  height: 24px;
  border: none;
  border-radius: 25px;
  background-color: #4261ff;
}
.ant-switch-checked::after {
  content: "";
  display: block;
  position: relative;
  left: 19px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 1px 4px 0 black;
}
.app-logo {
  width: 90px;
  margin: 0 auto 36px !important;
  display: block;
}
.forgot-password-link {
  margin-top: 10px;
  text-align: center;
  font-family: SansSemibold;
  letter-spacing: 0.44px;
}
.sign-up_text {
  margin-top: 21px;
  text-align: center;
  letter-spacing: 0.44px;
}
.sign-up_link {
  font-family: SansSemibold;
}
.login-footer {
  bottom: 0;
}
.new-password__title {
  margin-top: 24px !important;
}
.password-input-field {
  height: 28px;
}
.linked-bank-account__container {
  display: flex;
  padding-bottom: 5px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaecff;
}
.linked-bank-account__radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #4261ff;
  border-radius: 50%;
}
.linked-bank-account__radio:focus {
  outline: none;
}
.linked-bank-account__radio:checked {
  background-color: #4261ff;
}
.linked-bank-account__radio:checked::after {
  content: "";
  display: block;
  position: relative;
  top: 2px;
  left: 5px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}
.linked-bank-account__button-container {
  display: flex;
}
.linked-bank-account__add-button {
  padding-top: 1px;
  margin-right: 7%;
  width: 96px;
  height: 20px;
  font-size: 12px;
  color: white;
  text-align: center;
  background-color: #4261ff;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #4261ff;
}
.linked-bank-account__update-button {
  width: 96px;
  height: 20px;
  font-size: 12px;
  color: #4261ff;
  text-align: center;
  border: 1px solid #4261ff;
  border-radius: 5px;
}
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
@font-face {
  font-family: OpenSans;
  src: url('../fonts/opensans/OpenSans-Regular.ttf');
}
@font-face {
  font-family: SansSemibold;
  src: url('../fonts/opensans/OpenSans-Semibold.ttf');
}
