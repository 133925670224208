.list-view {
  .description {
    height: 115px;
    vertical-align: top;
    p {
      color: #2c2c39;
      font-family: OpenSans;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
    }
  }
}