html {
	height: 100%;
}

body {
	min-height: 100%;
	min-height: 100vh;
}

.viewport {
	overflow: hidden;
	width: 100%;
}

// ------------------------------------------------------------------------

// .content {
// 	background-color: #ffffff;
// }

// ------------------------------------------------------------------------

.viewport-inner {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
	padding-top: @header-height-mobile;

	.nav-in-left & {
		left: 0;
		transition: left @transition-speed ease-out;
	}

	.nav-in-left.nav-open & {
		left: @nav-mobile-width;
	}

	.nav-in-right & {
		right: 0;
		transition: right @transition-speed ease-out;
	}

	.nav-in-right.nav-open & {
		right: @nav-mobile-width;
	}

	@media screen and (min-width: @screen-tablet) {
		padding-top: @header-height;
	}
}

// ------------------------------------------------------------------------

.content-main {
	padding: 20px;
	display: flex;
	flex: 1 auto; // expand to available height
	height: 100%;
}

.content-main__inner {
	flex: 1 auto;
	display: flex;
	flex-direction: column;
	width: 100%;
}

	.content__header {
		padding: @grid-gutter-width/2 0;
		text-align: center;

		.title {
			font-weight: bold;
			font-size: 36px;
			margin: 0;
		}

		.text {
			font-weight: 600;
			margin: 0;
		}

		.text--recommendation {
			color: @brand-success;
			position: relative;
			padding-left: 18px;
			display: inline-block;
			&:before {
				content: "";
				background-image: url("../../images/icons/check--success.svg");
				width: 12px;
				height: 12px;
				display: block;
				position: absolute;
				left: 0;
				top: 5px;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
	}
.content-main__body--centered {
	display: flex;
	flex: 1 auto;
    flex-direction: column;
	justify-content: center;
}

.content-main__footer {
	margin-top: auto;
	bottom: 8px;
  position: relative;
}