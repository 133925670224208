.bank-account-input {
  padding: 24px 0 5px !important;
}
.bank-account-tab-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.bank-account-button {
  margin-top: 20px;
  width: 100%;
}
.bank-account-footer {
  flex-direction: column;
}