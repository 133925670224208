.layout--inverted {
	&,
	.header-main,
	.content-main {
		background-color: @brand-primary;
	}

	p,
	.content-lead,
	.section-title,
	.header-main,
	.content-main {
		color: @text-color-inverted;
	}

	.content-lead {
		line-height: normal;
		letter-spacing: 0.3px;
	}

	a {
		color: @link-color-inverted;

		&:active,
		&:hover {
			color: @link-hover-color-inverted;
		}
	}

	.content-main {
		align-items: stretch;
		display: flex;
		//padding-top: 0;

		.container {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	// ------------------------------------------------------------------------

	form {
		width: 100%;
		flex: 1 auto;

		p {
			color: @text-muted-inverted;
			width: 100%;
		}
	}
	.radio label,
	.checkbox label,
	.control-label,
	.form-control {
		color: @text-color-inverted;
	}

	.radio,
	.checkbox {
		label:before {
			border-color: @text-color-inverted;
		}

		input:checked + label:after {
			background-color: @text-color-inverted;
		}
	}

	.select {
		background-color: transparent;

		&:after {
			background-image: url("../../images/icons/select-arrow--inverted.svg");
		}
	}

	.input-validation-error {
		background-color: @brand-danger;
		color: @text-color-inverted;
	}

	.form-actions__item p {
		color: @text-muted-inverted;
	}

	// ------------------------------------------------------------------------
	
	.btn-default {
		background-color: transparent;
		border-color: @text-color-inverted;
		color: @text-color-inverted;
	}

	.btn-primary {
		background-color: @btn-primary-bgcolor-inverted;
		border-color: @btn-primary-border-color-inverted;
		color: @btn-primary-color-inverted;
	}	

	.toggle-icon g {
		fill: @text-color-inverted;
	}

	.btn-secondary {
		color: @brand-primary;
		border-color: @text-color-inverted;
		background-color: @text-color-inverted;
	}
}