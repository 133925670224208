.text-title {
  &__large {
    margin: 20px 0 !important;
    font-family: SansSemibold !important;
    font-size: 20px !important;
    color: #4261ff !important;
    line-height: 30px !important;
    white-space: pre-line !important;
  }
  &__small {
    margin: 0 !important;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
}
.text-label {
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #2c2c39;
  &__descriptions {
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.25px;
    color: #2c2c39;
    white-space: pre-line;
  }
  &__values {
    &__small-1 {
      font-family: SansSemibold;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #4261ff;
    }
    &__small-2 {
      font-family: SansSemibold;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: #4261ff;
    }
  }
  &__link {
    //font-family: SansSemibold;
    font-size: 15px;
    text-decoration: underline;
    color: #26d4a5 !important;
    letter-spacing: 0.44px;
  }
}
.text-error {
  &__small {
    font-size: 13px;
    color: #dd0f43;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
}
.text-one-line {
  white-space: nowrap !important;
}
