.country-residence-flag {
    margin-top: 2px;
    width: 23px;
    height: 15px;
    background-size: 100% 100% !important;
  
    &__container {
       height: 32px;
      display: flex;
      align-items: center;
      margin-left: 8px;
      width: 24px;
      margin-right:  16px;;
    }
  }

 
.dropdown-country-residence {
    width: 100%;
    padding-bottom: 6px;
    .phone-number-flag {
        border-radius: 4px;
    }
    .placeholder { 
        color: #a1b0fc;
        font-size: 15px;
        font-weight: normal;
        font-family: OpenSans, sans-serif

    }
    .dial-code{
        font-size: 15px;
        padding-left: 0 
    }
}
 