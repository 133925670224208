.linked-bank-account {
  &__container {
    display: flex;
    padding-bottom: 5px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaecff;
  }
  &__radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #4261ff;
    border-radius: 50%;
    &:focus {
      outline: none;
    }
    &:checked {
      background-color: #4261ff;
      &::after {
        content: "";
        display: block;
        position: relative;
        top: 2px;
        left: 5px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
      }
    }
  }
  &__button-container {
    display: flex;
  }
  &__add-button {
    padding-top: 1px;
    margin-right: 7%;
    width: 96px;
    height: 20px;
    font-size: 12px;
    color: white;
    text-align: center;
    background-color: #4261ff;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #4261ff;
  }
  &__update-button {
    width: 96px;
    height: 20px;
    font-size: 12px;
    color: #4261ff;
    text-align: center;
    border: 1px solid #4261ff;
    border-radius: 5px;
  }
}