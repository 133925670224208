// ********************************************************************************
// Select dropdown

.select {
	//position: relative;
	background-color: #FFFFFF;
	cursor: pointer;

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		border: none;
		border-radius: 0;
		background: transparent;
		cursor: pointer;
		margin-right: 0;
		height: 35px;
		padding: 0;
		width: 100%;

		&::-ms-expand {
			display: none;
		}
	}

	&:after {
		content: "";
		background-image: url("../../images/icons/select-arrow.svg");
		background-repeat: no-repeat;
		display: block;
		width: 16px;
		height: 9px;
		flex-shrink: 0;
		position: absolute;
		right: 9px;
		top: 18px;
		z-index: 2;
	}

	&:hover {
		&:after {
			color: @brand-primary;
		}
	}
}