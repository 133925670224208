// @font-face {
//     font-family: "Open Sans";
//     src: url("../fonts/open-sans/OpenSans-Light.woff2") format("woff2"),
//          url("../fonts/open-sans/OpenSans-Light.woff") format("woff");
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Open Sans";
//     src: url("../fonts/open-sans/OpenSans.woff2") format("woff2"),
//          url("fonts/open-sans/OpenSans.woff") format("woff");
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Open Sans";
//     src: url("../fonts/open-sans/OpenSans-Semibold.woff2") format("woff2"),
//          url("../fonts/open-sans/OpenSans-Semibold.woff") format("woff");
//     font-weight: 600;
//     font-style: normal;
// }


@font-face {
font-family: 'Glyphicons Halflings';
src: url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.eot');
src: url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

@font-face {
font-family: OpenSans;
src: url('../fonts/opensans/OpenSans-Regular.ttf')
}

@font-face {
font-family:  SansSemibold;
src: url('../fonts/opensans/OpenSans-Semibold.ttf')
}