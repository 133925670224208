/* Slider */
.slick-disabled {
    opacity: 0;
    pointer-events:none;
  }
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    &-progress-bar {
        .slick-arrow {
            pointer-events: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    .slick-list {
        width: calc(100vw - 40px);
    }
  }

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    padding-bottom: 1px;
    li {
        &:focus {
            outline: none !important;
        }
    }
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    //overflow: hidden;
	
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
    section {
        &:focus {
            outline: 0 !important;
        }
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li.slick-active button:before {
    background-color: #4261FF;
}
.slick-dots button {
    border: 0;
    background: transparent;
    display: block;
    height: 10px;
    width: 10px;
    outline: none;
    line-height: 0;
    font-size: 0;
    color: transparent;
    padding: 5px;
    cursor: pointer;
}
.slick-dots button:hover,
.slick-dots button:focus {
    outline: none;
}
.slick-dots button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-color: #d1d1d1;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
}

@import "slick-theme.less";