p, ul, ol, .panel {
	&:last-child {
		margin-bottom: 0;
	}
}


@spacing: @grid-gutter-width;
@spacing-xs: @spacing/4;
@spacing-sm: @spacing/2;
@spacing-md: @spacing;
@spacing-lg: @spacing*2;
@spacing-xl: @spacing*3;

// ** Margins ****************************************************************
// apply margin to elements for spacing

// ------------------------------------------------------------------------
// bottom
.m-b-r {
	margin-bottom: 0 !important;
}

.m-b-xs {
	margin-bottom: @spacing-xs !important;
}

.m-b-sm {
	margin-bottom: @spacing-sm !important;
}

.m-b-md {
	margin-bottom: @spacing-md !important;
}

.m-b-lg {
	margin-bottom: @spacing-lg !important;
}

.m-b-xl {
	margin-bottom: @spacing-xl !important;
}

// ------------------------------------------------------------------------
// top
.m-t-r {
	margin-top: 0 !important;
}

.m-t-xs {
	margin-top: @spacing-xs !important;
}

.m-t-sm {
	margin-top: @spacing-sm !important;
}

.m-t-md {
	margin-top: 18px!important;
}

.m-t-lg {
	margin-top: @spacing-lg !important;
}

.m-t-xl {
	margin-top: @spacing-xs !important;
}

// ------------------------------------------------------------------------
// right
.m-r-r {
	margin-right: 0 !important;
}

.m-r-xs {
	margin-right: @spacing-xs !important;
}

.m-r-sm {
	margin-right: @spacing-sm !important;
}

.m-r-md {
	margin-right: @spacing-md !important;
}

.m-r-lg {
	margin-right: @spacing-lg !important;
}

.m-r-xl {
	margin-right: @spacing-xl !important;
}

// ------------------------------------------------------------------------
// left
.m-l-r {
	margin-left: 0 !important;
}
.m-l-xs {
	margin-left: @spacing-xs !important;
}

.m-l-sm {
	margin-left: @spacing-sm !important;
}

.m-l-md {
	margin-left: @spacing-md !important;
}

.m-l-lg {
	margin-left: @spacing-lg !important;
}

.m-l-xl {
	margin-left: @spacing-xl !important;
}


// ** Paddings ****************************************************************
// apply padding to element for spacing

// ------------------------------------------------------------------------
// .p-t-r {
// 	padding-top: 0 !important;
// }

.p-t-xs {
	padding-top: @spacing-xs !important;
}

.p-t-sm {
	padding-top: @spacing-sm !important;
}

.p-t-md {
	padding-top: @spacing-md !important;
}

.p-t-lg {
	padding-top: @spacing-lg !important;
}

.p-t-xl {
	padding-top: @spacing-xl !important;
}

// ------------------------------------------------------------------------
.p-t-r {
	padding-top: 5px!important;
	padding-bottom: 0 !important;
}

.p-b-xs {
	padding-bottom: @spacing-xs !important;
}

.p-b-sm {
	padding-bottom: @spacing-sm !important;
}

.p-b-md {
	padding-bottom: @spacing-md !important;
}

.p-b-lg {
	padding-bottom: @spacing-lg !important;
}

.p-b-xl {
	padding-bottom: @spacing-xl !important;
}

// ------------------------------------------------------------------------
// left
.p-l-r {
	padding-left: 0 !important;
}

.p-l-xs {
	padding-left: @spacing-xs !important;
}

.p-l-sm {
	padding-left: @spacing-sm !important;
}

.p-l-md {
	padding-left: @spacing-md !important;
}

.p-l-lg {
	padding-left: @spacing-lg !important;
}

.p-l-lg-xl {
	padding-left: @spacing-xl !important;
}

// ------------------------------------------------------------------------
// right
.p-r-r {
	padding-right: 0 !important;
}

.p-lr-xs {
	padding-right: @spacing-xs !important;
}

.p-r-sm {
	padding-right: @spacing-sm !important;
}

.p-r-md {
	padding-right: @spacing-md !important;
}

.p-r-lg {
	padding-right: @spacing-lg !important;
}

.p-r-xl {
	padding-right:@spacing-xl !important;
}