.scroll-sticky {
	box-shadow: 0 0 5px 0 rgba(66,97,255,0);
	transition: box-shadow @transition-speed;
}

.is-sticky {
	box-shadow: 0 0 5px 0 rgba(66,97,255,0.10);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: @zindex-navbar-fixed;
}