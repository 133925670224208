.message-box-container {
  padding: 0 10px;
  .message-body {
    clear: both;
    position: relative;
    margin-top: 25px;
    color: #2c2c39;
    letter-spacing: 0.44px;
    font-family: OpenSans;
    float: left;
    width: 295px;
    height: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 6px 8px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    p {
      margin: 0;
      white-space: pre-line;
      padding: 5px;
    }
    &.answer {
      color: #2c2c39;
      border: 1px solid #ccc;
      background-color: #fff;
      // :after {
      //   content: " ";
      //   position: absolute;
      //   left: -15px;
      //   bottom: 9px;
      //   border-top: 15px solid transparent;
      //   border-right: 15px solid #ccc;
      //   border-left: none;
      //   border-bottom: 0px solid transparent;
      // }
    }

    &.question {
      margin-top: 25px;
      color: white;
      float: right;
      border: 1px solid #4261ff;
      background-color: #4261ff;
      border-radius: 5px;
      :after {
        content: " ";
        position: absolute;
        right: -15px;
        bottom: 9px;
        border-top: 15px solid transparent;
        border-right: none;
        border-left: 15px solid #4261ff;
        border-bottom: 0px solid transparent;
      }
    }
  }

  .status {
    width: calc(100vw - 60px);
    height: 15px;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.32px;
    color: #9dabbf;
    display: inline-block;
  }

  &.answer {
    .status {
      margin-top: 20px;
      text-align: center;
    }
  }

  &.question {
    .status {
      text-align: right;
      position: relative;
      right: 10px;
    }
  }
}

.message-box-arrow {
  position: absolute;
  border: 15px solid;
  border-width: 15px;
  border-right: none;
  border-color: transparent transparent #ccc;
  left: -16px;
  bottom: 27px;

  &::after {
    content: "";
    border: 10px solid;
    border-width: 13px;
    border-right: none;
    border-color: transparent transparent white transparent;
    position: absolute ;
    top: -12px;
    left: -12px;
  }
}

.message-text-footer {
  display: flex;
  position: fixed;
  width: 100%;
  height: 44px;
  bottom: 0;
  margin: 0 -20px;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #eaecff;
  border-left: none;
  border-right: none;
  background-color: white;

}

.message-text-input {
  width: 215px;
  height: 30px;
  margin: 6px;
  padding: 8px;
  border: 1px solid #eaecff;
  border-radius: 25px;
  &:focus {
    outline: none;
  }
}