.goal-calculator-title {
  margin: 0 !important;
}
.goal-calculator-sliders-section {
  margin: 0 -20px;
  overflow: hidden;
  height: 133px;
  transition: height 0.7s;
  -webkit-transition: height 0.7s;
  &__container {
    margin: 0 20px;
  }
}
.goal-calculator-more {
  display: inline-flex;
  font-size: 13px;
  letter-spacing: 0.38px;
  color: #a1b0fc;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaecff;
}
.goal-calculator-more-icon {
  position: relative;
  top: 2px;
  margin-right: 9px;
}
.goal-calculator-projection-section {
  &__title {
    display: block;
  }
}