.progressbar {
	margin: 0 -20px 0px;
	padding: 0 20px;
	position: relative;
	box-shadow: 0 3px 3px -2px rgba(66, 97, 255, 0.2);
  

	&::-webkit-scrollbar {
		display: none;
	}
}

.progressbar__wrapper {
	div {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		justify-content: space-between;
	}

	li {
		flex-shrink: 0;
		flex: 1;
		padding-bottom: 15px;
		position: relative;
		width: 33.333333%;

		&.complete {

			&:after {
				content: "";
				height: 1px;
				width: 100%;
				display: block;
				background-color: #4261FF;
				box-shadow: 0 0 5px 0 #4261FF;
				position: absolute;
				left: 0;
				bottom: 0px;
									  
			}

			a {
				font-size: 13px;
				flex-direction: column;
			}
			span {
				flex-direction: column;
			}
		}

		&.active {
			&:before {
				content: "";
				background-color: #4261FF;
				width: 14px;
				height: 14px;
				border: 3px solid #ffffff;
				position: absolute;
				left: 50%;
				bottom: 2px;
				transform: translate(-50%, 50%);
				z-index: 2;
				border-radius: 50%;			  
			}

			&:after {
				content: "";
				height: 1px;
				box-shadow: 0 0 5px 0 #4261FF;
				width: 50%;
				display: block;
				background-color: #4261FF;
				position: absolute;
				left: 0;
				bottom: 0;
			}

			span,
			a {
				color: @text-color;
				font-weight: 600;
			}
		}
	}

	a,
	li > span {
		position: relative;
		color: #B3C0FF;
		font-weight: 400;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		height: 36px;
	}

	.slick-prev,
	.slick-next {
		z-index: 10;
		top: 9px;
	}

	.slick-prev {
		left: -17px;
	}

	.slick-next {
		right: -17px;
	}

	.value {
		font-size: 15px;
		font-weight: 600;
		line-height: 1;
		color: @brand-primary;
	}

	.icon {
		display: inline-block;
		width: 12px;
		height: 12px;
		margin-top: 4px;

		&:after {
			content: "";
			display: block;
			width: 12px;
			height: 12px;
			background-image: url("../../images/icons/progressbar--complete.svg");
			background-repeat: no-repeat;
		}
	}
}