.personal-information {
  & .custom-dropdown {
    margin-bottom: 19px;
  }

  & .form-control {
    margin-bottom: 19px;
  }

  & .input-custom {
    height: 28px;
    font-size: 15px;
    border-bottom: none;
    padding: 0 8px;
  }

  .control-wrapper {
    margin-top: 0;

  }

  & .control-label {
    margin: 0;
  }
}