.goal-selector {
	min-height: 200px;
	margin-top: 20px;
	margin-right: -20px;
	margin-left: -20px;
}


.goal {
	padding: 0 20px;

	input {
		&:extend(.sr-only);
	}

	label {
		display: block;
		cursor: pointer;
	}
	&--title{
	width: 335px;
	height: 30%;
	float: none;
	margin: 0 auto;
	font-family: OpenSans;
	font-size: 24px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: 0.4px;
	color: #4261ff;
	
	}
}

.goal__icon {
	display: block;
	margin: 0 auto;
	width: 64px;
	height: 64px;
	margin-bottom: 20px;

	img {
		width: 100%;
	}
}

.goal__name {
	display: flex;
	justify-content: center;  
	font-family: OpenSans,sans-serif;
	font-size: 15px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #4261ff;

}

.goal__description {
	display: block;
	font-size: 15px;
}

.goal-selector--cockpit {
	&.goal-selector {
		min-height: 0;
		display: flex;
		flex-wrap: wrap;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}

	.goal {
		width: 50%;
		text-align: center;
		margin-bottom: 32px;
	}

	.goal__icon {
		width: 24px;
		height: 24px;
		margin-bottom: 10px;
	}

	// .goal__name {
	// 	font-size: 15px;
	// 	margin-bottom: 0;
	// }
}


// goal icons for listing

.goal--investment:before {
	background-image: url("../../images/icons/goal--investment.svg");
}

.goal--holiday:before {
	background-image: url("../../images/icons/goal--holiday.svg");
}

.goal--home:before {
	background-image: url("../../images/icons/goal--home.svg");
}

.goal--education:before {
	background-image: url("../../images/icons/goal--education.svg");
}

.goal--renovation:before {
	background-image: url("../../images/icons/goal--renovation.svg");
}

.goal--children:before {
	background-image: url("../../images/icons/goal--children.svg");
}

.goal--retirement:before {
	background-image: url("../../images/icons/goal--retirement.svg");
}

.goal--others:before {
	background-image: url("../../images/icons/goal--others.svg");
}

.goal--event:before {
	background-image: url("../../images/icons/goal--event.svg");
}

.goal--present:before {
	background-image: url("../../images/icons/goal--present.svg");
}

.icn--calculator:before{
  background-image: url('../../../css/images/icons/calculator.svg')
}


// .goal--saving {
// 	&:before {
// 		background-image: url("../../images/icons/goal--saving.svg");
// 	}
// }



// span:before {
// 	background-image: url("../../images/icons/listing--messaging--action-required.svg");
// }
// messaging

.icn--action-required:before {
	background-image: url("../../images/icons/listing--messaging--action-required.svg");
}

.icn--support:before {
	background-image: url("../../images/icons/listing--messaging--support.svg");
}

.icn--portfolio-info:before {
	background-image: url("../../images/icons/listing--messaging--support.svg");
}

.icn--document:before {
	background-image: url("../../images/icons/listing--messaging--new-document.svg");
}

.icn--history:before {
	background-image: url("../../images/icons/listing--messaging--history.svg");
}

.icn--recommendation:before {
	background-image: url("../../images/icons/listing--messaging--recommendation.svg");
}


.icn--edit--goal--topup {
	white-space: nowrap;
	&:before {
		//background-color: red;
	}
}
.icn--edit--goal--topup:before {
	background-image: url("../../images/icons/edit--goal--topup.svg");
}
.icn--edit--goal--withdraw {
	white-space: nowrap;
}
.icn--edit--goal--withdraw:before {
	background-image: url("../../images/icons/edit--goal--withdraw.svg");
}

.icn--dashboard-title {
margin: 2px 0 0 2px;
width: 100px;
height: 50px;
background-size: contain;
background-repeat: no-repeat;
background-position: 50%;
}

.icn--completion:before{
background-image: url('../../images/icons/arrow-glyph-up-right-alt.svg');
}
.icn--status:before{
background-image: url('../../images/icons/screen-desktop.svg')
}
.icn {
	&--expected-amount, &--strategy {
		white-space: nowrap;
	}
}
.icn--expected-amount:before{
background-image: url('../../images/icons/diamond.svg')
}

.icn--target-amount:before{
background-image: url('../../images/icons/listing--target.svg')
}
.icn--details:before{
background-image: url('../../images/icons/details.svg')
}

.goal--holiday:before {
background-image: url("../../images/icons/goal--holiday.svg");
}

.icn {
	white-space: nowrap;
}