.onboarding {
  legend {
    font-size: 20px;
    //padding: 0 50px
  }
}

.mask {
  margin: -20px;
  background-color: #4261ff;
  height: calc(100vh + 10px);
  margin-top: -70px;  
}

.discover-more-page {
  margin: -20px;
  padding-top: 100px;
  .header {
    width: calc(100vw - 80px);
    font-family: SansSemibold;
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: 0.4px;
    text-align: center;
    color: white;
    margin: 0 auto;
  }
  .full-dashboard-bg {
    background-image: url('../../../css/images/theme/Mobile-Fullscreen.png');
    background-position: 50%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 20px;
  }
}

@media only screen 
  and (min-device-height: 375px) 
  and (max-device-width: 667px) {
  .contain {
    margin-top: 110px;
  }
}


@media only screen 
  and (min-device-height: 700px)  {
  .contain {
    margin-top: 10px;
  }
}

.contain {
  .discover-more-text {
    font-family: OpenSans;
    font-size: 15px;
    color: #2c2c39;
    white-space: pre-line;
    &.dm-description {
      letter-spacing: 0.44px;
    }
    &.dm-list-item {
      letter-spacing: 0.29px;
    }
  }
  .discover-list-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #eaecff;
  }
  .discover-list-icon {
    height: 18px;
    margin: 18px 0;
  }
}
