.pop-up {
  &__container {
    width: 100%;
  }
  &__message {
    white-space: pre-line;
  }
  &__footer {
    padding-bottom: 20px; 
  }
}