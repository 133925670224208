.layout--onboarding {
  .header-main {
    background-color: #4261ff;
  }
}

.available-goal {
  height: calc(100vh + 10px);
  color: white;
  position: relative;
  .more-detail {
    color: #26d4a5;

    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding-right: 40px;
    font-size: 13px;
    .icon-arrow-down {
      background-image: url('../../../css//images/icons/icon-arrow-down-green.svg');
      background-position: center;
      margin: 0 auto;
      margin-top: 10px;
      background-size: cover
    }
  }
  &.mask {
    margin: 0 -20px;
    background-color: #4261ff;
    padding: 20px;
    padding-top: 72px;
    margin-bottom: 0px;
    margin-top: -70px;
  }
  .list-action__link {
    width: 16px;
  }
  .goal__name {
    color: white;
  }
  a {
    color: #26d4a5;
    font-size: 15;
    text-align: center;
  }
  .icon  {
    :before {
      background-position: 50%;
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    &--retirement {
      background-image: url('../../../css/images/icons/goal--retirement-white.svg');
      background-size: contain;
    }
    &--renovation {
      background-image: url('../../../css/images/icons/goal--renovation-white.svg');
      background-size: contain;
    }
    &--investment {
      background-image: url('../../../css/images/icons/goal--investment-white.svg');
      background-size: contain;
    }
    &--saving {
      background-image: url('../../../css/images/icons/goal--saving-white.svg');
      background-size: contain;
    }
    &--education {
      background-image: url('../../../css/images/icons/goal--education-white.svg');
      background-size: contain;
    }
    &--holiday {
      background-image: url('../../../css/images/icons/goal--holiday-white.svg');
      background-size: contain;
    }
    &--home {
      background-image: url('../../../css/images/icons/goal--home-white.svg');
      background-size: contain;
    }
    &--others {
      background-image: url('../../../css/images/icons/goal--other-white.svg');
      background-size: contain;
    }

  }

}