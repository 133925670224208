// Single side border-radius

.border-top-radius(@radius) {
  border-top-right-radius: @radius;
   border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
     border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
   border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
     border-top-left-radius: @radius;
}


.border-top {
  border-top: 1px solid #eaecff;
}

.border-bottom {
  border-bottom: 1px solid #eaecff;
}

#borderless{
  tr {
    border-bottom: 0 !important;
    border-top: 0;
    background-color: red;
  }
  border-bottom: 0;
  border-top: 0;
  &-bottom{
    border-bottom: 0px
  }
  &-top{
    border-top: 0px;
  }
}