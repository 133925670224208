.account-details {
  &-item {
    position: relative;
    display: block;
    border-top: 1px solid #eaecff;
    padding: 15px 0;
    color: #2c2c39;

    &-icon {
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
    }

    &-text {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }

    &-goto-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}