.pop-up-box {
  visibility: hidden;
  -webkit-animation: fadeInOut 3s linear forwards;
  animation: fadeInOut 3s linear forwards;
  opacity: 0;
  display: inline-block;
  position: relative;
  top: 15px;
  padding: 3px 3px;
  background-color: #4261ff;
  box-shadow: 0px 0px 4px 0px #4261ff;
  &:after {
    content: '';
    top: 27px;
    right: 24px;
    position: relative;
    border-style: solid;
    border-width: 4px;
    border-color: #4261ff transparent transparent transparent;
  }
}
@-webkit-keyframes fadeInOut {
  5%, 95% {opacity: 1;}
  100% {visibility: hidden}
}
@keyframes fadeInOut {
  5%, 95% {opacity: 1;}
  100% {visibility: hidden}
}
.pop-up-text {
  position: relative;
  bottom: 1px;
  left: 4px;
  color: white;
}
.inverted-input {
  color: #4261FF;
  text-align: center;
  width: 100%;
  border: none;
  border-bottom: 1px solid #eaecff;
  font-family: OpenSans;
  font-size: 24px;
  height: 55px;
  outline-width: 0;
}
.referral-text {
  letter-spacing: 0.4px;
}
.text-link-normal {
  font-family: OpenSans;
  letter-spacing: 0.4px;
  color: #26d4a5;
}
.generic-page-title {
  margin-bottom: 9px !important;
  //padding-bottom: 8px;
  white-space: pre-line;
  font-family: SansSemibold; 
  font-size: 20px; 
  line-height: 1.5;
}
.generic-page-footer {
  &__text {
    margin-top: 20px;
    //color: #ffffff !important;
    color: #a1b0fc !important;
    letter-spacing: 0.44px;
  }
  &__padding {
    margin-top: 40%;
  }
}
.generic-page-icon {
  &__container {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 13%;
  }
  &__loading {
    position: relative;
    color: #26d4a5 !important;
  }
  &__text {
    font-size: 15px;
    position: absolute;
    top: 40%;
  }
}
.generic-page-email-input {
  &__container {
    margin: 50px 0 30px;
  }
}