.support-title-text {
  font-size: 13px;
  color: #2c2c39;
  line-height: 18px;
  letter-spacing: 0.5px;
}
.support-title-input {
  border: none;
  padding: 4px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaecff;
  font-family: SansSemibold;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #4261ff;
  resize: none;
  overflow: hidden;
  &:focus {
    outline: none;
  }
}
.support-message {
  margin-top: 2px;
  height: 100%;
  border: none;
  font-family: OpenSans;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #2c2c39;
  resize: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-family: OpenSans;
    font-style: italic;
    font-size: 13px;
    color: #a1b0fc;
  }
}
.btn-send:focus {
  outline: none;
}