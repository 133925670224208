.range-slider-input-hidden-text {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 40px;
  max-width: 100%;
  font-size: 24px;
  display: inline-block;
  word-break: break-word;
  visibility: hidden;
  white-space: pre-wrap;
  padding: 0 8px;
}
.range-slider {

  &__thumb {
    background-color: white !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 12px;
    box-shadow: 0 0 5px 0.5px rgba(66, 97, 255, 0.5);
  }

  &__activated {
    background-color: #4261ff !important;
    box-shadow: none;
  }
  &__track {
    color: #4261ff;
    &.before {
      background-color: #4261ff;
      height: 6px;
    }
    &.after {
      background-color: #a1b0fc;
    }
  }
}

.input-slider--right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input-slider--title {
  &__container {
    display: flex;
    padding: 15px 0;
    align-items: center;
    justify-content: space-between;
  }
  &__text {
    font-size: 13px;
    color: #2c2c39;
    letter-spacing: 0.5px;
  }
  &__currency, &__date {
    font-family: OpenSans;
    color: #a1b0fc;
    margin: 5px 5px 0 10px;
  }
  &__currency {
    font-size: 15px;
  }
  &__date {
    font-size: 15px;
  }
  &__input {
    font-family: SansSemibold;
    font-size: 24px;
    height: 50px;
    max-width: 100%;
    color: #4261ff;
    text-align: center;
    border: none;
    border-bottom: 1px solid #a1b0fc;
    padding: 0 8px;

    &:focus {
      outline: none;
    }
  }
}
.input-slider--range-value {
  display: flex;
  margin-top: 20px;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #a1b0fc;
  justify-content: space-between;
}
