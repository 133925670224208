.messages-item {
  position: relative;
  display: block;
  text-decoration: none;
  padding: 14px 20px 16px 30px;
  border-bottom: 1px solid #eaecff;

  &:last-child {
    border-bottom: 0;
  }

  &.active {
    // display: inline-block;
    left: 50px;
    padding-left: 50px;
    width: calc(100% - 50px);
  }

  &.active & {
    &__archive {
      display: block;
    }

    &__icon {
      left: 20px;
    }

    &__go-icon {
      display: none;
    }
  }

  &__archive {
    display: none;
    position: absolute;
    top: 0;
    height: 100%;
    right: 100%;
    min-width: 70px;
    padding-top: 16px;
    text-align: center;
    background: #4261ff;

    &-icon {
      width: 24px;
      height: 24px;
    }

    &-text {
      color: #ffffff;
    }
  }

  &__icon {
    position: absolute;
    top: 26px;
    left: 0;
    width: 18px;
    height: 18px;
  }

  &__time {
    font-size: 13px;
    color: #a1b0fc;
  }

  &__text {
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.25px;
    color: #2c2c39;
  }

  &__go-icon {
    position: absolute;
    top: 30px;
    right: 0;
  }
}