.label-section {
  display: flex;
  justify-content: space-between;
  label {
    font-family: OpenSans;
    font-size: 13px;
    line-height: 1.15;
    color: #a1b0fc;
  }
}

.risk-profiling {
  &.description {
    font-family: OpenSans;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4261ff;
    white-space: pre-line;
    margin-bottom: 25px;
  }  
}
