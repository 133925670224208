.gauge-label-left-date {
	position: absolute ;
	left:50%;
	transform: translateX(-50%);
	margin-left: -86px;
	margin-top: -32px;
	color: #2c2c39;
	font-family: OpenSans;
	font-size: 12px
  }
  .gauge-label-left {
	position: absolute ;
	left:50%;
	transform: translateX(-50%);
	margin-left: -86px;
	margin-top: -18px;
	color: #2c2c39;
	font-family: SansSemiBold;
	font-size: 12px
  }
  .gauge-label-right{
	position: absolute ;
	left:50%;
	transform: translateX(-50%);
	margin-left: 88px;
	margin-top: -18px;
	color: #2c2c39;
	font-family: SansSemiBold;
	font-size: 12px
  }
  .gauge-label-right-date{
	position: absolute ;
	left:50%;
	transform: translateX(-50%);
	margin-left: 88px;
	margin-top: -33px;
	color: #2c2c39;
	font-family: OpenSans;
	font-size: 12px
  }