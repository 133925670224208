.potential {
  &-contain {
    
  }
  &__title {
    font-family: SansSemibold;
    font-size: 20px;
    color: #4261ff;
    line-height: 30px;
  }
  &__description {
    margin: 10px 0;
    font-family: OpenSans;
    font-size: 15px;
    color: #2c2c39;
    letter-spacing: 0.44px;
  }
  &__sliders {
    padding-bottom: 30px;
    border-bottom: 1px solid #eaecff;
  }
}