@pinkish-red: #dd0f43;
@lightish-blue: #4261ff;
@pastel-blue: #a1b0fc;
@input-border: #eaecff;
@white: #ffffff;

.custom-dropdown {
  position: relative;
  letter-spacing: 0.5px;

  &__label {
    display: inline-block;
    position: relative;
    margin-bottom: 2px;
    font-size: 13px;
    line-height: 1.38;

    &.required {
      margin-right: 17px;

      &::after {
        content: "*";
        height: 6px;
        display: block;
        position: absolute;
        left: 100%;
        top: 0;
        color: @pinkish-red;
      }
    }
  }

  &__tooltip-icon {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    background: url('../../../css/images/icons/tool-tip-question-mark.svg');
    background-size: 100% 100%;
  }

  &__input {
    position: relative;
    
    &-header {
      font-family: SansSemibold;
      padding-bottom: 6px;
      padding-left: 8px;
      font-size: 15px;
      line-height: normal;
      color: @lightish-blue;
      border-bottom: 1px solid @input-border;

      &.fog {
        font-family: OpenSans;
        color: @pastel-blue;
        font-weight: normal;
      }

      &::after {
        content: " ";
        position: absolute;
        top: -6px;
        right: 0;
        width: 16px;
        height: 16px;
        background: url('../../../css/images/icons/angle-bold-down.svg');
        background-size: 100% 100%;
      }
    }

    &-body {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: 0;
      width: 100%;
      max-height: 184px;
      padding: 0 8px;
      overflow: scroll;
      background: @white;
      border-radius: 6px;
      box-shadow: 0 0 5px 1px rgba(66, 97, 255, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      -webkit-transition: max-height .1s ease-in-out;
      -moz-transition: max-height .1s ease-in-out;
      -ms-transition: max-height .1s ease-in-out;
      -o-transition: max-height .1s ease-in-out;
      transition: max-height .1s ease-in-out;
      z-index: 10;
    }

    &-option {
      position: relative;
      padding: 8px 0;
      font-size: 15px;
      line-height: 1.87;
      border-bottom: 1px solid @input-border;

      &.active {
        color: @lightish-blue;
      }

      &__icon {
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        top: calc(50% - 8px);
        right: 0;
        background: url('../../../css/images/icons/check-glyph-alt.svg');
        background-size: 100% 100%;
      }
    }

    &.open & {
      &-body {
        visibility: visible;
        opacity: 1;
        z-index: 10;
      }
    }

    &.open.top & {
      &-body {
        bottom: 100%;
      }
    }
    
    &.open.bottom & {
      &-body {
        top: 100%;
      }
    }
  }
}