.error-message {
    // word-break: break-all;
    font-family: OpenSans;
    font-size: 13px;
    color: red;
    &-bottom {
        border-bottom: 1px solid red;
        border-radius: 0;
    }
}

// TODO
.amount-changed {
    font-size: 13px;
  }
  