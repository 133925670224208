.otp-title {
  font-family: SansSemibold;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: normal;
}
.otp-description {
  font-family: OpenSans;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.3px;
  white-space: pre-line;
}
.otp-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  padding-top: 5px;
}
.otp-code {
  border: 0;
  outline-width: 0;
  width: 40px;
  height: 55px;
  font-family: OpenSans;
  font-size: 24px;
  text-align: center;
  border-bottom: 1px solid #7a91ff;
  background-color: #4261ff;
  &:disabled {
    opacity: 1;
    -webkit-text-fill-color: white;
  }
  &:focus {
    border-bottom: 2px solid white;
  }
}
.otp-timer {
  font-family: OpenSans;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: white;
  padding-top: 10px;
}
.otp-button {
  margin: 30px auto 0;
  width: 335px;
  height: 45px;
  font-family: SansSemibold;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #26d4a5;
  &:focus {
    outline: none;
  }
}
.otp-footer {
  padding-top: 40px;
}
.otp-footer-description {
  //width: 335px;
  height: 100px;
  margin: 0 auto;
  font-family: OpenSans;
  font-size: 15px;
  line-height: normal;
  text-align: left;
  letter-spacing: 0.4px;
  color: white;
}
.otp-icon {
  margin: 0 auto 25px;
  width: 64px;
  height: 64px;
}
.otp-bottom-space {
  margin: 80px auto 0;
}
.code-num-highlight {
  border-bottom: 2px solid white;
}
