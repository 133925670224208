
@question-icon-color: #2c2c39;
@common-text-color: #4261ff;
@border-color: #eaecff;
@small-title-color: #2c2c39;
@error-color: #dd0f43;
@place-holder: #a1b0fc;
@light-blue: #eaecff;
@gray: #666666;
@white: #ffffff;

.mydatepicker {
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    font-family: OpenSans;
    color: @place-holder;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-family: OpenSans;
    color: @place-holder;
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-family: OpenSans;
    color: @place-holder;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: OpenSans;
    color: @place-holder;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: OpenSans;
    color: @place-holder;
  }

  ::placeholder { /* Most modern browsers support this now. */
    font-family: OpenSans;
    color: @place-holder;
  }
  
  &__container {
    position: relative;
  }

  &__button {
    position: absolute;
    top: -5px;
    right: 0;
    width: 16px;
    height: 16px;
    display: block;
    &-icon {
      width: 100%;
      height: 100%;
      display: block;
      background: url("../../../css/images/calender-alt.svg") no-repeat;
      background-size: 16px 16px;
    }
  }
  &__calendar {
    display: none;

    &-popup {
      left: 0 !important;
      top: 28px !important;
    }
  }

  .ant-calendar {
    width: 100%;

    a {
      text-decoration: none;
    }
  }

  .ant-calendar-header {
    border-bottom-color: @border-color;
    .ant-calendar-prev-year-btn {
      left: 0;
      &::after {
        content: "";
        background: url("../../../css/images/angle-default-left.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        left: 23px;
      }
      &::before {
        content: "";
        background: url("../../../css/images/angle-default-left.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        left: 18px;
      }
    }
    .ant-calendar-prev-month-btn {
      &::after {
        content: "";
        background: url("../../../css/images/angle-default-left.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        left: 18px;
      }
    }
    .ant-calendar-next-month-btn {
      &::after {
        content: "";
        background: url("../../../css/images/angle-default-right.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        right: 18px;
      }
    }

    .ant-calendar-next-year-btn {
      right: 0;
      &::after {
        content: "";
        background: url("../../../css/images/angle-default-right.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        right: 18px;
      }

      &::before {
        content: "";
        background: url("../../../css/images/angle-default-right.svg") no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        display: inline-block;
        color: @common-text-color;
        position: absolute;
        top: 12px;
        right: 23px;
      }
    }
  }

  .ant-calendar-input-wrap {
    display: none;
  }

  .ant-calendar-tbody {
    font-family: OpenSans;
    color: @place-holder;
    .ant-calendar-date {
      font-size: 14px;
      width: 29px;
      height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    .ant-calendar-selected-day {
      .ant-calendar-date {
        color: @white;
        background: @common-text-color;
      }
    }
    .ant-calendar-today {
      &.ant-calendar-selected-date {
        .ant-calendar-date {
          background: @common-text-color;
          color: @white;
          font-weight: normal;
        }
      }
      .ant-calendar-date {
        border: none;
        background: @light-blue;
        color: @gray;
      }
    }
  }

  .ant-calendar-footer {
    border-top-color: @border-color;
    .ant-calendar-footer-btn {
      .ant-calendar-today-btn {
        font-family: SansSemibold;
        font-size: 14px;
      }
    }
  }
}