// tab navigation

.tablist {
	margin: 0 -20px 0px;
	padding: 0 20px;
	position: relative;
	box-shadow: 0 3px 3px -2px rgba(66,97,255,.2);  
	span {
		color: #b3c0ff;
		padding: 15px 0 10px;
		display: block;
		border-bottom: 3px solid #fff;
		text-decoration: none;
		text-align: center;
	}
	&::-webkit-scrollbar {
		display: none;
	}
	
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {

		&.selected a {
			position: relative;
			color: #000000;
			font-weight: 600;
			text-align: center;

			&:after {
				content: "";
				height: 3px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: @brand-primary;
				display: block;
				box-shadow: 0 0 5px 0 rgba(66,97,255,0.50);
			}
		}

		+ li {
			margin-left: 20px;
		}
	}

	a {
		color: #B3C0FF;
		//font-size: 15px;
		font-weight: 400;
		padding: 15px 0 10px;
		display: block;
		border-bottom: 3px solid #ffffff;
		text-decoration: none;
		text-align: center;
	}

	.slick-prev,
	.slick-next {
		z-index: 10;
		top: 17px;
	}

	.slick-prev {
		left: -17px;
	}

	.slick-next {
		right: -17px;
	}
}

.tablist__btn {
	position: absolute;
	top: 0;
	height: 100%;
	z-index: 2;
	background-color: transparent;
	border: none;
	padding: 0;
	margin: 0;
	width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:before {
		content: "";
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		width: 7px;
		height: 12px;
		display: block;
	}
}

.tablist__btn--prev {
	left: 0;

	&:before {
		background-image: url("../../images/icons/tab-nav-left.svg");
	}
}

.tablist__btn--next {
	right: 0;

	&:before {
		background-image: url("../../images/icons/tab-nav-right.svg");
	}
}