.success-modal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 18px;

  .modal-dialog {
    width: 100%;
    margin: 15px;

    .modal-header {
      text-align: left;
      font-size: 15px;
      padding: 15px 15px 10px 15px;

      span {
        margin-top: 5px;
      }
    }

    .modal-body {
      text-align: left;
      font-size: 15px;
      color: #4261ff;
      padding: 0px 15px 20px 15px;
    }
  }
}