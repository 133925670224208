.financial-situation {
  position: relative;
  // min-height: calc((var(--vh, 1vh) * 100) - @top-space);

  &-dropdown {
    margin-bottom: 21px;
  }

  & .custom-dropdown__label {
    padding-right: 5px;
  }
}