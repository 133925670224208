.app-logo {
  width: 90px;
  margin: 0 auto 36px !important;
  display: block;
}
.forgot-password-link {
  margin-top: 10px;
  text-align: center;
  font-family: SansSemibold;
  letter-spacing: 0.44px;
}
.sign-up {
  &_text {
    margin-top: 21px;
    text-align: center;
    letter-spacing: 0.44px;
  }
  &_link {
    font-family: SansSemibold;
  }
}
.login-footer {
  bottom: 0;
}
.new-password {
  &__title {
    margin-top: 24px !important;
  }
}
.password-input-field {
  height: 28px;
}