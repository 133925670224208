.icn--generic-item {
  margin-top: 18px;
}
.icn--generic-item::before {
  //background-image: url("../images/icons/message-warning.svg");
  top: 0;
  padding-top: 10px;
}
.icn--generic-item-more {
  margin: 13px 0 13px auto;
  width: 24px;
}
.generic-item {
  font-family: OpenSans;
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #eaecff;
  font-size: 15px;
  z-index: 10;
}
.generic-item-left {
  white-space: nowrap;
}

.generic-info-right {
  color: #4261ff;
  font-weight: 600;
  height: 100%;
}
.generic-subtext {
  font-size: 13px;
  color: #a1b0fc;
}
.generic-notification {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #2c2c39;
}
.generic-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.generic-right-text {
  font-family: SansSemibold; 
  color: #4261ff;
}
.borderless {
  border: none;
}