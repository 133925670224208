
.dropdown-item {
  width: 100%;
  border: none;
  padding: 5px 0px;
  line-height: 1;
  height: 34px;
  background: url('../../../css/images/icons//angle-bold-down.svg') no-repeat right;
  color: #4261FF;
  background-size: 16px;
  -webkit-appearance: none;
  background-position-y: -5px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  &:focus {
    outline:0px;
  }
  option {
    background-color: none;
  }
}
.phone-number-drop-down {
  display: flex;
  align-items: center;
  // padding: 0 10% 0 0;
  padding-right: 10%;
  width: 33%;
  -webkit-appearance: none;
  font-family: SansSemibold;
  font-size: 20px;
  color: #a1b0fc;;
  background: url('../../../css/images/icons/angle-bold-down.svg') no-repeat right;
  background-size: 16px;
  border: none;
  border-bottom: 1px solid #eaecff;
  &:focus {
    outline: none;
  }
  .dial-code {
    padding-left: 10px;
    color: #4261ff;
  }
}
.phone-number-flag {
  margin-top: 2px;
  width: 23px;
  height: 15px;
  background-size: 100% 100% !important;

  &__container {
    width: 38px;
    height: 32px;
    display: flex;
    align-items: center;
  }
}

#country-code {
  display: flex;
  align-items: center;
  .modal-container {
    width: 351px;
    height: 412px;
    overflow: scroll;
    border-radius: 6px;
    box-shadow: 0 0 5px 1px rgba(66, 97, 255, 0.2);
    background-color: white;
    margin: 0 auto;

    .modal-content-main {
      padding-left: 8px;
      padding-right: 8px;

      .search-header {
        width: 100%;
        margin-top: 6px;
        .search-group {
          display: flex;
          align-items: center;
          position: relative;
          .input-search {
            padding-left: 48px;
            width: 100%;
            color: #2c2c39;
            font-size: 15px;
            font-family: OpenSans;
            &::placeholder {
              font-family: OpenSans;
              font-size: 15px;
              color: #a1b0fc;
            }
          }
          .search-content {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            position: absolute;
            .icon-search {
              display: block;
              width: 16px;
              height: 16px;
              text-align: center;
              color: #4261ff;
            }
          }

        }
      }

      .country-body {
        display: block;
        max-height: 360px;
        overflow: scroll;
        width: 100%;
        .country-row {
          border-bottom: 1px solid #eaecff;
          &:last-child {
            border-bottom: none;
          }
        }
        .country-row-no-result-message {
          padding-left: 48px;
          height: 28px;
          padding-top: 8px;
          color: #2c2c39;
        }
      }
    }
  }
}

.border-error__bottom {
  border-bottom: 1px solid #dd0f43;
}


.dropdown-container {
  position: absolute;
  top: 100%;
  width: 351px;
  max-height: 187px;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(66, 97, 255, 0.2);
  left: -8px;
  overflow: auto;
  background: #fff;
  .dropdown-body {
    padding: 8px;
    .country-row {
      border-bottom: 1px solid #eaecff;
      &:last-child {
        border-bottom: none;
      }
      
    }
  }
}
.dropdown-container {
  .country-body {
    .country-row {
      border-bottom: 1px solid #eaecff;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
  

.line {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  .flag-content {
    width: 40px;
    display: flex;
    justify-content: center;
    .flag {
      display: block;
      height: 15.5px;
      width: 24px;
      font-family: OpenSans;
      font-size: 15px;
      background-size: 100% 100% !important;
    }
  }
  .country-name {
    font-family: OpenSans;
    font-size: 15px;
    display: flex;
    height: 100%;
    width: 80%;
    align-items: center;
    justify-content: left;
    padding-left: 10px;
    line-height: 1.87;
    &.border-error__bottom {
      color: #2c2c39;
    }
  }
  .none-active-name {
    color: #2c2c39;
  }

  .active-name {
    color: #4261ff;
  }

  .icon-content {
    height: 100%;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      display: block;
      width: 16px;
      height: 16px;
    }
    .check-sticker {
      background: url("../../../css/images/icons/check-glyph-alt.svg") no-repeat;
      background-size: 16px 16px;
    }
  }
}

.ch-flag {
  background: url("../../../css/images/flags/Switzerland.svg") no-repeat;
}
.vietnam-flag {
  background: url("../../../css/images/flags/Vietnam.svg") no-repeat;
}
.singapore-flag {
  background: url("../../../css/images/flags/Singapore.svg") no-repeat;
}

.id-flag {
  background: url("../../../css/images/flags/ID.svg") no-repeat;
}

.at-flag {
  background: url("../../../css/images/flags/AT.svg") no-repeat;
}

.au-flag {
  background: url("../../../css/images/flags/AU.svg") no-repeat;
}

.my-flag {
  background: url("../../../css/images/flags/MY.svg") no-repeat;
}

.th-flag {
  background: url("../../../css/images/flags/TH.svg") no-repeat;
}

.de-flag {
  background: url("../../../css/images/flags/DE.svg") no-repeat;
}

.jp-flag {
  background: url("../../../css/images/flags/JP.svg") no-repeat;
}

.ph-flag {
  background: url("../../../css/images/flags/PH.svg") no-repeat;
}