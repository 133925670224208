.feedback {
  &-footer {
    text-align: center;
  }

  &-item {
    margin-bottom: 16px;
  }

  &__title {
    font-size: 20px;
    font-family: SansSemibold;
  }

  &__desc {
    padding: 0 5px;
  }

  & .btn.btn-default {
    color: white !important;
    background-color: #26D4A5 !important;
    border-color: #26D4A5 !important;
    outline: none !important;
  }
}