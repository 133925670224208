.btn {
  box-shadow: 0 0 5px 0 rgba(66, 97, 255, 0.5);
  font-size: 16px;
  padding: 10px 32px 10px;
  &-with-draw {
    padding-top: 1px;
    width: 96px;
    height: 20px;
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background: #4261ff;
    border-radius: 5px;
    box-shadow: 0 0 5px 0.5px rgba(66, 97, 255, 0.5);
  }
  &-no-focus {
    &__primary {
      &:active,
      &:focus,
      &:hover {
        background-color: #4261ff !important;
        border-color: #4261ff !important;
        outline: none !important;
      }
      &:focus {
        outline: none;
      }
      &__inverted {
        &:active,
        &:focus,
        &:hover {
          color: white !important;
          background-color: #26D4A5 !important;
          border-color: #26D4A5 !important;
          outline: none !important;
        }
        &:focus {
          outline: none;
        }
      }
    }
    &__inverted {
      &:active,
      &:hover {
        color: white !important;
      }
      &:active {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid white !important;
        background-color: #4261ff !important;
      }
    }
    &__white {
      &:active,
      &:focus,
      &:hover {
        background-color: white !important;
        outline: none;
        border-color: #4261ff;
      }
    }
    &__disabled {
      &:active,
      &:focus,
      &:hover {
        color: white;
      }
      &:disabled {
        opacity: 1;
        background-color: #a1b0fc;
        color: white;
      }
    }
  }
}

.btn-default {
  box-shadow: none;
}

// ------------------------------------------------------------------------------------------------------------
// button group

.button-group {
  display: flex;
  justify-content: space-between;
  //padding-top: @grid-gutter-width;

  .btn:only-child {
    display: block;
    width: 100%;
  }
}

// ------------------------------------------------------------------------------------------------------------
// Header Toggles

.toggle-header {
  margin: 0;
  padding: 0 @grid-gutter-width / 2;
  background-color: transparent;
  border: none;
  height: @header-toggle-height;
  //width: @header-toggle-width;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.toggle-header--notification {
  position: relative;
}

.notification-indicator {
  position: absolute;
  top: 4px;
  left: 42%;
  color: #fff;
  font-size: 9px;
  text-align: center;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background-color: #4261ff;
  border-radius: 50%;
}

// ------------------------------------------------------------------------------------------------------------

.toggle-icon {
  display: flex;
  align-items: center;
}

// ------------------------------------------------------------------------------------------------------------

.link--more {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eaecff;
  height: 50px;
  font-weight: 600;

  .table-listing + & {
    margin-top: -20px;
  }
}

// ------------------------------------------------------------------------------------------------------------

.link-arrow {
  position: relative;
  padding-left: 15px;

  &:before {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../images/icons/link-arrow.svg");
    content: "";
    width: 10px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.link-form {
  font-size: 15px;
  display: block;
  margin-top: 10px;
}

.btn-cancel {
  width: 50%;
  margin-right: 10px;
  position: relative;
  top: 69%;
}
.btn-review {
  width: 50%;
  position: relative;
  top: 69%;
}
