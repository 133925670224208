.leave-suggestion {
  & .input-custom {
    height: 28px;
    font-size: 15px;
    border-bottom: none;
    padding: 0 8px;
  }

  &-textarea {
    width: 100%;
  }

  &--desc {
    p {
      margin-bottom: 20px;
    }
  }
}