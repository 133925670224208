@pinkish-red: #dd0f43;
@input-border: #eaecff;
@lightish-blue: #4261ff;

// it include the header height, step line height, and padding of the parent element.
// This is the sumary of the space before the current element(personal finance)
@top-space: 123px; // magic number

.personal-finance {
  position: relative;
  // min-height: calc((var(--vh, 1vh) * 100) - @top-space);

  &-dropdown {
    margin-bottom: 20px;
  }

  &-form-control {
    margin-bottom: 30px;
    padding: 2px 0;
    border-bottom: 1px solid @input-border;

    &__label {
      display: inline-block;
      position: relative;
      font-size: 14px;
      line-height: 1.38;

      &.required {
        margin-right: 17px;

        &::after {
          content: "*";
          height: 6px;
          display: block;
          position: absolute;
          left: 100%;
          top: 0;
          color: @pinkish-red;
        }
      }
    }

    &__tooltip-icon {
      display: inline-block;
      vertical-align: middle;
      width: 12px;
      height: 12px;
      background: url('../../../css/images/icons/tool-tip-question-mark.svg');
      background-size: 100% 100%;
    }

    & .ant-radio-checked .ant-radio-inner {
      border-color: @lightish-blue;
    }

    & .ant-radio-inner {
      border-color: @lightish-blue;

      &::after {
        background: @lightish-blue;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}