//#onboarding-goal-planning {
  .total-balance-background {
    background: #00b961;
    width: 100px;
    height: 29px;
    border-radius: 5px;
    color: white;
    padding: 0 5px;
  }
//}

.onboarding {
  & .slick-slider {
    padding-bottom: 15px;
  }

  & .slick-dots {
    & li {
      vertical-align: top;
    }
  }

  & .highcharts-label {
    position: absolute;
    top: 10px !important;
    left: 30px !important;
  }

  & .highcharts-legend-item {
    & > span {
      overflow: visible !important;
    }

    &-hidden {
      color: rgb(51, 51, 51);
      fill: none;
    }

    &-hidden {
      & .highcharts-legend-item-custom-mark {
        background-image: url("../../../css/images/icons/square-default-line.svg");
      }
    }
    &-custom {
      position: relative;
    
      &-hidden {
        color: transparent;
        pointer-events: none;
        -webkit-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 
      }

      &-inner {
        position: absolute;
        top: 7px;
        left: -56px;
      }

      &-mark {
        display: inline-block;
        vertical-align: bottom;
        margin-right: 7px;
        width: 24px;
        height: 24px;
        background-image: url("../../../css/images/icons/check-square-line.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        opacity: .8;
      }

      &-label {
        display: inline-block;
        vertical-align: bottom;
      }
    }
  }
}