@border-color: #eaecff;
@text-color: #243041;
@pastel-blue: #a1b0fc;

.visit_branch {
  position: relative;
  min-height: calc(100vh - 140px);
  padding-bottom: 20px;

  font-family: OpenSans;

  &__question {
    display: block;
    position: relative;
    padding: 15px 0;
    padding-left: 28px;
    border-top: 1px solid @border-color;
    color: @text-color;
    text-decoration: none;

    &:visited, &:hover, &:active {
      text-decoration: none;
      color: @text-color;
    }

    &:last-child {
      border-bottom: 1px solid @border-color;
    }

    &::after {
      content: ' ';
      position: absolute;
      top: calc(50% - 5px);
      right: 0;
      width: 10px;
      height: 10px;
      background-image: url('../../../css/images/icons/angle-bold-down.svg');
      background-size: 100% 100%;
      transform: rotate(-90deg);
    }

    &.download & {
      &--icon {
        background-image: url('../../../css/images/icons/arrow-glyph-down.svg');
      }
    }

    &.direction-multiple & {
      &--icon {
        background-image: url('../../../css/images/icons/direction-multiple.svg')
      }
    }

    &--icon {
      position: absolute;
      top: calc(50% - 9px);
      left: 0;
      width: 18px;
      height: 18px;
      background-size: 100% 100%;
    }
  }

  &__disclaimer {
    padding: 10px 0;
    color: @pastel-blue;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}