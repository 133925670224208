// Default Variables

@slick-font-path: "./fonts/";
@slick-font-family: "slick";
@slick-loader-path: "./";
@slick-arrow-color: white;
@slick-dot-color: #d1d1d1;
@slick-dot-color-active: @brand-primary;
@slick-prev-character: "←";
@slick-next-character: "→";
@slick-dot-character: "";
@slick-dot-size: 16px;
@slick-opacity-default: 0.75;
@slick-opacity-on-hover: 1;
@slick-opacity-not-active: 0.25;

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 16px;
    width: 16px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: @grid-gutter-width/2;
    padding: 0;
    border: none;
	outline: none;
	
    &:hover, &:focus {
        outline: none;
        background: transparent;
		color: transparent;
	}
}

.slick-prev,
.slick-next {
	
	&:before {
		content: "";
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		width: 7px;
		height: 16px;
		display: block;
	}
}

.slick-prev {
	left: 20px;
	
    [dir="rtl"] & {
        left: auto;
        right: 20px;
	}
	
    &:before {
		background-image: url("../../../images/icons/chevron-left.svg");
		
        [dir="rtl"] & {
			background-image: url("../../../images/icons/chevron-right.svg");
        }
    }
}

.slick-next {
	right: 20px;
	
    [dir="rtl"] & {
        left: 20px;
        right: auto;
	}
	
    &:before {
		background-image: url("../../../images/icons/chevron-right.svg");

        [dir="rtl"] & {
			background-image: url("../../../images/icons/chevron-left.svg");
        }
    }
}

/* Dots */

.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 0;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
	width: 100%;
	
    li {
        // position: relative;
        // display: inline-block;
        // height: 10px;
        // width: 10px;
        // margin: 0 5px;
        // padding: 0;
		// cursor: pointer;

        &.slick-active button:before {
            background-color: @slick-dot-color-active;
        }
    }
		
	button {
		// border: 0;
		// background: transparent;
		// display: block;
		// height:10px;
		// width: 10px;
		// outline: none;
		// line-height: 0px;
		// font-size: 0px;
		// color: transparent;
		// padding: 5px;
		// cursor: pointer;
		
		&:hover, &:focus {
			outline: none;
		}
		
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			content: @slick-dot-character;
			background-color: @slick-dot-color;
			// width: @slick-dot-size;
			// height: @slick-dot-size;
			display: block;
			border-radius: 50%;
		}
	}
}
