// ------------------------------------------------------------------
// invest summary

.invest-summary {
	text-align: center;
	padding: 0 0 20px 0;
	line-height: 1;
	position: relative;

	.amount-value {
		font-size: 36px;
		margin-bottom: 10px;
		position: relative;
	}

	.currency {
		font-size: 13px;
		position: absolute;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: .2px;
		font-family: SansSemiBold;
		-webkit-transform: translate(-117%);
		transform: translate(-117%);
		top: 4px;
	}

	.amount-change {
		font-size: 15px;
	}
}

.invest-summary__icon {
	margin-bottom: 10px;
	display: block;

	.icn {
		width: 24px;
		height: 24px;
	}
}



// ------------------------------------------------------------------

.amount-positive {
	color: @brand-success;
}

.amount-negative {
	color: @brand-danger;
}

.amount-change {
	color: #25C177;
	font-family: SansSemibold;
	margin-bottom: 0;
	font-weight: 600;
	padding-left: 14px;
	position: relative;
	display: inline-block;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(0, -50%);
		width: 8px;
		height: 8px;
		background-image: url("../../images/icons/amount-increase.svg");
		background-repeat: no-repeat;
	}
}

.amount-change--decrease {
	color: @brand-danger;

	&:before {
		background-image: url("../../images/icons/amount-decrease.svg");
	}
}

// ------------------------------------------------------------------

.line-chart {
	margin-left: -20px;
	margin-right: -20px;
}

// ------------------------------------------------------------------
// dot
// colored circles for positions and chart legends
.dot {
	display: inline-block;
	height: 12px;
	width: 12px;
	border-radius: 50%;

	table & {
		position: relative;
		top: 1px;
	}
}

// ------------------------------------------------------------------

.status-bar {
	display: block;
	height: 10px;
	border-radius: 5px;
}

// ------------------------------------------------------------------

.tag {
	background-color: @brand-primary;
	font-weight: 400;
	height: 25px;
	color: #ffffff;
	padding-right: 10px;
	padding-left: 10px;
	border-radius: 13px;
}

.tag--arrow {
	&:after {
		content: "";
		background-position: center center;
		background-repeat: no-repeat;
		background-image: url("../../images/icons/tag-arrow-right.svg");
		width: 10px;
		height: 10px;
		display: inline-block;
		margin-left: 3px;
	}

	&.tag--arrow--up:after {
		background-image: url("../../images/icons/tag-arrow-up.svg");
	}

	&.tag--arrow--down:after {
		background-image: url("../../images/icons/tag-arrow-down.svg");
	}

	&.tag--arrow--right:after {
		background-image: url("../../images/icons/tag-arrow-right.svg");
	}
}

.tag--positive {
	background-color: #25C177;
}

.tag--neutral {
	background-color: @brand-primary;
}

.tag--negative {
	background-color: #FF376B;
}

// ------------------------------------------------------------------

.warning {
	display: inline-block;
	width: 12px;
	height: 11px;
	background-image: url('../../images/icons/warning.svg');
	background-repeat: no-repeat;
	background-size: 12px 11px;
}

// ------------------------------------------------------------------

// .tooltip-toggle {
// 	cursor: pointer;
// 	display: inline-block;
// 	width: 12px;
// 	height: 12px;
// 	border-radius: 50%;
// 	background-color: #4261FF;
// 	color: #ffffff;
// 	position: relative;
// 	top: 1px;
// 	left: 4px;
// 	z-index: 2;
// 	&:before {
// 		content: "?";
// 		position: absolute;
// 		font-size: 8px;
// 		display: block;
// 		width: 100%;
// 		text-align: center;	  
// 	}
// }
.tooltip-toggle {
	cursor: pointer;
	display: inline-block;
	position: relative;
	top: 1px;
	margin-left: 5px;
	width: 12px;
	height: 12px;
	background: url("../../images/icons/tool-tip-question-mark.svg") no-repeat;
	background-size: contain;
	z-index: 2;
}

// ------------------------------------------------------------------

.referal {
	margin-top: 20px;
	margin-right: -20px;
	margin-left: -20px;
	margin-bottom: -20px;
	padding: 20px;
	text-align: center;

	.btn {
		margin-top: 15px;
	}
}

.disclaimer {
    font-family: OpenSans;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 18px;
    letter-spacing: .2px;
    color: #a1b0fc;
}