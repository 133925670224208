.list-view {
	width: 100%;
	//table-layout: fixed;
	&-row {
		display: block;
		
		&.title {
			color: #000;
			font-size: 13px;
			font-weight: 400;
			font-family: OpenSans;
		}
		&.amount {
			color: #a1b0fc;
			font-size: 13px;
			font-weight: 400;
			font-family: OpenSans;
		}
	}
	&-title {	
		font-size: 13px;
	}

	&:not(:last-child) {
		margin-bottom: 10px;
	}

	ul&, ol& {
		margin-left: 0;
		padding-left: 0;
		list-style-type: none;
	}

	tr, li {
		border-top: 1px solid @line-color;
		border-bottom: 1px solid #EAECFF;
	}

	// td, th, li {
	// 	border-top: 1px solid @line-color;
	// 	border-bottom: 1px solid #EAECFF;
	// }

	th, td {
		padding: 15px 0;
		//word-break: break-word;
		// border-top: 1px solid #eaecff;
	}

	th {
		font-weight: 400;
		// border-top: 1px solid #eaecff;
		//position: absolute;
		width: 50%;
	}

	td {
		// color: @brand-primary;
		// font-weight: 600;
		color: #4261FF;
		font-family: SansSemibold;

	}

	li {
		border-bottom: 1px solid #EAECFF;
		a {
			color: @text-color;
			position: relative;
			display: block;
			padding: 15px 16px 15px 0;
			text-decoration: none;

			&:after {
				content: "";
				background-image: url("../../images/icons/arrow-list.svg");
				background-position: right center;
				background-repeat: no-repeat;
				width: 6px;
				height: 10px;
				display: block;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translate(0, -50%);
			}

			.icn {
				margin-top: 1px;
			}
		}
	}
}

.text-byline {
	display: block;
	color: @byline-color;
	font-size: 13px;
	font-weight: normal;
	line-height: normal;

	td& {
		display: table-cell;
		border-top: none;
		padding-top: 0;
		font-family: OpenSans;
		font-size: 13px;
		color: #A1B0FC;	  
	}
}

.list-view__item--has-value {
	display: flex;
}

.list-view__item__value {
	margin-left: auto;
	font-weight: 600;
	color: @brand-primary;
}


// ------------------------------------------------------------------------------------------------------------
// list-action (inside .list-view)

.list-action {
	display: inline-flex;
	align-items: center;
}

.list-action__text {
	display: inline-block;
	white-space: nowrap;
}

.list-action__link {
	background-color: initial;
    border: none;
    background-repeat: no-repeat;
    height: 40px;
    padding: 0;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 0 0 10px;
}

.list-action__link--more {
	background-image: url("../../images/icons/arrow-list.svg");
	background-position: right center;
}

.list-action__link--expand {
	background-image: url("../../images/icons/triangle-right.svg");
	background-repeat: no-repeat;
	background-position:  right center;

	&.open {
		position: relative;
		top: 5px;
		background-image: url("../../images/icons/triangle-down.svg");
		background-repeat: no-repeat;
		background-position:  bottom;	  
	}
}