.onboarding-input-field {
  &__full {
    &__large {
      height: 46px;
      width: 100%;
      font-family: SansSemibold;
      font-size: 24px;
      color: #4261ff;
      border: none;
      border-bottom: 1px solid #eaecff;
      &:focus {
        outline: none;
        border-bottom: 1px solid #a1b0fc;
      }
    }
  }
}
.cockpit-input-field {
  &__full {
    &__small {
      width: 100%;
      font-family: SansSemibold;
      font-size: 15px;
      letter-spacing: 0.5px;
      color: #9dabbf;
      border-bottom: 1px solid #eaecff;
      &:focus {
        outline: none;
      }
      &__inverted {
        font-family: OpenSans;
        font-size: 15px;
        letter-spacing: 0.25px;
        box-shadow: none;
        &::placeholder {
          color: white;
        }
      }
      &__no-border {
        width: 100%;
        border: none;
        &:focus {
          outline: none;
          border-bottom: 1px solid #a1b0fc;
        }
      }
    }
  }
}
.input-wrapper {
  display: flex;
  padding-bottom: 8px;
  margin-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid #7a91ff;
}