.input--check-box {
  -webkit-appearance: none;
  margin: 2px 10px 0 0;
  width: 18px;
  height: 18px;
  border: 1px solid #4261FF;
  background-color: transparent;
  &:after {
    display: none;
    content: "";
    position: relative;
    top: 2px;
    left: 5px;
    width: 5px;
    height: 9px;
    border: solid #4261FF;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:checked {
    &:after {
      display: block;
    }
  }
  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
}