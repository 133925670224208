.support-item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 15px 16px 17px 0;
  border-bottom: 1px solid #eaecff;
  text-decoration: none;
  font-family: SansSemibold;
  color: #4261ff;

  &:last-child {
    border-bottom: 0;
  }

  &__go-icon {
    position: absolute;
    top: calc(50% + 2px);
    right: 0;
    transform: translateY(-50%);
  }
}