.support-send-mail {
  height: 100%;
  
  &__label {
    font-size: 13px;
    color: #2c2c39;
    line-height: 18px;
    letter-spacing: 0.5px;
  }

  &__body {
    height: 100%;
  }

  &__input {
    margin-bottom: 20px;
    padding: 4px 0; 
    width: 100%;
    overflow: hidden;
    resize: none;
    outline: none;
    border: none;
    border-bottom: 1px solid #eaecff;
    font-family: SansSemibold;
    font-size: 15px;
    letter-spacing: 0.5px;
    color: #4261ff;

    &:focus {
      outline: none;
    }
  }

  &__textarea {
    margin-top: 2px;
    height: 100%;
    width: 100%;
    border: none;
    font-family: OpenSans;
    font-size: 15px;
    letter-spacing: 0.3px;
    color: #2c2c39;
    resize: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-family: OpenSans;
      font-style: italic;
      font-size: 13px;
      color: #a1b0fc;
    }
  }
}