.expandable-view-row {
  border-top: 1px solid #eaecff;
  & div {
    width: 100%;
    margin-top: 2px;
    padding: 16px 0 16px 0;
    font-family: OpenSans;
    font-size: 15px;
    color: #2c2c39;
    letter-spacing: 0.29px;
  }
}
.expandable-view-title {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.expandable-view-description {
  margin-bottom: 23px !important;
  font-family: OpenSans;
  font-size: 15px;
  color: #2c2c39;
  letter-spacing: 0.25px;
  white-space: pre-line;
}
.row-padding {
  &__none {
    padding: 0 !important;
  }
  &__compliance-declaration {
    padding: 0 !important;
    height: 53px;
  }
}