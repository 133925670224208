.with-draw {
  .account {
    border-top: 1px solid #eaecff;
    border-bottom: 1px solid #eaecff;
    margin-bottom: 30px;
    padding: 10px 0;
  }
  .goal-name {
    font-family: OpenSans;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: #2c2c39;
  }
  option {
    letter-spacing: 0.5px;
    color: #2c2c39;
  }
}