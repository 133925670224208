.feedback {
  &-footer {
    text-align: center;

    &.content-main__footer {
      bottom: 0;
    }
  }

  &-item {
    margin-bottom: 16px;
  }

  &__desc {
    padding: 0 5px;
  }

  & .btn.btn-default {
    color: white !important;
    background-color: #26D4A5 !important;
    border-color: #26D4A5 !important;
    outline: none !important;
  }
}