.online-verification {
	.title {
		color: #4261ff;
		font-size: 20px;
		font-family: 'OpenSans', sans-serif;
		font-weight: 600;
		margin-bottom: 20px;
	}
	
	.label {
		font-size: 13px;
	}
	
	.countryValue {
		color: #4261ff;
		font-size: 15px;
		margin-top: 4px;
		font-family: 'OpenSans', sans-serif;
		font-weight: 600;
	}
	
	.please {
		color: #a1b0fc;
		font-size: 13px;
		margin-top: 20px;
		margin-bottom: 15px;
	}
	
	.guideStep {
		color: #2c2c39;
		font-size: 15px;
	}
}


.step {
	border-top: 1px solid #eaecff;
	padding: 14px 0;
	position: relative;
	padding-left: 24px;

	b {
		color: #4261ff;
		font-family: 'OpenSans', sans-serif;
		font-weight: 600;
		margin-right: .5em;
		position: absolute;
		left: 0;
		top: 14px;
	}
}

.btnBack {
	position: fixed;
	left: 24px;
	bottom: 16px;
	width: 52px;
	height: 52px;
	background: 50% no-repeat;
	background-size: contain;
	opacity: .7;
}

.btnNext {
	position: fixed;
	right: 24px;
	bottom: 16px;
	width: 52px;
	height: 52px;
	background: 50% no-repeat;
	background-size: contain;
	opacity: .7;
}

.bgBlack {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: #000;
}

.simulateApp {
	background: no-repeat 50%;
	background-size: auto 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 90px;
}

// COPY JSX to here as Quan expected
.header-main{
  background:none
}

