.risk-score-container {
  font-family: OpenSans;

  .risk-score-chart {
    // height: 220px; /* the old value */
    height: 180px; /* Fixed ticked CR4-406 */
    overflow: hidden; /* Fixed ticked CR4-406 */
  }

  .risk-score-status {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    color: #fff;
    line-height: 1.5;
    font-family: "SansSemibold"
  }

  .risk-score-cap {
    padding-top: 8px;
    text-align: center;
    font-size: 15px;
    white-space: pre-line;
    line-height: 1.3;
    color: #fff;
  }
}

.content-main__footer {
  .risk-score-footer-description {
    padding-top: 20px;
    font-size: 15px;
    color: #ffffff !important;
    .page-link {
      font-family: "SansSemibold";
      color: #26d4a5;
    }
  }
}