.transaction-history-search {
  width: 75vw;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #4261FF;
  border: none;
  &:focus {
    outline-width: 0;
  }
  &::placeholder {
    color: #a1b0fc;
    font-weight: normal;
  }
}

.no-transaction {
height: 20px;
font-family: OpenSans;
font-size: 15px;
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: 0.3px;
text-align: center;
margin-top: 50px;
}