.search-form {
  position: relative;

  &__input {
    width: 100%;
    font-size: 15px;
    padding: 17px 26px;
    border: none;
    border-bottom: 1px solid #EAECFF;
    font-family: SansSemibold;
    color: #4261ff;

    &:focus {
      outline: none;
    }
  }

  &__search-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background: url('../../../css/images/icons-png/magnifier-default.png');
    background-size: 100% 100%;
  }

  &__close-icon {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: 0 !important;
    transform: translateY(-50%);
  }
}