.personal-detail {
  &__title {
    margin: 25px 0 !important;
    margin-top: 0 !important;
  }
  &__input-field {
    margin-bottom: 24px;
  }
  &__phone-input {
    margin-left: 20px;
  }
  &__error-message {
    position: relative;
    bottom: 20px;
    margin: 0;
  }
  &__error-message-phone {
    position: relative;
    top: 5px;
    margin: 0;
  }
  &--phone-number-container {
    display: flex;
    position: relative;
  }
  &-container {
    padding-top: 10px;
  }
}
.obligatory {
  color: #dd0f43;
}