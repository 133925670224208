.table-listing {
	// vertical-align: middle;
	// padding-top: 15px;
	// padding-bottom: 15px;
	// font-weight: 600;
	// color: #4261ff;  
	width: 100%;

	&:last-child {
		margin-bottom: -1px;
	}

	td, th {
		vertical-align: middle;
		padding-top: 15px;
		padding-bottom: 15px;
		//height: 50px;
	}

	.th,
	th {
		text-align: left;
		font-weight: 400;
		color: @text-color;
	}

	td {
		color: @brand-primary;
		font-weight: 600;
	}

	.text-small {
		display: block;
		font-weight: normal;
		color: #B3C0FF;
		font-size: 11px;
	}

	tr {
		padding: 15px !important;
		border-top: 1px solid #EAECFF;
		// & + tr {
		// 	th, td {
		// 		border-top: 1px solid #EAECFF;
		// 	}
		// }
	}
}

.table-listing--status-bar {
	th {
		width: 70px;
		padding-right: 10px;
	}
}


th.bold {
	font-family: SansSemibold;
}
// ------------------------------------------------------------------
// table nested inside table

.table-nested {
	margin-bottom: 0;
	font-size: 13px;

	tr:first-child th,
	tr:first-child td {
		border-top: none;
	}

	td, th {
		padding: 15px 0;
	}
}

// ------------------------------------------------------------------
// toggable row, contains .table-nested

tr.row-expandable {
	> td {
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 25px;
	}
}

.row-title {
	padding: 5px 0;
	border-top: 1px solid #EAECFF;
	border-bottom: 1px solid #EAECFF;
	font-size: 20px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.8;
	letter-spacing: normal;
	color: #4261ff;
}
// ------------------------------------------------------------------
// cell modifiers

td.cell-icn {
	width: 28px;
	padding-right: 10px;
	vertical-align: middle;
}

td.cell-percentage {
	width: 55px;
	font-family: SansSemiBold;
	font-size: 15px;
}

td.cell-amount {
	width: 110px;
	text-align: right;
}

td.cell-tag {
	width: 70px;
	text-align: right;
}

td.cell-default {
	padding-left: 10px;
	padding-right: 10px;
	width: 150px;
	&:first-child {
		padding-left: 0;
	}
}

td.cell-dot {
	padding-right: 5px;
	padding-left: 5px;
	width: 22px;
}

td.cell-more {
	padding-top: 0;
	padding-bottom: 0;
	width: @grid-gutter-width/2 + 6px;
}

td.cell-expand {
	padding-top: 0;
	padding-bottom: 0;
	width: 20px;
}

td.cell-truncate {
	position: relative;
	
	&:before {
		content: '&nbsp;';
		visibility: hidden;
	}

	> span {
		position: absolute;
		left: 0;
		right: 15px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-left: 10px;
	}
}

td.cell-status-bar {
	padding: 0;
}