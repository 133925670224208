.investment-detail {
  &--description {
    margin-top: 20px;
    width: 102%;
    font-size: 20px;
    color: #2c2c39;
    line-height: 42px;
    white-space: pre-line;
  }
  &-container {
    padding-top: 10px;
    .text-title__large {
      margin: 0 !important;
    }
  }
}
.investment-detail--input {
  margin: 0 0 8px;
  padding: 0 8px;
  height: 44px;
  font-family: SansSemibold;
  border: none;
  border-bottom: 1px solid #eaecff;
  color: #4261ff;
  &::placeholder {
    color: #eaecff;
  }
  &:focus {
    outline: none;
    border-bottom: 1px solid #a1b0fc;
  }
}
.goal-input {
  &__name {
    width: 98%;
  }
  &__target-amount {
    width: 35%;
  }
  &__target-years {
    width: 12%;
    margin-bottom: 20px;
    text-align: center;
  }
  &__initial-amount {
    width: 26%;
  }
}
.investment-detail--error-message {
  -webkit-animation: fadeIn 200ms linear forwards;
  animation: fadeIn 200ms linear forwards;
  opacity: 0;
  display: none;
  position: absolute;
  top: 107px;
  left: 5%;
  padding: 3px 3px;
  width: 91%;
  height: 104px;
  font-size: 15px;
  color: white;
  background-color: #4261ff;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px #4261ff;
  & .error-message-arrow {
    width: 0;
    top: 104px;
    left: 46%;
    position: inherit;
    border-style: solid;
    border-width: 13px;
    border-color: #4261ff transparent transparent transparent;
  }
  &__title {
    padding: 0 20px;
    font-family: SansSemibold;
  }
  &__content {
    padding: 0 20px;
    position: relative;
    bottom: 5px;
    line-height: 20px;
  }
}
@-webkit-keyframes fadeIn {
  100% {opacity: 1;}
}
@keyframes fadeIn {
  100% {opacity: 1;}
}
.error-message-title {
  display: flex;
  justify-content: space-between;
}
.invalid-input {
  color: #dd0f43;
  border-bottom: 1px solid #dd0f43;
  &::placeholder {
    color: #dd0f4330;
  }
}
