@icn-size: 18px;

.icn {
  display: inline-block;
  padding-left: @icn-size + 10;
  position: relative;
  background-repeat: no-repeat;

  &:before {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: "";
    width: @icn-size;
    height: @icn-size;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.icn-main {
  display: inline-block;
  padding-left: 28px;
  position: relative;
}
.icn-main:before {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  content: "";
  width: 25px;
  height: 32px;
  position: absolute;
  left: 0;
  top: -14px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icn--loading {
  padding-left: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto @grid-gutter-width;
  &:before {
    background-image: url("../../images/icons/main-icon.svg");
    height: 114px;
    display: block;
    width: 100%;
    top: calc(220px + 57px);
  }
}

.icn--search--default {
  background-image: url("../../images/icons/search.svg");
  width: 16px;
  height: 16px;
  background-size: contain;
  margin-top: 5px;
}

.icn--search {
  background-image: url("../../images/icons/nav-search.svg");
  width: 16px;
  height: 16px;
}

.icn--notification {
  background-image: url("../../images/icons/notification.svg");
  width: 17px;
  height: 17px;
}

.icn--menu-toggle {
  background-image: url("../../images/icons/menu-toggle.svg");
  width: 5px;
  height: 17px;
}

.icn--otp-lock {
  background-image: url("../../images/icons/otp-lock.svg");
}

.icn--verify-phone-portrait {
  background-image: url("../../images/icons/verify-phone-portrait.svg");
}

.icn--withdraw:before {
  background-image: url("/");
}

.icn--funding-received:before {
  background-image: url("../../images/icons/funding-receive.svg");
}

.icn--sell:before {
  background-image: url("../../images/icons/sell.svg");
}

.icn--buy:before {
  background-image: url("../../images/icons/buy.svg");
}

.icn--rebalancing:before {
  background-image: url("../../images/icons/rebalancing.svg");
}

.icn--calendar-goal {
  position: absolute;
  left: 94%;
  top: 25px;
  &:before {
    background-image: url("../../images/icons/listing--calendar.svg");
  }
}
.icn--username:before {
  width: 18px;
  height: 18px;
  background-image: url("../../images/icons/user-alt-1.svg");
}
.icn--password:before {
  width: 18px;
  height: 18px;
  background-image: url("../../images/icons/password-lock.svg");
}
.icn--send {
  position: relative;
  top: 1px;
  width: 50px;
  height: 20px;
  margin: auto;
  font-size: 12px;
  color: white;
  background-color: #4261ff;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 1px #4261ff;
}
.icn--error-message-close:before {
  width: 10px;
  height: 10px;
  background-image: url("../../images/icons/close-glyph-alt-inverted.svg");
}
.icn--onboarding-pie-chart:before {
  width: 24px;
  height: 24px;
  background-image: url("../../images/icons/onboarding--pie-chart.svg");
}
.icn--onboarding-bulb:before {
  width: 24px;
  height: 24px;
  background-image: url("../../images/icons/onboarding-bulb.svg");
}
.icn--onboarding-equalizer:before {
  width: 24px;
  height: 24px;
  background-image: url("../../images/icons/onboarding-equalizer.svg");
}
.icn--onboarding-lock:before {
  width: 24px;
  height: 24px;
  background-image: url("../../images/icons/onboarding-lock.svg");
}
.icn--fees:before {
  background-image: url("../../images/icons/listing--fees.svg");
}

.icn--deposit:before,
.icn--investment:before {
  background-image: url("../../images/icons/listing--investment.svg");
}

.icn--performance:before {
  background-image: url("../../images/icons/magic-wand.svg");
}

.icn--buy-amount:before {
  background-image: url("../../images/icons/arrow-buy-amount.svg");
}

.icn--sell-amount:before {
  background-image: url("../../images/icons/arrow-sell-amount.svg");
}

.icn--security:before {
  background-image: url("../../images/icons/listing--security.svg");
}

&.icn--calendar:before {
  background-image: url("../../images/icons/listing--calendar.svg");
}

.icn--withdraw:before {
  background-image: url("../../images/icons/nav-more--withdraw.svg");
}

.icn--market:before {
  background-image: url("../../images/icons/listing--market.svg");
}

.icn--recurring:before {
  background-image: url("../../images/icons/listing--recurring.svg");
}

.icn--strategy:before {
  background-image: url("../../images/icons/listing--strategy.svg");
}

.icn--probability:before {
  background-image: url("../../images/icons/listing--probability.svg");
}

.icn--projection:before {
  background-image: url("../../images/icons/listing--projection.svg");
}

.icn--target:before {
  background-image: url("../../images/icons/flag.svg");
}

.icn--generic-document:before {
  background-image: url("../../images/icons/documents--generic-document.svg");
}

.icn--monthly-statement:before {
  background-image: url("../../images/icons/documents--monthly-statement.svg");
}

.icn--return:before {
  background-image: url("../../images/icons/listing--return.svg");
}

.icn--allocation:before {
  background-image: url("../../images/icons/listing--allocation.svg");
}

.icn--action-required:before {
  background-image: url("../../images/icons/listing--messaging--action-required.svg");
}
.icn--support:before {
  background-image: url("../../images/icons/listing--messaging--support.svg");
}
.icn--portfolio-info:before {
  background-image: url("../../images/icons/listing--messaging--support.svg");
}
.icn--document:before {
  background-image: url("../../images/icons/listing--messaging--new-document.svg");
}
.icn--history:before {
  background-image: url("../../images/icons/listing--messaging--history.svg");
}
.icn--recommendation:before {
  background-image: url("../../images/icons/listing--messaging--recommendation.svg");
}
.icn--triangle-down,
.icn--triangle-up {
  padding-left: 0;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0;
  &:before {
    width: 10px;
    height: 10px;
    display: block;
  }
}
.icn--triangle-down:before {
  background-image: url("../../images/icons/triangle-down.svg");
}
.icn--triangle-up:before {
  background-image: url("../../images//icons/triangle-up.svg");
}
.icn--not-found,
.icn--hour-glass,
.icn--frown,
.icn--frown-highlighted,
.icn--locked,
.icn--time {
  padding-left: 0;
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  &:before {
    width: 80px;
    height: 80px;
    display: block;
  }
}
.icn--confirmation,
.icn--reset-password {
  padding-left: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 10px; //@grid-gutter-width;

  &:before {
    width: 64px;
    height: 64px;
    display: block;
  }
}
.icn--reset-password:before {
  background-image: url("../../images/icons/lock-unlocked.svg");
}
.icn--confirmation:before {
  background-image: url("../../images/icons/envolope-default.svg");
}
.icn--mail:before {
  width: 18px;
  height: 18px;
  background-image: url("../../images/icons/envolope-white.svg");
  background-size: contain;
}
.icn--note-pen {
  padding-left: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 30px;

  &:before {
    content: " ";
    width: 64px;
    height: 64px;
    display: block;
    background-image: url("../../images/icons/note-pen.svg");
    background-size: 100% 100%;
  }
}

.icn--right-tick,
.icn--right-tick-highlighted {
  padding-left: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 30px;
  &:before {
    width: 64px;
    height: 64px;
    display: block;
  }
}
.icn--right-tick {
  margin-bottom: 16px;
}
.icn--right-tick:before {
  background-image: url("../../images/icons/rightTick.svg");
}
.icn--right-tick-highlighted:before {
  background-image: url("../../images/icons/rightTickHighlighted.svg");
}

.icn--not-found:before {
  background-image: url("../../images/icons/not-found.svg");
}

.icn--frown:before {
  background-image: url("../../images/icons/frown-icon.svg");
  background-size: 64px 64px;
}

.icn--frown-highlighted:before {
  background-image: url("../../images/icons/frown-icon-highlighted.svg");
  background-size: 64px 64px;
}

.icn--hour-glass:before {
  background-image: url("../../images/icons/hourglass.svg");
}

.icn--locked:before {
  background-image: url("../../images/icons/lock-locked.svg");
}

.icn--time:before {
  background-image: url("../../images/icons/time-default.svg");
}

.icn--time-small:before {
  background-image: url("../../images/icons/time-default.svg");
}

.icn--trophy,
.icn--badge,
.icn--shield,
.icn--file {
  &:before {
    width: 18px;
    height: 18px;
  }
}
.icn--trophy:before {
  background-image: url("../../images/icons/trophy.svg");
}
.icn--badge:before {
  background-image: url("../../images/icons/badge.svg");
}
.icn--shield:before {
  background-image: url("../../images/icons/shield-default.svg");
}
.icn--file:before {
  background-image: url("../../images/icons/file-single-alt.svg");
}

.icn--plus {
  padding: 6px;
  margin-left: 6px;
  width: 8px;
  height: 9px;
  &:before {
    background-image: url("../../images/icons-png/plus.png");
  }
}

.icn--menu {
  width: 8px;
  height: 9px;
  &:before {
    background-image: url("../../images/icons-png/menu-vertical.png");
  }
}

.icn--camera {
  width: 24px;
  height: 20px;
  &:before {
    width: 24px;
    height: 26px;
    background-size: 24px;
    background-image: url("../../images/icons-png/camera.png");
  }
}

.icn--microphone {
  width: 10px;
  height: 20px;
  &:before {
    width: 18px;
    height: 22px;
    background-size: 22px;
    background-image: url("../../images/icons-png/microphone.png");
  }
}

.icn--close {
  float: right;
  padding-left: 0;
  width: 10px;
  height: 10px;
  &:before {
    background-image: url("../../images/icons/close-glyph-alt.svg");
    width: 10px;
    height: 10px;
  }
}

.icn--close-blue {
  float: right;
  padding-left: 0;
  width: 10px;
  height: 10px;
  &:before {
    background-image: url("../../images/icons/close-glyph-alt-blue.svg");
    width: 10px;
    height: 10px;
  }
}

.icn--shortfall:before {
  background-image: url("../../images/icons/shortfall.svg");
}
.icn--excess:before {
  background-image: url("../../images/icons/excess.svg");
}
.icn--referral:before {
  background-image: url("../../images/icons/referral.svg");
  width: 64px;
  height: 64px;
  display: block;
}
.icn--referral {
  padding-left: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 25px;
}

.icn--dashboard-title {
  margin: 2px 0 0 2px;
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}

.icn-instruction-adjust {
  position: relative;
  bottom: 4px;
}
