.risk-profile-container {
  font-family: OpenSans;

  .risk-profile-title {
    position: relative;
    margin-bottom: -20px;
    text-align: center;
    z-index: 1030;
    &__name {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 0.5px;
    }
    &__status {
      font-family: SansSemibold;
      color: #dd0f43;
    }
  }

  .risk-profile-chart {
    // height: 220px; /* the old value */
    height: 180px; /* Fixed ticked CR4-406 */
    overflow: hidden; /* Fixed ticked CR4-406 */
  }

  .risk-profile-status {
    padding-top: 14px;
    text-align: center;
    font-family: "SansSemibold";
    font-size: 20px;
    line-height: 1.5;
    color: #4261ff;
  }

  .risk-profile-cap {
    padding: 14px 0 20px;
    text-align: center;
    font-size: 15px;
    white-space: pre-line;
    line-height: 1.3;
    letter-spacing: 0.25px;
  }

  .risk-profile-date {
    display: inline-flex;
    width: 100%;
    padding-top: 16px;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eaecff;
    &__left {
      display: inline-flex;
      align-items: center;
    }
    &__right {
      font-family: SansSemibold;
      color: #4261ff;
    }
  }
}
