.chart {
	margin-bottom: 15px;
	margin-top: 15px;
	}
	.chart__gauge {
		
	}
  .chart--column,
  .chart--pie {
	margin-top: 15px;
  }
  .chart__element__container {
	width: 180px;
	margin: 0 auto 10px;
  }
  .chart--pie .chart__element__outer {
	position: relative;
	padding: 0;
	padding-bottom: 100%;
	display: block;
	height: 0;
	overflow: hidden;
	width: 180px;
  }
  .chart--pie .chart__element__inner {
	position: absolute;
	width: 100%;
	height: 100%;
  }
  .chart--pie .chart__element {
	position: relative;
	width: 100%;
	height: 100%;
  }
  .chart--performance .chart__element {
	height: 220px;
  }
  .chart--gauge .chart__element{
	height: 180px;
  }
  .chart--goal-probability .chart__element {
	height: 360px;
  }
  .highcharts-range-selector-group,
  .highcharts-range-selector-buttons {
	display: none;
  }
  .chart__range {
	display: flex;
	justify-content: space-between;
	padding: 30px 0 0;
	overflow: hidden;
  }
  .chart__range div {
	display: block;
	font-size: 15px;
	cursor: pointer;
	padding: 3px 11px;
	border-radius: 5px;
	color: #B3C0FF;
	text-decoration: none;
  }
  .chart__range div.active {
	box-shadow: 0 0 5px 0 #4261FF;
	background-color: #4261FF;
	height: 24px;
	width: 45px;
	color: #fff;
	font-family: SansSemibold
  }
  .chart__range div:active,
  .chart__range div:focus {
	text-decoration: none;
  }
  .chart--column .chart__element {
	display: flex;
  }
  .column-chart__item {
	flex: 1 auto;
  }
  .column-chart__bar-wrapper {
	height: 100%;
	border-bottom: 1px solid #EAECFF;
	display: flex;
	justify-content: center;
	align-items: flex-end;
  }
  .column-chart__bar {
	width: 15px;
	height: 0;
	transition: height 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: block;
  }
  .column-chart__text {
	text-align: center;
	padding-top: 10px;
  }
  .column-chart__item__name {
	display: block;
  }
  .column-chart__item__value {
	font-weight: 600;
	color: #4261FF;
	}
	
	.chart__currency {
		padding: 20% 0;
	}

	.hide-text {
		text {
			display: none;
		}
	}