.helper-banner {
  margin: 0 -20px;
  padding: 28px 20px;
  text-align: center;
  background-color: #4261ff;
  color: #ffffff;

  &__title {
    margin-bottom: 12px;
    padding: 0 50px;
    font-size: 24px;
    line-height: 1.33;
  }

  &__desc {
    margin-bottom: 25px; 
    font-family: SansSemibold;
  }
  
  &__btn {
    font-family: SansSemibold;
    font-size: 16px;
    
    & .btn-default {
      background-color: #26D4A5 !important;
      color: #ffffff;
    }
  }
}