.discover-more  {
  padding: 38px 0;
  background: white;
  display: flex;
  font-family: 'OpenSans';
  .left {
    padding-top: 110px;
    width: 60%;
    margin-right: auto;
    .simple-affordable {
      font-size: 20px;
      color: #4261ff;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.5;
    }
    .a-digital-wealth {
      font-size: 15px;
      letter-spacing: 0.4px;
      margin-top: 10px;
      color: #2c2c39;
    }
    .z-button {
      margin-top: 10px;
      background: white;
      display: flex;
      width: 160px;
      color: #4261ff;
      .text-contain {
        margin-left: -25px;
        .icn--play { 
          background-image: url('../../../css/images/icons/control-play-alt.svg');
          background-repeat: no-repeat;
          background-size: 16px;
          background-position-y: 4px;
        }
      }
    }
  }
  .right {
    .half-dashboard-bg {
      background-image: url('../../../css/images/theme/Mobile-Halfscreen.png');
      width: 135px;
      height: 488px;
      background-size: contain;
      margin-right: -30px;
      background-repeat: no-repeat;
    }
  }
}
.test-class {
  color: green !important;
}