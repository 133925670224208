@lightish-blue: #4261ff;

.ant-radio-wrapper {
  font-family: SansSemibold;
  padding-left: 10px;
  
  &-checked {
    color: @lightish-blue;
  }
}
