.flex-row {
	display: flex;

	@media screen and (min-width: @screen-sm-min) {
		margin-left: -15px;
		margin-right: -15px;
	}
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.block-center {
	margin: 0 auto;
}

.container-full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}