@border-color: #eaecff;
@question-icon-color: #2c2c39;
@selected-color: #4261ff;
@answerbackgroud-color: #fafaff;

.questionnaires {
  &__question {
    border-top: solid 1px @border-color;
    padding: 23px 0;
    
    &-header {
      position: relative;
      padding-left: 35px;
      cursor: pointer;

      &__icon {
        position: absolute;
        display: inline-block;
        top: 5px;
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url("../../../../css/images/icons/triangle-right.svg");
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-size: 16px;
        color: @question-icon-color;
        
        -webkit-transition: -webkit-transform .15s ease-in-out;
        -ms-transition: -ms-transform .15s ease-in-out;
        transition: transform .15s ease-in-out;  
      }
  
      &__text {
        display: inline-block;
        padding: 0;
        font-family: SansSemibold;
        font-size: 18px;
      }
    }

    &-content {
      max-height: 0;
      overflow: hidden;
      -webkit-transition: max-height 0.18s ease-out;
      -ms-transition: max-height 0.18s ease-out;
      transition: max-height 0.18s ease-out;

      &__img {
        margin: 28px 8px 8px;
      }

      &__answer {
        padding-top: 22px;
      }
    }

    &.expanded & {
      &-header {
        &__icon {
          transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
        }
      }

      &-content {
        max-height: 1000px;
        -webkit-transition: max-height 0.24s ease-in;
        -ms-transition: max-height 0.24s ease-in;
        transition: max-height 0.24s ease-in;
      }
    }

    &.answered & {
      &-header {
        color: @selected-color;
        &__icon {
          left: 1px;
          background-image: url("../../../../css/images/icons/check-filled.svg");
        }
      }

    }
    &.answered.expanded & {
      &-header {
        &__icon {
          background-image: url("../../../../css/images/icons/triangle-right.svg");
        }
      }
    }
  }

  &__answer {
    font-size: 15px;
    padding: 12px 16px;
    border: solid 1px @border-color;
    border-radius: 6px;
    background-color: @answerbackgroud-color;

    &-icon {
      width: 20px;
      height: 20px;
    }

    &.selected {
      background: @selected-color;
      color: white;
    }

    &.selected & {
      &-checkmark {
        background-image: url('../../../../css/images/icons/circle_Selected.svg');
      }
    }

    &--fullrow {
      display: table;
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--one-three {
      display: inline-block;
      vertical-align: middle;
      margin-right: 21px;
      width: calc(33.333% - 14px);
      padding: 9px 0 3px;
      text-align: center;

      &--inner {
        display: inline-block;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &--one-three & {
      &-desc {
        padding-left: 10px;
      }
    }
  
    &-icon {
      display: table-cell;
      vertical-align: middle;

      & img {
        display: inline-block;
        vertical-align: top;
        margin-top: 2px;
      }
    }

    &-checkbox {
      display: table-cell;
      vertical-align: top;
      width: 16px;
      padding-top: 3px;

      & img {
        display: block;
        width: 100%;
        min-height: 21.4286px; // just fixed heigth for center text
      }
    }
    
    &-checkmark {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('../../../../css/images/icons/circle.svg');
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: center; 
    }

    &-desc {
      display: table-cell;
      vertical-align: top;
      padding-left: 12px;
    }
  }
}
