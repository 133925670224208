
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.@{noUi-css-prefix}-target,
.@{noUi-css-prefix}-target * {
-webkit-touch-callout: none;
-webkit-tap-highlight-color: transparent;
-webkit-user-select: none;
-ms-touch-action: none;
	touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
	user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.@{noUi-css-prefix}-target {
	position: relative;
	direction: ltr;
}
.@{noUi-css-prefix}-base,
.@{noUi-css-prefix}-connects {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}
/* Wrapper for all connect elements.
 */
.@{noUi-css-prefix}-connects {
	overflow: hidden;
	z-index: 0;
}
.@{noUi-css-prefix}-connect,
.@{noUi-css-prefix}-origin {
	will-change: transform;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	-ms-transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}
/* Offset direction
 */
html:not([dir="rtl"]) .@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-origin {
	left: auto;
	right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.@{noUi-css-prefix}-vertical .@{noUi-css-prefix}-origin {
	width: 0;
}
.@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-origin {
	height: 0;
}
.@{noUi-css-prefix}-handle {
	position: absolute;
}
.@{noUi-css-prefix}-state-tap .@{noUi-css-prefix}-connect,
.@{noUi-css-prefix}-state-tap .@{noUi-css-prefix}-origin {
-webkit-transition: transform 0.3s;
	transition: transform 0.3s;
}
.@{noUi-css-prefix}-state-drag * {
	cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.@{noUi-css-prefix}-horizontal {
	height: 3px;
}
.@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-handle {
	width: 21px;
	height: 21px;
	left: -17px;
	top: -9px;
}
.@{noUi-css-prefix}-vertical {
	width: 3px;
}
.@{noUi-css-prefix}-vertical .@{noUi-css-prefix}-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px;
}
html:not([dir="rtl"]) .@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-handle {
	right: -9px;
	left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.@{noUi-css-prefix}-target {
	background: #EAECFF;
	border-radius: 3px;
}
.@{noUi-css-prefix}-connects {
	border-radius: 3px;
	box-shadow: 0 0 5px 0 rgba(66,97,255,0.50);
}
.@{noUi-css-prefix}-connect {
	background: #4261FF;
}

/* Handles and cursors;
 */
.@{noUi-css-prefix}-draggable {
	cursor: ew-resize;
}
.@{noUi-css-prefix}-vertical .@{noUi-css-prefix}-draggable {
	cursor: ns-resize;
}
.@{noUi-css-prefix}-handle {
	cursor: default;
	background: #FFFFFF;
	box-shadow: 0 0 5px 0 rgba(66,97,255,0.50);
	border-radius: 5px;
}
.@{noUi-css-prefix}-active {
}


/* Disabled state;
 */

[disabled] .@{noUi-css-prefix}-connect {
	background: #B8B8B8;
}
[disabled].@{noUi-css-prefix}-target,
[disabled].@{noUi-css-prefix}-handle,
[disabled] .@{noUi-css-prefix}-handle {
	cursor: not-allowed;
}
